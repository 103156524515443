import { style } from '@/widgets/map/helpers'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { Vector as VectorLayer } from 'ol/layer'
import { Cluster, Vector as VectorSource } from 'ol/source'

export const init = (arr, k) => {
  let layer = {}
  layer.source = new VectorSource()
  layer.vector = new VectorLayer({
    source: new Cluster({
      distance: 60,
      source: layer.source,
    }),
  })
  if (k === 'history') {
    style.clusterStyle(layer.vector, arr, 'name', style.cars('green'))
  } else {
    style.clusterStyle(layer.vector, arr, 'objID', style.cars('red'))
  }
  return layer
}

export const pointer = (item, key) => {
  let feature = new Feature(new Point([item.lastPos.mLon, item.lastPos.mLat]))
  feature.setId(item[key])
  return feature
}

<template>
  <div v-if="car(alarm.objID)">
    <router-link :to="{ name: 'car', params: { id: car(alarm.objID).sn } }">
      <div class="alarm_card no_selected" style="color: #000000">
        <div class="ac_row ac_head">
          <div class="ac_id">№ {{ alarm.alarmID }}</div>
          <ConnectingDateTime>
            {{ unixGmtToDateTime(alarm.gmt) }}
          </ConnectingDateTime>

          <div class="ac_status">
            <img
              v-if="false"
              :src="require('@/assets/icons/alarms/complete-normal.png')"
            />
            <img
              v-if="alarm.alarmState === 1"
              :src="require('@/assets/icons/alarms/wrok-active.png')"
            />
            <img
              v-if="alarm.alarmState === 0"
              :src="require('@/assets/icons/alarms/new-active.png')"
            />
          </div>
          <div class="ac_in_work_btn">
            <image-btn
              :type="'alarms'"
              :name="'in-work'"
              :alt="'Взять в работу'"
              :label="'в работу'"
              @click.prevent.stop="workOn()"
            />
          </div>
        </div>
        <div class="ac_line"></div>
        <height-wrapp @click.prevent v-if="showWorker" :h="h">
          <div class="worker">
            <div
              style="
                display: flex;
                width: 100%;
                height: 30px;
                align-items: center;
              "
            >
              <div style="flex: 1 1 auto; margin-left: 10px">
                осталось символов -
                {{ 1000 - commentHistori.length - comment.length - 32 }}
              </div>
              <image-btn
                style="margin-right: 10px"
                :type="'groups'"
                :name="'save'"
                :alt="'Сохранить'"
                @click.prevent.stop="save()"
              />
              <image-btn
                style="margin-right: 10px"
                :type="'groups'"
                :name="'cancel'"
                :alt="'Отменить'"
                @click.prevent.stop="cancel()"
              />
              <image-btn
                style="margin-right: 10px"
                :type="'alarms'"
                :name="'close'"
                :alt="'Закрыть'"
                @click.prevent.stop="finish()"
              />
            </div>
            <textarea
              disabled
              v-model="commentHistori"
              style="
                font-size: 12px;
                padding: 8px;
                background: #cecece;
                width: 100%;
                height: 100px;
                resize: none;
              "
            ></textarea>
            <textarea
              v-model="comment"
              style="
                background: #d1f7ff;
                width: 100%;
                flex: 1 1 auto;
                resize: none;
                border-radius: 0 0 8px 8px;
                padding: 8px;
              "
              placeholder="Введите комментарий"
            ></textarea>
          </div>
        </height-wrapp>
        <div class="ac_row">
          <div class="ac_name">Наименование</div>
          <div class="ac_desc">{{ car(alarm.objID).name }}</div>
        </div>
        <div class="ac_row">
          <div class="ac_name">Тревога</div>
          <div class="ac_desc">{{ alarm.desc }}</div>
        </div>
        <div v-for="i in Object.keys(showerSetting)" :key="i">
          <div class="ac_row" v-for="j in showerSetting[i].selected" :key="j">
            <div class="ac_name">
              {{ showerSetting[i].options.find((o) => o.name === j).label }}
            </div>
            <div v-if="i === 'car'" class="ac_desc">
              {{ car(alarm.objID)[j] }}
            </div>
            <div v-if="i === 'customField'" class="ac_desc">
              {{ car(alarm.objID).customFields[j] }}
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { api } from '@/entities/alarms'
import { unixGmtToDateTime } from '@/shared/lib/date'
import { ConnectingDateTime, HeightWrapp, ImageBtn } from '@/shared/ui'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'alarmCard',
  components: { ImageBtn, HeightWrapp, ConnectingDateTime },
  props: { alarm: Object, showerSetting: Object },
  emits: ['workOn'],
  data() {
    return {
      unixGmtToDateTime,
      showWorker: false, // Тромоз (300 мс) выключения воркера
      enableWorker: false,
      h: 0,
      comment: '',
      commentHistori: '',
    }
  },
  watch: {
    alarm: {
      handler(a) {
        this.commentHistori = a.comment
      },
      deep: true,
    },
    enableWorker: {
      handler(v) {
        if (v) {
          this.h = 200
          this.showWorker = v
        } else {
          this.h = 0
          setTimeout(() => {
            this.showWorker = v
          }, 300)
        }
      },
    },
  },
  mounted() {
    this.commentHistori = this.alarm.comment
  },
  computed: {
    ...mapGetters({
      car: 'cars_mod/GET_CAR',
      customFields: 'cf_mod/ALL_CUSTOM_FIELDS',
      selectFromMap: 'OPN_mod/GET_ONE',
    }),
  },
  methods: {
    ...mapActions({
      select2Map: 'OPN_mod/SET_ONE',
      showActions: 'OPN_mod/ADD_SHOW_LAYERS',
      hiddenActions: 'OPN_mod/DEL_SHOW_LAYERS',
    }),
    select() {},
    cancel() {
      setTimeout(() => {
        this.$emit('workOn', false)
      }, 300)
      this.enableWorker = false
    },
    save() {
      setTimeout(() => {
        this.$emit('workOn', false)
      }, 300)
      this.enableWorker = false
      const now = new Date()
      let com = this.commentHistori + '\n\n'
      if (this.commentHistori === '') com = ''
      const alarm = {
        alarmUID: this.alarm.uuid,
        comment:
          com +
          '[' +
          unixGmtToDateTime(now.getTime() / 1000) +
          '] ' +
          this.alarm.operator +
          ' - ' +
          this.comment,
      }
      api.update(alarm)
      this.comment = ''
    },
    finish() {
      setTimeout(() => {
        this.$emit('workOn', false)
      }, 300)
      this.enableWorker = false
      const alarm = {
        alarmUID: this.alarm.uuid,
        comment: this.comment,
        alarmState: 100,
      }
      api.finish(alarm)
      this.comment = ''
    },
    workOn() {
      this.$emit('workOn', true)
      this.enableWorker = true
      api.getAlarm(this.alarm.uuid)
    },
  },
}
</script>

<style scoped>
.btn {
  margin: 10px;
  padding: 2px;
  border: 1px solid red;
}
.btn:hover {
  background: #a4a4a4;
}
.worker {
  /*z-index: 2;*/
  /*padding: 6px;*/
  /*padding-top: 0;*/
  height: 200px;
  border: 1px solid #5ce672;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.alarm_card {
  width: 360px;
  background: #fdfdfd;
  border: 1px solid #b1b1b1;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}
.ac_row {
  width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
  padding: 0 8px;
}
.ac_head {
  height: 32px;
}
.ac_line {
  width: 96%;
  height: 1px;
  border-bottom: 1px solid #9e9e9e;
}
.border {
  border: 1px solid red;
}
.ac_id {
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #494949;
  margin-right: 6px;
}

.ac_in_work_btn {
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 12px;
}

.ac_status {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}
.ac_name {
  flex: 1 1 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ac_desc {
  flex: 1 1 auto;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: end;
}
.datatimeconnecting {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}
.datatimeconnecting_text {
  padding-left: 8px;
  padding-top: 4px;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
}
</style>

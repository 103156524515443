import { local, wamp } from '@/app/deliv'
import { router } from '@/app/providers'
import { api as apiAlarms } from '@/entities/alarms'
import { api as apiCars } from '@/entities/cars'
import Connect from '@/entities/connect'
import { api as apiCustomFields } from '@/entities/customFields'
import { api as apiGroups } from '@/entities/groups'
import { api as apiZones, clear as clearZones } from '@/entities/zones'
import { WS } from '@/shared/config'
import store from '@/store'
import { useCarsStore } from '@/stores/carsStore'
import { useMenuStore } from '@/stores/menuStore'
import { useRulesStore } from '@/stores/rules'

export const subscriber = {
  unSub: (
    subArray = [
      'alarms',
      'positions',
      'customFields',
      'zones',
      'groups',
      'auto',
      'object',
    ]
  ) => {
    if (subArray.includes('alarms'))
      Connect.wamp.value.Unsubscribe(WS.ALARMS + Connect.userID.value)

    if (subArray.includes('positions'))
      Connect.wamp.value.Unsubscribe(WS.POSITION + Connect.userID.value)

    if (subArray.includes('customFields'))
      Connect.wamp.value.Unsubscribe(WS.CUSTOM_FIELDS + Connect.userID.value)

    if (subArray.includes('zones'))
      Connect.wamp.value.Unsubscribe(WS.SP + Connect.spID.value)

    if (subArray.includes('groups'))
      Connect.wamp.value.Unsubscribe(WS.GROUPS + Connect.userID.value)

    if (subArray.includes('auto'))
      Connect.wamp.value.Unsubscribe(WS.AUTOMATICS + Connect.userID.value)

    if (subArray.includes('object'))
      Connect.wamp.value.Unsubscribe(WS.OBJECT + Connect.userID.value)
  },
  sub: (
    subArray = [
      'alarms',
      'positions',
      'customFields',
      'zones',
      'groups',
      'auto',
      'object',
    ]
  ) => {
    if (subArray.includes('alarms'))
      Connect.wamp.value.Subscribe(WS.ALARMS + Connect.userID.value, (e) => {
        store.dispatch('alarms_mod/UPDATE_ALARMS', e)
      })

    if (subArray.includes('positions'))
      Connect.wamp.value.Subscribe(WS.POSITION + Connect.userID.value, (e) => {
        store.dispatch('cars_mod/UPDATE_CARS_POSITION', e[0])
        useCarsStore().updatePosition(e[0])
      })

    if (subArray.includes('customFields'))
      Connect.wamp.value.Subscribe(
        WS.CUSTOM_FIELDS + Connect.userID.value,
        (e) => {
          store.dispatch('cf_mod/UPDATE_CUSTOM_FIELDS', e)
        }
      )

    if (subArray.includes('zones')) apiZones.subscribe()
    // Connect.wamp.value.Subscribe(WS.SP + Connect.spID.value, (e) => {
    //   store.dispatch('zones_mod/UPDATE_ZONES', e)
    // })

    if (subArray.includes('groups'))
      Connect.wamp.value.Subscribe(WS.GROUPS + Connect.userID.value, (e) => {
        store.dispatch('g_mod/UPDATE_GROUPS', e)
      })

    if (subArray.includes('auto'))
      Connect.wamp.value.Subscribe(
        WS.AUTOMATICS + Connect.userID.value,
        (e) => {
          store.dispatch('g_mod/UPDATE_AUTOMATICS', e)
        }
      )

    if (subArray.includes('object'))
      Connect.wamp.value.Subscribe(WS.OBJECT + Connect.userID.value, (e) => {
        useCarsStore().updateCars(e)
      })
  },
  singleSub: (objID) => {
    Connect.wamp.value.Subscribe(
      WS.SINGLE.replace(':userID', Connect.userID.value).replace(
        ':objID',
        objID
      ),
      (e) => {
        useCarsStore().updateCars(e)
      }
    )
    Connect.wamp.value.Subscribe(
      WS.SINGLE_POSITION.replace(':objID', objID),
      (e) => {
        store.dispatch('cars_mod/UPDATE_CARS_POSITION', [e])
      }
    )
  },
  singleUnSub: (objID) => {
    Connect.wamp.value.Subscribe(
      WS.SINGLE.replace(':userID', Connect.userID.value).replace(
        ':objID',
        objID
      )
    )
    Connect.wamp.value.Subscribe(WS.SINGLE_POSITION.replace(':objID', objID))
  },
}

export const setter = {
  preSet: () => {
    const rules = useRulesStore()

    if (rules.FLEET_SINGLE_OBJECT) {
      apiCustomFields.get()
      useMenuStore().menuInit(['manage_set'])
    } else {
      useMenuStore().menuInit([
        'monitoring',
        'geozones',
        'reports',
        'alarms',
        'settings',
        'car',
      ])
      store.dispatch('carsGroups_mod/FETCH_GROUPS')
      store.dispatch('INIT_ZONE')
      apiCars.get()
      apiAlarms.get()
      apiZones.get()

      apiCustomFields.get()
      apiGroups.getGroups()
      apiGroups.getAutomatics()
      subscriber.sub()
    }
  },
  close: () => {
    // Отписка
    for (const s in wamp.subscribtions) {
      wamp.Unsubscribe(s)
    }
    local.reset([
      'fleet_login',
      'fleet_pass',
      'token',
      'reportTemplates',
      'cars_setting',
      'menu',
      'groups',
      'mapShowZones',
      'version',
      'user',
    ])

    wamp.close()
    Connect.logout()

    router.push({ name: 'loginPage' })
    useCarsStore().reset()
    useMenuStore().reset()

    store.dispatch('cars_mod/CLEAR')
    store.dispatch('cf_mod/CLEAR')
    store.dispatch('INIT_ZONE')
    store.dispatch('carsGroups_mod/CLEAR')
    store.dispatch('alarms_mod/CLEAR')
    // store.dispatch('zones_mod/CLEAR')
    clearZones()
    store.dispatch('g_mod/CLEAR')
  },
}

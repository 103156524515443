export const local = {
  get: (key) => {
    if (localStorage.key) return localStorage.getItem(key)
    else return false
  },
  set: (key, vol) => {
    localStorage.setItem(key, vol)
  },
  reset: (keys = ['fleet_login', 'fleet_pass']) => {
    keys.forEach((k) => localStorage.removeItem(k))
  },
}

import { FLEET_DEBUG } from '@/shared/config'

export const groupsModule = {
  state: () => ({
    groups: [], // группы
    // tree: {}, // дерево
    tree: { name: 'Все объекты', spID: 0 }, // дерево
    groupsSelect: {
      rowCount: 0, // количество отображаемых групп (развернуты)
      select: [], // выбрали ид групп
    }, //  временная фигня (выбор групп из дерева) для экшенов добавить и исключить объекты из групп
    objectsSelect: [], // временныф массив с объектами выбираемыми вручную
    automatics: [], //автоматизации
    select: null,
    edit: null,
    filter: '',
  }),
  getters: {
    GET_FILTER(state) {
      return state.filter
    },
    GET_GROUPS(state) {
      return state.groups
    },
    GET_GROUPS_SELECT(state) {
      return state.groupsSelect
    },
    GET_GROUP_OBJECTS: (state) => (id) => {
      if (id !== 0) {
        const objs = [...state.groups].find((i) => i.spID === id).objects
        if (objs) return objs
      }
      return []
    },
    GET_TREE(state) {
      return state.tree
    },
    GET_ALL_AUTOMATICS(state) {
      return state.automatics
    },
    GET_AUTOMATICS: (state) => (groupId) => {
      let automatics = JSON.parse(JSON.stringify(state.automatics))
      return automatics.filter((i) => i.spID === groupId)
    },
    GET_SELECT(state) {
      if (state.select !== null) return state.select.item
    },
    GET_EDIT(state) {
      return state.edit
    },
    GET_SELECT_PARENT(state) {
      if (state.select !== null) return state.select.parent
    },
    GET_SELECT_TYPE(state) {
      if (state.select !== null) return state.select.type
    },
    GET_FULL_SELECT(state) {
      if (state.select !== null) return state.select
    },
    GET_OBJECTS_SELECT(state) {
      return state.objectsSelect
    },
  },
  mutations: {
    INIT: (state) => {
      state.groups = [] // группы
      state.tree = { name: 'Все объекты', spID: 0 } // дерево
      state.groupsSelect = {
        rowCount: 0, // количество отображаемых групп (развернуты)
        select: [], // выбрали ид групп
      } //  временная фигня (выбор групп из дерева) для экшенов добавить и исключить объекты из групп
      state.objectsSelect = [] // временныф массив с объектами выбираемыми вручную
      state.automatics = [] //автоматизации
      state.select = null
      state.edit = null
      state.filter = ''
    },
    SET_FILTER: (state, filter) => {
      state.filter = filter
    },
    SET_GROUPS: (state, groups) => {
      // console.log("this.groupObjs commit", groups.length);
      state.groups = groups
    },
    SET_GROUPS_SELECT: (state, gs) => {
      state.groupsSelect = gs
    },
    SET_TREE: (state, children) => {
      state.tree.children = children
    },
    SET_AUTOMATICS: (state, automatics) => {
      state.automatics = automatics
    },
    SET_SELECT: (state, select) => {
      state.select = select
    },
    SET_EDIT: (state, edit) => {
      state.edit = edit
    },
    SET_OBJETCS_SELECT: (state, objs) => {
      state.objectsSelect = objs
    },
  },
  actions: {
    CLEAR: ({ commit }) => {
      commit('INIT')
    },
    SET_FILTER: ({ commit }, filter) => {
      commit('SET_FILTER', filter)
    },
    GROUPS_SELECTOR: ({ state, commit }, id) => {
      let tsg = JSON.parse(JSON.stringify(state.groupsSelect))
      if (tsg.select.includes(id))
        tsg.select = tsg.select.filter((i) => i !== id)
      else tsg.select.push(id)
      commit('SET_GROUPS_SELECT', tsg)
    },
    RESET_GROUPS_SELECTOR: ({ state, commit }) => {
      let tsg = JSON.parse(JSON.stringify(state.groupsSelect))
      tsg.select = []
      commit('SET_GROUPS_SELECT', tsg)
    },
    COUNTER_GROUPS_SELECT: ({ state, commit }, boo) => {
      let tsg = JSON.parse(JSON.stringify(state.groupsSelect))
      if (boo) tsg.rowCount++
      else tsg.rowCount--
      commit('SET_GROUPS_SELECT', tsg)
    },
    SET_GROUPS: ({ commit }, groups) => {
      if (FLEET_DEBUG) console.log('SET_GROUPS', groups)
      commit('SET_GROUPS', groups)
    },
    // ADD_GROUP: ({ state, commit }) => {
    //   let id = Math.floor(Math.random() * 999999).toString();
    //   let newGroup = {
    //     name: "Новая группа",
    //     parentSpID: state.select.item,
    //     spID: id,
    //     children: [],
    //   };
    //   let groups = JSON.parse(JSON.stringify(state.groups));
    //   groups.push(newGroup);
    //   commit("SET_GROUPS", groups);
    //   commit("SET_SELECT", { item: id, parent: state.select.item });
    //   commit("SET_EDIT", id);
    // },
    // DELETE_GROUP: ({ state, commit }) => {
    //   let groups = JSON.parse(JSON.stringify(state.groups));
    //   let delId = state.select.item;
    //   groups = groups.filter((i) => i.spID !== delId);
    //   commit("SET_GROUPS", groups);
    // },

    UPDATE_GROUPS: ({ state, commit }, newGroups) => {
      if (FLEET_DEBUG) console.log('UPDATE_GROUPS: ', newGroups)

      let groups = JSON.parse(JSON.stringify(state.groups))
      let newGroupsId = []
      let groupsId = []

      newGroups.forEach((g) => newGroupsId.push(g.spID))
      groups.forEach((g) => groupsId.push(g.spID))

      groups.forEach((g) => {
        if (newGroupsId.includes(g.spID)) {
          let ng = newGroups.find((i) => i.spID === g.spID)
          if (ng.isForUM) g.isForUM = ng.isForUM
          if (ng.name) g.name = ng.name
          g.objects = ng.objects
          if (ng.parentSpID) g.parentSpID = ng.parentSpID
          if (ng.spType) g.spType = ng.spType
        }
      })
      newGroups.forEach((g) => {
        if (!groupsId.includes(g.spID)) {
          groups.push(g)
        }
      })

      groups.sort((a, b) => (a.name > b.name ? 1 : -1))
      commit('SET_GROUPS', groups)
    },

    SET_AUTOMATICS: ({ commit }, automatics) => {
      commit('SET_AUTOMATICS', automatics)
    },
    UPDATE_AUTOMATICS: ({ state, commit }, newAutomatics) => {
      if (FLEET_DEBUG) console.log('UPDATE_AUTOMATICS', newAutomatics)
      let automatics = [...state.automatics]
      newAutomatics.forEach((i) => {
        let oldAutomaticIndex = automatics.findIndex(
          (j) => j.autoID === i.autoID
        )
        if (oldAutomaticIndex < 0) {
          automatics.push(i)
        } else {
          automatics[oldAutomaticIndex] = i
        }
      })
      commit('SET_AUTOMATICS', automatics)
    },
    DELETE_AUTOMATICS: ({ state, commit }) => {
      let automatics = JSON.parse(JSON.stringify(state.automatics))
      let delId = state.select.item
      automatics = automatics.filter((i) => i.autoID !== delId)
      commit('SET_AUTOMATICS', automatics)
      commit('SET_EDIT', null)
    },
    ADD_ACTION: ({ state, commit }, action) => {
      let automatics = JSON.parse(JSON.stringify(state.automatics))
      let autoID = state.select.item
      automatics.forEach((a) => {
        if (a.autoID === autoID) {
          a.actions.push(action)
        }
      })
      commit('SET_AUTOMATICS', automatics)
      if (FLEET_DEBUG) console.log('automatics', autoID, automatics)
    },

    SET_TREE: ({ commit }, children) => {
      commit('SET_TREE', children)
    },
    SET_SELECT: ({ commit }, select) => {
      commit('SET_SELECT', select)
    },
    SET_EDIT: ({ state, commit }, edit) => {
      let id = null
      if (edit) id = state.select.item
      commit('SET_EDIT', id)
    },
    SAVE_GROUP_NAME: ({ state, commit }, name) => {
      let id = state.select.item
      let groups = JSON.parse(JSON.stringify(state.groups))
      groups.forEach((i) => {
        if (i.spID === id) i.name = name
      })

      commit('SET_GROUPS', groups)
      commit('SET_EDIT', null)
    },
    SAVE_AUTOMATICS: ({ state, commit }, auto) => {
      let automatics = JSON.parse(JSON.stringify(state.automatics))
      automatics.forEach((i) => {
        if (i.autoID === auto.autoID) {
          i.name = auto.name
          i.trigger = JSON.parse(JSON.stringify(auto.trigger))
        }
      })

      commit('SET_AUTOMATICS', automatics)
      commit('SET_EDIT', null)
    },
    SET_OBJETCS_SELECT: ({ commit }, objs) => {
      commit('SET_OBJETCS_SELECT', [...objs])
    },
    ADD_REMOVE_OBJECT_TO_SELECT: ({ state, commit }, obj) => {
      let objs = [...state.objectsSelect]

      if (objs.includes(obj)) {
        objs = objs.filter((o) => o !== obj)
      } else {
        objs.push(obj)
      }
      commit('SET_OBJETCS_SELECT', objs)
    },
  },
  namespaced: true,
}

<script setup>
import { FieldEditor } from '@/shared/ui'
import { useRulesStore } from '@/stores/rules'
import { ref } from 'vue'
import { api } from '../api'

const props = defineProps({
  car: { type: Object, required: true },
})

const open = ref(true)

const rulesStore = useRulesStore()

const ObjData = [
  { id: 'sn', label: 'Серийный номер', isEdit: false },
  { id: 'model', label: 'Модель', isEdit: true },
  { id: 'year', label: 'Год выпуска', isEdit: false },
  { id: 'color', label: 'Цвет', isEdit: true },
  { id: 'gos', label: 'Гос. номер', isEdit: true },
  { id: 'vin', label: 'VIN', isEdit: true },
]

const saveCar = (key, val) => {
  const req = {
    objID: props.car.objID,
  }
  key = key.replace('model', 'markModel')
  req[key] = val
  api.setInfo(req)
}
</script>

<template>
  <div
    class="basic_content"
    :style="`height: ${open ? ObjData.length * 30 + 60 : 30}px`"
  >
    <div class="basic_content_head" @click="open = !open">
      Данные объекта:
      <div
        style="
          flex: 1 1 auto;
          font-weight: 700;
          font-size: 14px;
          line-height: 15px;
          padding-left: 5px;
        "
      >
        {{ car.name }} / {{ car.objID }}
      </div>
    </div>
    <div class="in_basic_content basic_top_border">
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">
          Наименование объекта:
        </div>
        <div
          style="
            margin-right: 15px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ car.name }}
        </div>
      </div>
      <div class="basic_content_row" v-for="prop in ObjData" :key="prop.id">
        <field-editor
          v-if="prop.isEdit && rulesStore.FLEET_GOS_EDIT"
          :property="prop.label + ':'"
          :editString="car[prop.id]"
          @save="saveCar(prop.id, $event)"
        />
        <div v-else class="basic_content_row">
          <div style="flex: 1 1 auto; margin-left: 15px">{{ prop.label }}:</div>
          <div
            style="
              margin-right: 15px;
              font-weight: 500;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ car[prop.id] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

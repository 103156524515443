<template>
  <div style="position: relative; width: 100%">
    <div
      class="height_wrapp_expansion height_wrapp_upper"
      :style="{ height: height.toString() + 'px' }"
    >
      <slot />
    </div>
    <div
      class="height_wrapp_expansion"
      :style="{ height: height.toString() + 'px' }"
    ></div>
  </div>
</template>

<script>
// Принимаю высоту слота - плавно меняю размеры обертки (300ms)
// Слот в позишн абсолют, z-index = 100
export default {
  name: "heightWrapp",
  props: { h: Number },
  data() {
    return {
      height: 0,
    };
  },
  watch: {
    h: {
      handler(v) {
        this.height = v;
      },
    },
  },
  mounted() {
    this.height = 0;
    setTimeout(() => {
      this.height = this.h;
    }, 0);
  },
};
</script>

<style scoped>
.height_wrapp_expansion {
  overflow: hidden;
  transition: height 300ms;
  width: 100%;
}
.height_wrapp_upper {
  position: absolute;
  z-index: 1000;
}
</style>

import {
  EPSG_DB,
  EPSG_MAP,
  TYPE_RATIO,
  ZONE_CIRCLE,
  ZONE_POLYGON,
} from '@/shared/config'
import { getPointResolution, transform } from 'ol/proj'
import { invertObj } from './helpers'

export const coordinateImportConverter = ([lon, lat]) =>
  transform([lon, lat], EPSG_DB, EPSG_MAP)

export const coordinateExportConverter = ([lon, lat]) =>
  transform([lon, lat], EPSG_MAP, EPSG_DB)

export const radiusImportConverter = (radius, center) =>
  radius / getPointResolution(EPSG_MAP, 1, center, 'm')

export const radiusExportConverter = (radius, center) =>
  Math.round(radius * getPointResolution(EPSG_MAP, 1, center, 'm'))

export const typeImportConverter = (type) => TYPE_RATIO[type]
export const typeExportConverter = (type) => invertObj(TYPE_RATIO)[type]

export const isCircle = (type) => type === ZONE_CIRCLE
export const isPolygon = (type) => type === ZONE_POLYGON
export const isUnknownType = (type) => !isCircle(type) && !isPolygon(type)

const coordinateToPoint = (coordinate) => ({
  lat: coordinate[1],
  lon: coordinate[0],
})

export const pointsToCoordinates = (points) =>
  [...points, points[0]].map((point) =>
    coordinateImportConverter([point.lon, point.lat])
  )

export const coordinatesToPoints = (coordinates) =>
  coordinates
    .slice(0, -1)
    .map(flow(coordinateExportConverter, coordinateToPoint))

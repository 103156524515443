import settingsModel from '@/models/customFields'
import { DEBUG, EPSG_DB, EPSG_MAP } from '@/shared/config'
import Circle from 'ol/geom/Circle'
import Polygon from 'ol/geom/Polygon'
import { transform } from 'ol/proj'
import { unixGmtToDateTime } from './date'

export const isNull = (value) => value === null

// Округляем до count символов после запятой
export const rounded = (count) => (number) => +number.toFixed(count)

export const rounded10 = rounded(10)
const rounded11 = rounded(11)

// Обрезаем текст до нужного количества символов и добавляем ..
export const sliceName = (text, lenght, sliced = text.slice(0, lenght)) =>
  sliced.length < text.length ? sliced + '...' : sliced

//  Функция filterFn проверяет у объекта (obj) значения ключей
//  из массива ключей (keys) на "=><вхождение подстроки и пересечение массивов" val
export const filterFn = (type = 'inc', obj = {}, keys = [], val = '') => {
  return keys.some((k) => {
    if (k in obj) {
      if (type === '=') return obj[k] === val
      if (type === '><') return obj[k] > val[0] && obj[k] < val[1]
      if (type === '<') return obj[k] < val
      if (type === '>') return obj[k] > val
      if (type === 'xArr')
        return obj[k].filter((x) => val.includes(x)).length > 0
      if (type === 'inc')
        return obj[k]
          .toString()
          .toLowerCase()
          .includes(val.toString().toLowerCase())
    }
    return false
  })
}

// Подготовка данных по полученной тревоге
export const alarmDataPrepare = function ({
  // Принимаю данные по тревоге
  uuid = '',
  alarmID = 0,
  alarmState = 0,
  objID = 0,
  operator = '',
  comment = '',
  desc = '',
  gmt = '',
  gmtWork = '',
}) {
  return {
    uuid,
    alarmID,
    alarmState,
    objID,
    operator,
    comment,
    desc,
    gmt,
    gmtWork,
  }
}

// Подготовка данных по новому объекту
export const carDataPrepare = function ({
  // Принимаю данные по объекту
  objID = 0,
  vin = '',
  gos = '',
  sn = '',
  customFields = {},
  model = '',
  year = '',
  color = '',
}) {
  const id = objID
  let name
  if (gos !== '') {
    name = gos
  } else if (vin !== '') {
    name = vin
  } else {
    name = sn
  }
  return {
    name,
    id,
    vin,
    gos,
    sn,
    customFields,
    model,
    color,
    year,
  }
}

//---------------
export const addConfig2Cars = (id) => {
  let defaultConfig = {}
  settingsModel.forEach((i) => {
    defaultConfig[i.id] = '-'
  })
  let config = defaultConfig
  let settings = []
  if (localStorage.cars_setting)
    settings = JSON.parse(localStorage.getItem('cars_setting'))

  if (settings.find((i) => i.id === id)) {
    config = settings.find((i) => i.id === id).config
  }

  return config
}

// Подготовка данных по пришедшей последней позиции объекта
export const lastPosPrepare2 = (lastPos) => {
  lastPos.accum > 3
    ? (lastPos.powervolume = 100)
    : (lastPos.powervolume = Math.ceil(lastPos.accum / 0.0003) / 100)
  lastPos.gps === 0
    ? (lastPos.connecttype = 'LBS')
    : (lastPos.connecttype = 'GPS')
  lastPos.datetime = unixGmtToDateTime(lastPos.unixGmt)
  if (lastPos.lat === 0 && lastPos.lon === 0) {
    lastPos.rLat = rounded10(lastPos.gLat)
    lastPos.rLon = rounded10(lastPos.gLon)
  } else {
    lastPos.rLat = rounded10(lastPos.lat)
    lastPos.rLon = rounded10(lastPos.lon)
  }
  ;[lastPos.mLon, lastPos.mLat] = transform(
    [lastPos.rLon, lastPos.rLat],
    EPSG_DB,
    EPSG_MAP
  )

  lastPos.address = '-'
}

// Подготовка данных по последней позиции
export const lastPosPrepare = function ({
  // Принимаю данные по объекту
  lat = 0,
  lon = 0,
  gps = 0,
  gsm = 0,
  unixGmt = 0,
  accum = 0,
  speed = 0,
  board = 0,

  // Вычисляемое свойство - если нет спутников, значит прилетело от ЛБС
  connecttype = 'GPS',
}) {
  lat = rounded11(lat)
  lon = rounded11(lon)
  const datetime = unixGmt
  const powervolume = accum > 3 ? 100 : Math.ceil(accum / 0.0003) / 100
  const temperature = board
  if (gps === 0) connecttype = 'LBS'

  return {
    lat,
    lon,
    gps,
    gsm,
    datetime,
    powervolume,
    speed,
    temperature,
    connecttype,
  }
}

//  Преобразование плоского массива в дерево
export const flat2Tree = (
  items,
  rootID = 0,
  link = 'parentSpID'
  // key = "spID"
) =>
  items
    .filter((item) => item[link] === rootID)
    .map((item) => ({
      ...item,
      children: flat2Tree(items, item.spID),
    }))

//  Поиск в дереве
export const treeFinder = (tree, value, key = 'id', reverse = false) => {
  const stack = [tree[0]]
  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']()
    if (node[key] === value) return node
    node.children && stack.push(...node.children)
  }
  return null
}

// export const groupObjectRemover = function () {
//   let a = this.store.getters("cars_mod/ALL_CARS");
//   console.log("aaaaaaaaaa", a);
// };

//  Сравнение двух массивов
export const equalArrays = (a, b) => {
  if (a.length !== b.length) return false
  for (var i = 0; i < a.length; i++) if (a[i] !== b[i]) return false
  return true
}

//  Сравнение двух объектов. С одним уровнем вложенности. Значения мб строки, числа, массивы
export const equal = (a, b) => {
  for (let key in a) {
    if (typeof a[key] !== typeof b[key]) return false
    if (typeof a[key] === 'string' && a[key] !== b[key]) return false
    if (typeof a[key] === 'number' && a[key] !== b[key]) return false
    if (!equalArrays(a[key], b[key])) return false
  }
  return true
}

//  Определяем вхождение позиции в зоны из списка
export const calcIntersects = (zones, pos) => {
  let z = []
  zones.forEach((zone) => {
    let geo = null
    switch (zone.type) {
      case 'Circle':
        geo = new Circle(zone.center, zone.radius)
        break
      case 'Polygon':
        geo = new Polygon([zone.coordinates])
        break
    }
    if (geo.intersectsCoordinate(pos)) z.push(zone.id)
  })
  return z
}

//  Проставка группе объектов их зон
//  TODO - как грубо менять аргумент внутри функции (( фу...
export const calcIntersectsMultyObj = async (zones, cars) => {
  if (DEBUG) console.time('calcIntersectsMultyObj')
  cars.forEach((car) => {
    car.inZone = []
  })
  zones.forEach((zone) => {
    let geo = null
    switch (zone.type) {
      case 'Circle':
        geo = new Circle(zone.center, zone.radius)
        break
      case 'Polygon':
        geo = new Polygon([zone.coordinates])
        break
    }
    cars.forEach((car) => {
      if (geo.intersectsCoordinate([car.lastPos.mLon, car.lastPos.mLat])) {
        car.inZone.push(zone.id)
      }
    })
  })
  if (DEBUG) console.timeEnd('calcIntersectsMultyObj')
  if (DEBUG) console.log('calcIntersectsMultyObj', zones.length, cars.length)
}

export const invertObj = (obj) =>
  Object.fromEntries(Object.entries(obj).map((a) => a.reverse()))

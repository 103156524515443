import {
  numWord,
  WORDS_DAY,
  WORDS_HOUR,
  WORDS_MINUTE,
  WORDS_SECOND,
  WORDS_YEAR,
} from './numWord'

const DATE_TIME_OPTION = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

const ONE_SECOND = 1000
const ONE_MINUTE = ONE_SECOND * 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24
const ONE_YEAR = ONE_DAY * 365

const UP_TIME_DATA = [
  [ONE_YEAR, WORDS_YEAR],
  [ONE_DAY, WORDS_DAY],
  [ONE_HOUR, WORDS_HOUR],
  [ONE_MINUTE, WORDS_MINUTE],
  [ONE_SECOND, WORDS_SECOND],
]

const reverseDot = (array) => array.split('.').reverse().join('.')

const dateTime = (date) =>
  new Intl.DateTimeFormat('ru-RU', DATE_TIME_OPTION).format(date).split(', ')

const formatter = (rawDate) =>
  dateTime(rawDate)
    .map((i) => reverseDot(i))
    .join(' ')

export const unixGmtToDateTime = (unixGmt) =>
  formatter(new Date(unixGmt * 1000))

const getDiff =
  (unixGmt, diffTime = Date.now() - parseInt(unixGmt, 10) * 1000) =>
  (period, word, diff = diffTime / period) =>
    diff > 1 ? `${Math.floor(diff)} ${numWord(word)(Math.floor(diff))}` : false

// Считаем аптайм в человеческом виде из милисекунд с начала эпохи
export const upTime = (
  unixGmt,
  diff = getDiff(unixGmt),
  up = UP_TIME_DATA.find((i) => diff(...i))
) => (up ? diff(...up) : 'сейчас')

<template>
  <div
    class="custom_field_card_main"
    :style="{
      height: heightMain.toString() + 'px',
      background: backgroundMain,
    }"
  >
    <div class="custom_field_card_top">
      <div class="custom_field_card_left">
        {{ allCustom.find((i) => i.UID === id).name }}
      </div>
      <div v-if="!isRo" class="custom_field_card_right">
        <div class="custom_field_card_edit" @click="openEdit">
          <img :src="require(`@/assets/icons/UI/pencil.png`)" alt="pencil" />
        </div>
        <div @click="deleteCustom" ref="del">
          <img
            style="margin-top: 3px"
            :src="require(`@/assets/icons/UI/garbage.png`)"
            alt="garbage"
          />
        </div>
      </div>
    </div>
    <div class="custom_field_card_bottom">
      <transition name="basic_sleeper">
        <custom-field-edit
          v-if="OpenCollaps"
          :fieldId="allCustom.find((i) => i.UID === id).UID"
          :fieldName="allCustom.find((i) => i.UID === id).name"
          :fieldDesc="allCustom.find((i) => i.UID === id).desc"
          :fieldGlobal="allCustom.find((i) => i.UID === id).isGlobal"
          @cancel="cancelEdit"
          @save="saveEdit"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomFieldEdit from './CustomFieldEdit.vue'

export default {
  name: 'CustomFieldCard',
  components: { CustomFieldEdit },
  props: {
    isOpenEdit: Boolean,
    id: String,
    isRo: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['edit', 'delete', 'save'],
  data() {
    return {
      OpenCollaps: false,
    }
  },
  watch: {
    isOpenEdit: {
      handler(v) {
        if (!v) this.OpenCollaps = false
      },
    },
  },
  computed: {
    ...mapGetters({
      allCustom: 'cf_mod/ALL_CUSTOM_FIELDS',
    }),
    heightMain() {
      if (this.OpenCollaps) return 120
      return 30
    },
    backgroundMain() {
      if (this.OpenCollaps) return '#e2f3ff'
      return ''
    },
  },
  methods: {
    deleteCustom() {
      if (!this.OpenCollaps) {
        this.$emit(
          'delete',
          this.$refs.del.getBoundingClientRect().top,
          this.$refs.del.getBoundingClientRect().left,
          this.id
        )
      }
    },
    openEdit() {
      if (!this.OpenCollaps) {
        if (!this.isOpenEdit) {
          this.$emit('edit', true)
          this.OpenCollaps = true
        }
      }
    },
    cancelEdit() {
      if (this.OpenCollaps) {
        this.$emit('edit', false)
        this.OpenCollaps = false
      }
    },
    saveEdit(x) {
      this.$emit('save', x)
      this.OpenCollaps = false
    },
  },
}
</script>

<style scoped>
.custom_field_card_main {
  flex: 0 0 auto;
  width: 382px;
  height: 30px;
  display: flex;
  flex-direction: column;
  transition-property: all;
  transition-duration: 0.3s;
  overflow: hidden;
}
.custom_field_card_top {
  flex: 1 1 auto;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.custom_field_card_top:hover {
  background: #f0f6fd;
}
.custom_field_card_bottom {
  height: 90px;
  display: flex;
}
.custom_field_card_left {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.custom_field_card_right {
  display: flex;
}
.custom_field_card_edit {
  height: 50%;
  margin-right: 10px;
  cursor: pointer;
  width: 34px;
  text-align: center;
  padding-top: 1px;
  background: #d1ebc5;
  border: 1px solid #d1ebc5;
  border-radius: 2px;
  box-sizing: border-box;
}
</style>

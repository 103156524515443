import geoZoneModel from "@/models/geozone";

const state = {
  status: "", // NEW, DRAW_END, EDIT, MODIFY_END
  color_picker: {
    enable: false,
    top: 0,
    left: 0,
  },
  zone: null,
};
const getters = {
  FULL_ZONE: (state) => {
    return state;
  },
  ZONE: (state) => {
    return state.zone;
  },
  ZONE_TYPE: (state) => {
    if (state.zone !== null) {
      return state.zone.type;
    }
  },
  ZONE_COLOR: (state) => {
    if (state.zone !== null) {
      return state.zone.fill_color;
    }
  },
  ZONE_STATUS: (state) => {
    return state.status;
  },
  COLOR_PICKER: (state) => {
    return state.color_picker;
  },
};
const mutations = {
  SET_ZONE: (state, payload) => {
    state.zone = payload;
  },
  SET_ZONE_TYPE: (state, payload) => {
    state.zone.type = payload;
  },
  SET_ZONE_COLOR: (state, payload) => {
    state.zone.fill_color = payload;
  },
  SET_ZONE_NAME: (state, payload) => {
    state.zone.name = payload;
  },
  SET_ZONE_ID: (state, payload) => {
    state.zone.id = payload;
  },
  SET_ZONE_GEOMETRY: (state, payload) => {
    if (state.zone.type === "Polygon") {
      state.zone.coordinates = payload;
    } else if (state.zone.type === "Circle") {
      state.zone.radius = payload.radius;
      state.zone.center = payload.center;
    }
  },
  SET_ZONE_STATUS: (state, payload) => {
    state.status = payload;
  },
  SET_COLOR_PICKER: (state, payload) => {
    state.color_picker = payload;
  },
  INIT_ZONE: (state) => {
    state.zone = JSON.parse(JSON.stringify(geoZoneModel));
    state.status = "";
    state.color_picker = {
      top: 0,
      left: 0,
    };
  },
};
const actions = {
  SET_ZONE: (context, payload) => {
    context.commit("SET_ZONE", payload);
  },
  SET_ZONE_TYPE: (context, payload) => {
    context.commit("SET_ZONE_TYPE", payload);
  },
  SET_ZONE_COLOR: (context, payload) => {
    context.commit("SET_ZONE_COLOR", payload);
  },
  SET_ZONE_NAME: (context, payload) => {
    context.commit("SET_ZONE_NAME", payload);
  },
  SET_ZONE_ID: (context, payload) => {
    context.commit("SET_ZONE_ID", payload);
  },
  SET_ZONE_GEOMETRY: (context, payload) => {
    context.commit("SET_ZONE_GEOMETRY", payload);
  },
  SET_ZONE_STATUS: (context, payload) => {
    context.commit("SET_ZONE_STATUS", payload);
  },
  SET_COLOR_PICKER: (context, payload) => {
    context.commit("SET_COLOR_PICKER", payload);
  },
  INIT_ZONE: (context) => {
    context.commit("INIT_ZONE");
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};

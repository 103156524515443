<template>
  <div style="position: relative">
    <span>
      <!--    строка норм / едит мода-->
      <!--    <div-->
      <!--      :class="{-->
      <!--        select: isSelected(parent, parent, type),-->
      <!--        basic_content_row: true,-->
      <!--        upper_row: parent === isEdit && selectType === type,-->
      <!--      }"-->
      <!--      style="position: relative; display: flex"-->
      <!--      @dblclick="isOpen = !isOpen"-->
      <!--      @click="-->
      <!--        setSelected(parent, parent, type);-->
      <!--        setSelect({-->
      <!--          item: parent,-->
      <!--          parent: parent,-->
      <!--          type: type,-->
      <!--        });-->
      <!--      "-->
      <!--      :style="{-->
      <!--        paddingLeft: paddingLeft,-->
      <!--      }"-->
      <!--    >-->
      <!--      <div class="wrapper">-->
      <!--        &lt;!&ndash;        строка заголовок?&ndash;&gt;-->
      <!--        <div v-if="parent === isEdit && selectType === type" class="wrapper">-->
      <!--          <div class="wrapper" style="justify-content: space-between">-->
      <!--            Выберите группы для-->
      <!--            {{ type === "include" ? "добавления" : "исключения" }}-->
      <!--            <div style="display: flex">-->
      <!--              <div @click.stop="saveAuto()" style="padding-right: 10px">-->
      <!--                <img-->
      <!--                  :src="require(`@/assets/icons/groups/save.png`)"-->
      <!--                  alt="Сохранить"-->
      <!--                />-->
      <!--              </div>-->
      <!--              <div-->
      <!--                @click.stop="setAddAuto(!isAddAutomatics)"-->
      <!--                style="padding-right: 10px"-->
      <!--              >-->
      <!--                <img-->
      <!--                  :src="require(`@/assets/icons/groups/cancel.png`)"-->
      <!--                  alt="Отменить"-->
      <!--                />-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->
    </span>

    <!--    Строка в обычном состоянии-->
    <simple-row
      @click="!isHidden ? setSelected(parent, parent, type) : ''"
      @dblclick="!isHidden ? (isOpen = !isOpen) : ''"
      @save="$emit('save')"
      @cancel="setIsEdit(false)"
      :level="level"
      :select="{ id: parent, parent: parent, type: type }"
      :name="folderName"
      :isOpen="isOpen"
      :upper="showGroupSelector"
      :save="showGroupSelector"
      :folderPic="'action-' + actionType"
    />
    <!--    Действия пользователя-->
    <!--    Изменение состава групп-->
    <height-wrapp v-if="showGroupSelector" :h="wrappHeight">
      <div style="border-top: 1px solid #5ce672">
        <div
          style="
            margin-left: 10px;
            background: #ffffff;
            border-left: 1px solid #5ce672;
            border-right: 1px solid #5ce672;
            border-bottom: 1px solid #5ce672;
            border-radius: 0 0 4px 4px;
            width: 360px;
            overflow: hidden;
          "
        >
          <div class="content2">
            <div class="search_icon">
              <img
                :src="require('@/assets/icons/filter_finder_sorter/search.png')"
              />
            </div>
            <div class="input">
              <input
                class="search"
                placeholder="Поиск..."
                v-model="search"
                type="text"
              />
            </div>
          </div>
          <div v-if="selectCount === 0" style="">группы не найдены</div>
          <group-selector
            :item="tree"
            :levelTree="0"
            :levelOff="0"
            :level="0"
            :search="search"
          />
        </div>
      </div>
    </height-wrapp>

    <!--    Потомки - список групп-->
    <div v-if="isOpen">
      <simple-row
        v-for="n in names"
        :key="n.id"
        @click="setSelected(n.id, parent, type + '_obj')"
        :level="level + 3"
        :select="{ id: n.id, parent: parent, type: type + '_obj' }"
        :name="n.name"
        :isOpen="false"
        :folderPic="'group2'"
        :isSmall="true"
      />
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/groups'
import { HeightWrapp } from '@/shared/ui'
import { mapActions, mapGetters } from 'vuex'
import GroupSelector from './GroupSelector.vue'
import SimpleRow from './SimpleRow.vue'

export default {
  name: 'groupsFolder',
  components: { SimpleRow, HeightWrapp, GroupSelector },
  mixins: [mixin],
  props: {
    type: String,
    items: Array,
    level: Number,
    parent: String,
  },
  emits: ['save'],
  data() {
    return {
      search: '',
      showGroupSelector: false, // Тромоз (300 мс) выключения компонента выбора групп
      // heightGroupSelector: 0,

      isOpen: false,
      names: [], // тоже самое что items, + имя группы
    }
  },
  watch: {
    enableGroupSelector: {
      handler(v) {
        this.viewSelector()
        v
          ? this.items.forEach((id) => this.groupSelector(id))
          : this.resetGroupSelector()
      },
    },
    selectCount: {
      handler() {
        this.viewSelector()
      },
    },
  },
  mounted() {
    // По полученому айтемс списку формируем список names - тоже самое + имя группы
    this.items.forEach((i) => {
      this.names.push({
        id: i,
        name: this.allGroups.find((g) => g.spID === i).name,
      })
    })
  },
  computed: {
    ...mapGetters({
      allGroups: 'g_mod/GET_GROUPS',
      tree: 'g_mod/GET_TREE',
      groupsSelector: 'g_mod/GET_GROUPS_SELECT',
    }),
    enableGroupSelector() {
      // return this.parent === this.isEdit && this.selectType === this.type;
      return this.isSelected(this.parent, this.parent, this.type) && this.isEdit
    },
    selectCount() {
      return this.groupsSelector.rowCount
    },
    // Фолдер нейм добавить или удалить из группы
    folderName() {
      if (this.type === 'include') return 'Добавтить в группы'
      return 'Удалить из групп'
    },
    // Фолдер пик в зависимости от типа + для добавить, - для удалить
    actionType() {
      if (this.type === 'include') return 'add'
      return 'del'
    },
  },
  methods: {
    ...mapActions({
      groupSelector: 'g_mod/GROUPS_SELECTOR',
      resetGroupSelector: 'g_mod/RESET_GROUPS_SELECTOR',
    }),
    viewSelector() {
      if (this.enableGroupSelector) {
        this.showGroupSelector = true
        this.selectCount > 0
          ? setTimeout(() => {
              this.setWrappHeight(this.selectCount * 22 + 34)
            }, 0)
          : setTimeout(() => {
              this.setWrappHeight(60)
            }, 0)
      } else {
        this.setWrappHeight(0)
        setTimeout(() => {
          this.showGroupSelector = false
        }, 300)
      }
    },
  },
}
</script>

<style scoped>
.content2 {
  margin: 5px;
  /*margin: 3px auto 3px auto;*/
  padding: 5px;
  height: 22px;
  background: #f0f3f8;
  border-radius: 6px;
  /*border: 1px solid red;*/
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_icon {
  padding: 0 10px 0 10px;
}
.input {
  flex: 1 0 auto;
}
.search {
  width: 100%;
  background: #f0f3f8;
}
</style>

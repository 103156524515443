<template>
  <check-box :mode="mode" :label="'Показать все'" @click="change" />
</template>

<script>
import { showZones, zones } from '@/entities/zones'
import { CheckBox } from '@/shared/ui'
export default {
  name: 'ShowAllGeoZones',
  components: {
    CheckBox,
  },
  data() {
    return { showZones }
  },
  computed: {
    mode() {
      const allZonesCount = Object.values(zones.storage).length
      const showZonesCount = Object.values(zones.storage).filter(
        (i) => i.ismap
      ).length
      if (showZonesCount === 0) {
        return 'off'
      }
      if (allZonesCount === showZonesCount) {
        return 'on'
      }
      return 'all'
    },
  },
  methods: {
    change() {
      if (this.mode === 'on') {
        showZones({ id: 0, ismap: false })
      } else {
        showZones({ id: 0, ismap: true })
        this.$store.dispatch('OPN_mod/SET_FIT_LAYER', 'zones')
      }
    },
  },
}
</script>

<style scoped></style>

import { createPinia } from 'pinia'
import { createApp } from 'vue'
import store from '../store'
import { wamp } from './deliv'
import App from './index.vue'
import { router } from './providers'

import '@/assets/css/basic.css'

export const app = createApp(App)
  .use(createPinia())
  .use(store)
  .use(wamp)
  .use(router)

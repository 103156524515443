import { Modify } from 'ol/interaction'

export const intModify = {
  init: (layer, callback) => {
    let m = new Modify({
      source: layer,
    })
    m.on('modifyend', callback)
    return m
  },
}

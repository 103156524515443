export default [
  {
    id: 1,
    name: "monitoring",
    title: "Мониторинг",
    icon: "monitoring_icon",
    selected: true,
    sub_menu: [
      {
        id: 1,
        name: "all_cars",
        title: "Все объекты",
        selected: true,
      },
      {
        id: 2,
        name: "work_list",
        title: "Рабочая группа",
        selected: false,
      },
      {
        id: 3,
        name: "groups_list",
        title: "Мониторинг по группам",
        selected: false,
      },
    ],
  },
  {
    id: 2,
    name: "dashboard",
    title: "Dashboard",
    icon: "dashboard_icon",
    selected: false,
  },
  {
    id: 3,
    name: "geozones",
    title: "Геозоны",
    icon: "geozones_icon",
    selected: false,
    sub_menu: [
      {
        id: 1,
        name: "all_geozones",
        title: "Все геозоны",
        selected: true,
      },
      // {
      //   id: 2,
      //   name: "groups_geozones",
      //   title: "Группы геозон",
      //   selected: false,
      // },
    ],
  },
  {
    id: 4,
    name: "reports",
    title: "Отчеты",
    icon: "reports",
    selected: false,
    sub_menu: [
      {
        id: 1,
        name: "all_report",
        title: "Все отчеты",
        selected: true,
      },
      {
        id: 2,
        name: "report_builder",
        title: "Конструктор отчетов",
        selected: false,
      },
    ],
  },
  {
    id: 5,
    name: "notifications",
    // title: "Уведомления",
    title: "Менеджеры",
    icon: "notifications",
    selected: false,
  },
  {
    id: 6,
    name: "alarms",
    title: "Тревоги",
    icon: "alarms",
    selected: false,
  },
  {
    id: 7,
    name: "users",
    title: "Пользователи",
    icon: "users",
    selected: true,
  },
  {
    id: 8,
    name: "tasks",
    title: "Задания",
    icon: "tasks",
    selected: false,
  },
  {
    id: 9,
    name: "tasks",
    title: "Настройки",
    icon: "tasks",
    selected: false,
  },
];

export const mapModule = {
  state: () => ({
    isZone: true,
    isCarsCluster: false,
    isCarHistory: true,
  }),
  getters: {
    ZONE(state) {
      return state.isZone;
    },
    CARS(state) {
      return state.isCarsCluster;
    },
    HISTORY(state) {
      return state.isCarHistory;
    },
  },
  mutations: {
    SET_ZONE(state, isMap) {
      state.isZone = isMap;
    },
    SET_CARS(state, isMap) {
      state.isCarsCluster = isMap;
    },
    SET_HISTORY(state, isMap) {
      state.isCarHistory = isMap;
    },
  },
  actions: {
    SET_ZONE({ state, commit }, isMap) {
      if (!isMap) {
        isMap = !state.isZone;
      }
      commit("SET_ZONE", isMap);
    },
    SET_CARS({ state, commit }, isMap) {
      if (!isMap) {
        isMap = !state.isCarsCluster;
      }
      commit("SET_CARS", isMap);
    },
    SET_HISTORY({ state, commit }, isMap) {
      if (!isMap) {
        isMap = !state.isCarHistory;
      }
      commit("SET_HISTORY", isMap);
    },
  },
  namespaced: true,
};

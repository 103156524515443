// ----------------------------------- Втелегопулятель -----------------------------------
import { API_CONF, DEBUG } from '@/shared/config'
import Connect from '@/entities/connect'
import { apiCore } from './apiCore'

export const apiTelegram = async (car) => {
  const msg =
    'Пользователь: ' +
    Connect.userName.value +
    '\nНастроить маяк SN: ' +
    car.sn +
    '\nРежим выхода на связь: ' +
    car.config[1001] +
    '\nПропуск GPS сеансов: ' +
    car.config[1002] +
    '\nТаймеры LORA: ' +
    car.config[1003] +
    '\nВремя конфигурации: ' +
    new Date()
  const response = await apiCore.post(API_CONF.TELEGRAM, { msg: msg })
  if (DEBUG) console.log('fleet setup: ', msg, response)
}

<template>
  <div style="display: flex">
    <check-box :mode="modeAll" @click="changeAll" label="все" />
    <div style="margin-right: 5px; margin-left: 5px">/</div>
    <check-box :mode="modeSelect" @click="changeSelect" label="выбранные" />
  </div>
</template>

<script>
import { CheckBox } from '@/shared/ui'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CarsShower',
  components: { CheckBox },
  computed: {
    ...mapGetters({
      allCars: 'cars_mod/ALL_CARS',
      selectCars: 'sfs_mod/SORTED_AND_SEARCHED_AND_FILTERED_CARS',
    }),
    modeAll() {
      const allCarsCount = this.allCars.length
      const showCarsCount = this.allCars.filter((i) => i.ismap).length
      if (showCarsCount === 0) {
        return 'off'
      }
      if (allCarsCount === showCarsCount) {
        return 'on'
      }
      return 'all'
    },
    modeSelect() {
      const allCarsCount = this.selectCars.length
      const showCarsCount = this.selectCars.filter((i) => i.ismap).length
      if (showCarsCount === 0) {
        return 'off'
      }
      if (allCarsCount === showCarsCount) {
        return 'on'
      }
      return 'all'
    },
  },
  methods: {
    ...mapActions({
      showCar: 'cars_mod/SHOW_CAR',
    }),
    changeAll() {
      if (this.modeAll === 'on') {
        this.showCar({ id: [], ismap: false })
      } else {
        this.showCar({ id: [], ismap: true })
      }
    },
    changeSelect() {
      let selectCarsId = []
      this.selectCars.forEach((i) => selectCarsId.push(i.id))
      if (this.modeSelect === 'on') {
        this.showCar({ id: selectCarsId, ismap: false })
      } else {
        this.showCar({ id: selectCarsId, ismap: true })
      }
    },
  },
}
</script>

<style scoped></style>

export default {
  id: 0,
  name: "",
  type: "Polygon",
  center: [],
  radius: null,
  coordinates: [],
  fill_color: "rgba(255,0,255,0.5)",
  stroke_color: "#000000",
  stroke_width: 1,
  ismap: true,
};

<template>
  <div ref="ae" style="width: 100%; border-top: 1px solid #5ce672">
    <div class="ae_box">
      <!--      Наименование автоматизации-->
      <div class="ae_row">
        <div class="ae_row_left">Наименование</div>
        <div class="ae_row_right">
          <input type="text" class="input" v-model="automatic.name" />
        </div>
      </div>
      <!--      Выбор триггера автоматизации-->
      <div class="ae_row">
        <div class="ae_row_left">Триггер</div>
        <div class="ae_row_right">
          <select
            class="select"
            :disabled="type === 'edit'"
            v-model="automatic.trigger.triggerType"
          >
            <option v-for="t in triggers" :value="t.id" :key="t.id">
              {{ t.label }}
            </option>
          </select>
        </div>
      </div>
      <!--      Триггер = 2 Геозона-->
      <height-wrapp
        :h="automatic.trigger.triggerType === 2 ? rowHeight * rowTT2Zone : 0"
      >
        <!--        Выбор геозоны-->
        <div class="ae_row">
          <div class="ae_row_left">Зона</div>
          <div class="ae_row_right">
            <select class="select" v-model="automatic.trigger.zoneID">
              <option v-for="z in zones.storage" :value="z.id" :key="z.id">
                {{ sliceName(z.name, 25) }}
              </option>
            </select>
          </div>
        </div>
        <!--        Радио выбор въезд или выезд из зоны-->
        <div class="ae_row" style="justify-content: space-around">
          <check-box
            @click="
              automatic.trigger.isZoneInEv = !automatic.trigger.isZoneInEv
            "
            :label="'въезд в зону'"
            :mode="automatic.trigger.isZoneInEv ? 'on' : 'off'"
          />
          <check-box
            @click="
              automatic.trigger.isZoneOutEv = !automatic.trigger.isZoneOutEv
            "
            :label="'выезд из зоны'"
            :mode="automatic.trigger.isZoneOutEv ? 'on' : 'off'"
          />
        </div>
        <!--        Время в зоне-->
        <div class="ae_row">
          <div class="ae_row_left">Время в зоне, с</div>
          <div class="ae_row_right">
            <input
              v-if="automatic.trigger.isZoneInEv"
              type="number"
              min="0"
              class="input"
              v-model="automatic.trigger.inZoneTtl"
            />
            <input v-else disabled type="number" min="0" class="input" />
          </div>
        </div>
      </height-wrapp>
      <!--      Триггер = 4 Кастом поле-->
      <height-wrapp
        :h="automatic.trigger.triggerType === 4 ? rowHeight * rowTT4CF : 0"
      >
        <!--        Выбор кастом поля-->
        <div class="ae_row">
          <div class="ae_row_left">Кастом поле</div>
          <div class="ae_row_right">
            <select class="select" v-model="automatic.trigger.customFieldID">
              <option v-for="cf in customFields" :value="cf.UID" :key="cf.UID">
                {{ sliceName(cf.name, 25) }}
              </option>
            </select>
          </div>
        </div>
        <!--        Значение кастом поля-->
        <div class="ae_row">
          <div
            class="ae_row_left"
            style="width: 100%; display: flex; align-items: center"
          >
            <div style="flex: 1 1 auto">Значение</div>
            <div
              @click.stop="
                automatic.trigger.isCustomFieldNotEqual =
                  !automatic.trigger.isCustomFieldNotEqual
              "
              style="padding-top: 2px"
            >
              <div
                v-if="automatic.trigger.isCustomFieldNotEqual"
                style="font-size: 24px"
              >
                &ne;
              </div>
              <div v-else style="font-size: 24px">=</div>
            </div>
          </div>
          <div class="ae_row_right">
            <input class="input" v-model="automatic.trigger.customFieldValue" />
          </div>
        </div>
      </height-wrapp>
      <!--      Триггер = 5 Нет связи-->
      <height-wrapp
        :h="automatic.trigger.triggerType === 5 ? rowHeight * rowTT5NoConn : 0"
      >
        <!--        Значение кастом поля-->
        <div class="ae_row">
          <div
            class="ae_row_left"
            style="width: 100%; display: flex; align-items: center"
          >
            <div style="flex: 1 1 auto">Нет связи, сек</div>
          </div>
          <div class="ae_row_right">
            <input
              class="input"
              v-model="automatic.trigger.connTtl"
              type="number"
            />
          </div>
        </div>
      </height-wrapp>
    </div>
  </div>
</template>

<script>
import { zones } from '@/entities/zones'
import mixin from '@/mixins/groups'
import automaticModel from '@/models/automatic'
import { sliceName } from '@/shared/lib/helpers'
import { CheckBox, HeightWrapp } from '@/shared/ui'
import { uuid } from 'vue-uuid'
import { mapGetters } from 'vuex'
import { api } from '../api'

export default {
  name: 'AutomaticEditor',
  components: { CheckBox, HeightWrapp },
  mixins: [mixin],
  emits: ['update'],
  props: { item: Object, level: Number, save: Boolean, type: String },
  data() {
    return {
      sliceName: sliceName,
      zones,
      rowCountDefault: 2, // Количество строк по умолчанию
      rowHeight: 30, // Высота строк
      rowHeightPlus: 2, // добавка на бордеры
      rowCount: 2, // Всего строк сейчас

      rowTT1Mirror: 0, // Добавится строк при Зеркалировании
      rowTT2Zone: 3, // Добавится строк при триггере Геозоны
      rowTT4CF: 2, // Добавится строк при триггере Кастом поле
      rowTT5NoConn: 1, // Добавится строк при триггере Кастом поле

      automatic: null,
      // zones,
      triggers: [
        // { id: 1, label: "копия списка объектов" },
        { id: 2, label: 'геозона' },
        // { id: 3, label: "группа геозон" },
        { id: 4, label: 'кастом поле' },
        { id: 5, label: 'нет связи' }, //
      ],
    }
  },
  created() {
    if (this.type === 'new')
      this.automatic = JSON.parse(JSON.stringify(automaticModel))
    if (this.type === 'edit')
      this.automatic = JSON.parse(JSON.stringify(this.item))
    this.rowCount = this.rowCountDefault
    this.setWrappHeight(this.height)
  },
  watch: {
    // При изменении триггера в модалке меняется количество строк и устанавливается высота враппер-окна
    automatic: {
      handler() {
        const tt = this.automatic.trigger.triggerType
        switch (tt) {
          case 1:
            this.rowCount = this.rowCountDefault + this.rowTT1Mirror
            break
          case 2:
            this.rowCount = this.rowCountDefault + this.rowTT2Zone
            break
          case 4:
            this.rowCount = this.rowCountDefault + this.rowTT4CF
            break
          case 5:
            this.rowCount = this.rowCountDefault + this.rowTT5NoConn
            break
          default:
            this.rowCount = this.rowCountDefault
            break
        }
        this.setWrappHeight(this.height)
      },
      deep: true,
    },
    save: {
      handler(v) {
        if (v) {
          if (this.automatic.trigger.triggerType === 2) {
            if (
              this.automatic.trigger.isZoneInEv ||
              this.automatic.trigger.isZoneOutEv
            ) {
              if (this.type === 'new') this.saveNewAuto()
              if (this.type === 'edit') this.saveEditAuto()
            }
          } else {
            if (this.type === 'new') this.saveNewAuto()
            if (this.type === 'edit') this.saveEditAuto()
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      // zones: 'zones_mod/ZONES',
      customFields: 'cf_mod/ALL_CUSTOM_FIELDS',
    }),
    height() {
      return this.rowCount * this.rowHeight + this.rowHeightPlus
    },
  },
  methods: {
    saveEditAuto() {
      api.cudAutomatics(JSON.parse(JSON.stringify(this.automatic)))
      this.setIsEdit(false)
    },
    saveNewAuto() {
      this.automatic.autoID = uuid.v4()
      this.automatic.spID = this.selectId
      if (
        this.automatic.trigger.triggerType === 2 &&
        !this.automatic.trigger.isZoneInEv
      )
        this.automatic.trigger.inZoneTtl = 0
      // console.log("this.automatic", this.automatic);
      // console.log("this.automatic", this.automatic.trigger);
      api.cudAutomatics(JSON.parse(JSON.stringify(this.automatic)))
      this.setIsAddAutomatic(false)
    },
  },
}
</script>

<style scoped>
.ae_box {
  /*flex: 1 1 auto;*/
  margin-left: 10px;
  background: #ffffff;
  border-left: 1px solid #5ce672;
  border-right: 1px solid #5ce672;
  border-bottom: 1px solid #5ce672;
  border-radius: 0 0 4px 4px;
  width: 360px;
  overflow: hidden;
  /*display: flex;*/
}
.ae_row {
  padding: 0 6px 0 6px;
  flex: 0 0 auto;
  height: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.ae_row_left {
  padding: 0 6px 0 6px;
}
.ae_row_right {
  padding: 0 6px 0 6px;
}
.select {
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  padding: 2px 12px 2px 12px;
  width: 220px;
  /*font-weight: 400;*/
  /*font-size: 12px;*/
}
.input {
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  padding: 2px 12px 2px 12px;
  width: 220px;
}
</style>

export default {
  autoID: null,
  spID: null,
  name: "Новая автоматизация",
  trigger: {
    triggerType: 2,

    inZoneTtl: 300,
    isZoneInEv: true,
    isZoneOutEv: false,
    zoneID: null,

    connTtl: null,

    isCustomFieldNotEqual: true,
    customFieldID: "",
    customFieldValue: "",
  },
  actions: [],
};

// export default {
//   autoID: null,
//   spID: null,
//   name: "Новая автоматизация",
//   parentAutoID: null,
//   trigger: {
//     connTtl: null,
//
//     triggerType: null,
//     inZoneTtl: null,
//     isZoneInEv: null,
//     isZoneOutEv: null,
//     zoneID: null,
//
//     zoneGroupID: null,
//
//     isCustomFieldNotEqual: null,
//     customFieldID: null,
//     customFieldValue: null,
//   },
//   actions: [],
// };

export default [
  {
    id: "name",
    name: "Наименование",
    enable: true,
  },
  {
    id: "sn",
    name: "Серийный номер",
    enable: true,
  },
  {
    id: "model",
    name: "Модель",
    enable: true,
  },
  {
    id: "gos",
    name: "ГОС номер",
    enable: true,
  },
  {
    id: "vin",
    name: "VIN номер",
    enable: true,
  },
];

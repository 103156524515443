<template>
  <div style="display: flex">
    <check-box
      :mode="modeAll"
      @click="changeAll"
      :label="allCars.length.toString()"
    />
    <div style="margin-right: 5px; margin-left: 5px">/</div>
    <check-box
      :mode="modeSelect"
      @click="changeSelect"
      :label="selectCars.length.toString()"
    />
  </div>
</template>

<script>
import { CheckBox } from '@/shared/ui'
import { useCarsStore } from '@/stores/carsStore'
import { computed } from 'vue'

export default {
  name: 'CarsShowerProps',
  components: { CheckBox },
  props: {
    allCars: Array,
    selectCars: Array,
  },
  setup() {
    const sortedAndSearchedAndFilteredCars = computed(() => {
      return useCarsStore().sortAndFilter
    })
    const allStateCars = computed(() => {
      return useCarsStore().cars
    })

    const showCar = useCarsStore().showCar

    return { sortedAndSearchedAndFilteredCars, allStateCars, showCar }
  },
  computed: {
    modeSelect() {
      const allCarsIsMap = this.allStateCars.filter((i) => i.isMap).length
      const selectInGroupCars = this.selectCars.length
      const selectInGroupCarsIsMap = this.selectCars.filter(
        (i) => i.isMap
      ).length

      if (selectInGroupCarsIsMap === 0) {
        return 'off'
      } else if (
        allCarsIsMap === selectInGroupCars &&
        selectInGroupCars === selectInGroupCarsIsMap
      ) {
        return 'on'
      }
      return 'all'
    },
    modeAll() {
      const allCarsIsMap = this.allStateCars.filter((i) => i.isMap).length
      const allInGroupCars = this.allCars.length
      const allInGroupCarsIsMap = this.allCars.filter((i) => i.isMap).length
      if (allInGroupCarsIsMap === 0) {
        return 'off'
      } else if (
        allCarsIsMap === allInGroupCars &&
        allInGroupCars === allInGroupCarsIsMap
      ) {
        return 'on'
      }
      return 'all'
    },

    // modeAll() {
    //     const allCarsCount = this.allStateCars.filter((i) => i.ismap).length;
    //     const showCarsCount = this.allCars.length;
    //     if (showCarsCount === 0) {
    //       return "off";
    //     }
    //     if (allCarsCount === showCarsCount) {
    //       return "on";
    //     }
    //     return "all";
    //   },
    //   modeSelect() {
    //     const allCarsCount = this.allStateCars.filter((i) => i.ismap).length;
    //     const showCarsCount = this.selectCars.length;
    //     if (showCarsCount === 0) {
    //       return "off";
    //     }
    //     if (allCarsCount === showCarsCount) {
    //       return "on";
    //     }
    //     return "all";
    //   },
  },
  methods: {
    changeAll() {
      let selectCarsId = []
      this.allCars.forEach((i) => selectCarsId.push(i.objID))
      if (this.modeAll === 'on') {
        this.showCar({ objID: selectCarsId, isMap: false })
      } else {
        this.showCar({ objID: [], isMap: false })
        this.showCar({ objID: selectCarsId, isMap: true })
      }
    },
    changeSelect() {
      let selectCarsId = []
      this.selectCars.forEach((i) => selectCarsId.push(i.objID))
      if (this.modeSelect === 'on') {
        this.showCar({ objID: selectCarsId, isMap: false })
      } else {
        this.showCar({ objID: [], isMap: false })
        this.showCar({ objID: selectCarsId, isMap: true })
      }
    },
  },
}
</script>

<style scoped></style>

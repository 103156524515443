import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  Text,
} from 'ol/style'

export const style = {
  //  Кластеры
  clusterStyle: (vector, cars, key, carStyle) => {
    const styleCache = {}
    vector.setStyle(function (feature) {
      const feat = feature.get('features')
      const size = feat.length
      const radius = (r) => {
        let rad = 15
        if (r > 2) rad = rad + (r - 2) * 5
        return rad
      }
      let style = styleCache[size]
      if (size > 1) {
        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: radius(size.toString().length), //15,
              stroke: new Stroke({
                color: '#4AFFFF',
              }),
              fill: new Fill({
                color: 'rgba(9, 56, 99, 0.69)',
              }),
            }),
            text: new Text({
              text: size.toString(),
              font: 'bold 14px Gordita',
              offsetY: 1,
              fill: new Fill({
                color: '#fff',
              }),
            }),
          })
        }
        styleCache[size] = style
      } else {
        style = styleCache[feat[0].getId()]
        if (!style) {
          let txt
          let num = feat[0].getId()
          if (cars[num]) txt = cars[num].name
          else txt = cars.find((i) => i[key] === num).name
          style = [
            carStyle,
            new Style({
              text: new Text({
                text: txt,
                font: 'Gordita',
                placement: 'point',
                textAlign: 'center',
                fill: new Fill({ color: '#ffffff' }),
                backgroundFill: new Fill({
                  color: 'rgba(9, 56, 99, 0.69)',
                }),
                padding: [5, 3, 2, 7],
              }),
            }),
          ]
          styleCache[feat[0].getId()] = style
        }
      }
      return style
    })
  },

  //  Рисовалка
  drawZone: (color) => {
    return new Style({
      fill: new Fill({
        color: color,
      }),
      stroke: new Stroke({
        color: 'red',
        width: 3,
      }),
    })
  },

  //  Цветные авто
  cars: (color, grad = 0) => {
    const carColor = '/map_car_' + color + '.png'
    return new Style({
      image: new Icon({
        src: carColor,
        rotation: (grad * Math.PI) / 180,
      }),
    })
  },

  //  Зоны
  zones: (z, editKey) => {
    return new Style({
      fill: new Fill({
        color: z.fill_color,
      }),
      stroke: new Stroke({
        color: editKey ? 'red' : z.stroke_color,
        width: editKey ? 3 : z.stroke_width,
      }),
    })
  },
}

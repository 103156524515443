<template>
  <div
    class="basic_content_head"
    style="width: 100%; border-bottom: 1px solid #296584"
  >
    <div style="flex: 1 1 auto">Добавить объекты</div>
    <div style="display: flex; margin-right: 5px">
      <div style="padding: 7px 5px 5px 5px; cursor: pointer">
        <check-box :mode="checkMode" @click.stop="selectAll()" />
      </div>
      <div style="padding: 5px; cursor: pointer" @click.stop="save()">
        <img :src="require(`@/assets/icons/groups/save.png`)" alt="Сохранить" />
      </div>
      <div style="padding: 5px; cursor: pointer">
        <img
          :src="require(`@/assets/icons/groups/cancel.png`)"
          alt="Отменить"
          @click.stop="setIsEdit(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/entities/groups'
import mixin from '@/mixins/groups'
import { CheckBox } from '@/shared/ui'
import { useCarsStore } from '@/stores/carsStore'
import { computed } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ObjectSelectorControl',
  components: { CheckBox },
  mixins: [mixin],
  computed: {
    ...mapGetters({
      // sortedAndSearchedAndFilteredCars:
      //   "sfs_mod/SORTED_AND_SEARCHED_AND_FILTERED_CARS",
      selectObjets: 'g_mod/GET_OBJECTS_SELECT',
      list: 'g_mod/GET_GROUP_OBJECTS',
    }),
    checkMode() {
      let count = 0
      this.sortedAndSearchedAndFilteredCars.forEach((c) => {
        if (this.selectObjets.includes(c.objID)) count++
      })
      if (count === 0) return 'off'
      if (this.sortedAndSearchedAndFilteredCars.length === count) return 'on'
      return 'all'
    },
  },
  setup() {
    const sortedAndSearchedAndFilteredCars = computed(() => {
      return useCarsStore().sortAndFilter
    })
    return { sortedAndSearchedAndFilteredCars }
  },
  methods: {
    ...mapActions({
      allObjs2Select: 'g_mod/SET_OBJETCS_SELECT',
    }),
    save() {
      let list = this.list(this.selectId)
      let add = { spID: this.selectId, objects: [] }
      let rem = { spID: this.selectId, objects: [] }
      list.forEach((i) => {
        if (!JSON.parse(JSON.stringify(this.selectObjets)).includes(i))
          rem.objects.push(i)
      })
      this.selectObjets.forEach((i) => {
        if (!list.includes(i)) add.objects.push(i)
      })
      if (add.objects.length > 0) api.addObject2Group(add)
      if (rem.objects.length > 0) api.deleteObjectInGroup(rem)
      this.setIsEdit(false)
    },
    //  Ткнули в чекбокс селектАлла
    selectAll() {
      // console.time("1");
      let newSelect = [...this.selectObjets]
      //  Если в отфильтрованном общем списке есть невыбранные объекты, то добавить их
      if (this.checkMode === 'off' || this.checkMode === 'all') {
        this.sortedAndSearchedAndFilteredCars.forEach((c) => {
          if (!newSelect.includes(c.objID)) newSelect.push(c.objID)
        })
      }
      //  Если в отфильтрованном общем списке выбраны все объекты, то исключить их
      //  Обнулять массив нельзя, так как список фильтрованный и чекалка применяется только к данному ОТФИЛЬТРОВАННОМУ списку
      else {
        let x = []
        this.sortedAndSearchedAndFilteredCars.forEach((c) => x.push(c.objID))
        newSelect = newSelect.filter((i) => !x.includes(i))
      }
      this.allObjs2Select(newSelect)
      // console.timeEnd("1");
    },
  },
}
</script>

<style scoped></style>

import { DEBUG } from '@/shared/config'
import { equal, filterFn } from '@/shared/lib/helpers'
import { init, pointer } from '@/stores/use/useMap'
// import { useZonesStore } from '@/stores/zonesStore'
import { getLastPositionAddress } from '@/entities/cars/addressor'
import { importLastPositionDTO } from '@/entities/cars/dto/importDTO'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useCarsStore = defineStore('carsStore', () => {
  const cars = ref([])
  const changer = ref(0)
  const mapLayer = ref(null)
  const points = []
  const pointsLinker = new Map()
  const linker = new Map()
  const names = ref(['gos', 'sn'])
  const sortKey = ref('unixGmt')
  const sortDir = ref(1)
  const cursorWait = ref(false)
  const filter = ref([
    // {
    //   type: "inc", - тип > < = inc xArr
    //   keyFields: ["vin", "gos"], - массив кючей по которым применяем фильтр
    //   value: "922", - значение фильтра
    // },
    // {
    //   type: "=",
    //   keyFields: ["connecttype"],
    //   value: "LBS",
    // },
    // {
    //   type: "<",
    //   keyFields: ["powervolume"],
    //   value: 70,
    // },
    // {
    //   type: "xArr",
    //   keyFields: ["inZone"],
    //   value: [3504],
    // },
  ])

  //  TODO:что курсор делает в автомобильном сторе?
  const setCursorWait = (en) => {
    cursorWait.value = en
  }

  //  Функция присваивает объекту имя - по умолчанию серийник,
  //  берет первое непустое поля из рефа names
  const nammer = (c) => {
    let name = 'sn'
    for (let i = 0; i < names.value.length; i++) {
      if (c[names.value[i]] !== '') {
        name = names.value[i]
        break
      }
    }
    return c[name].toString()
  }

  //  Функция выставляет реф names и обновляет имена всем объектам
  const setName = (carNames) => {
    if (DEBUG) console.time('pinia set Name')
    names.value = carNames
    cars.value.forEach((c) => {
      c.name = nammer(c)
    })
    if (DEBUG) console.timeEnd('pinia set Name')
  }

  //  Первоначальньная установка пачки объектов
  const set = (inCars) => {
    linker.clear()
    pointsLinker.clear()
    points.length = 0

    cars.value = inCars

    cars.value.forEach((item) => {
      linker.set(item.objID, item)
    })

    let point
    cars.value.forEach((c) => {
      c.name = nammer(c)
      point = pointer(c, 'objID')
      points.push(point)
      pointsLinker.set(c.objID, point)
    })

    mapLayer.value = init(cars.value, 'red')
    mapLayer.value.source.addFeatures(points)
    changer.value++

    // calcIntersectsMultyObj(useZonesStore().zones, cars.value)
  }

  //  Функция обновления позиций
  const updatePosition = async (inLastPos) => {
    const prepareLastPos = inLastPos.map((lp) => importLastPositionDTO(lp))
    const address = await getLastPositionAddress(prepareLastPos)
    prepareLastPos.forEach((i) => (i.address = address[i.objID]))

    points.length = 0
    pointsLinker.clear()

    const newCars = []
    prepareLastPos.forEach((lp) => {
      const obj = linker.get(lp.objID)
      if (obj) {
        obj.lastPos = lp
        newCars.push(obj)
      }
    })

    let point
    for (let v of linker.values()) {
      point = pointer(v, 'objID')
      points.push(point)
      pointsLinker.set(v.objID, point)
    }
    const showPoints = []

    linker.forEach((v, k) => {
      if (v.isMap) showPoints.push(pointsLinker.get(k))
    })
    mapLayer.value = init(cars.value, 'red')
    mapLayer.value.source.addFeatures(showPoints)

    // mapLayer.value = init(cars.value, "red");
    // mapLayer.value.source.addFeatures(points.filter((p) => p.isMap));

    if (DEBUG) console.timeEnd('pinia updateCarsPosition')
  }

  //  Геттер отфильтрованных отсортированных объектов
  const sortAndFilter = computed(() => {
    if (DEBUG) console.time('pinia sortAndFilter')
    let filteredCars = [...cars.value]
    filter.value.forEach((f) => {
      filteredCars = filteredCars.filter((c) => {
        return (
          filterFn(f.type, c, f.keyFields, f.value) || //  фильтр по объекту
          filterFn(f.type, c.lastPos, f.keyFields, f.value) // Фильтр по последней позиции
        )
      })
    })
    if (DEBUG) console.timeEnd('pinia sortAndFilter')
    return filteredCars.sort((a, b) =>
      a.lastPos[sortKey.value] > b.lastPos[sortKey.value]
        ? -1 * sortDir.value
        : sortDir.value
    )
  })

  const setSortKey = (key) => {
    sortKey.value = key
  }
  const setSortDir = (dir) => {
    sortDir.value = dir
  }
  const delFilter = (id) => {
    // console.log("hello", filter.value);
    filter.value = filter.value.filter((f) => f.id !== id)
  }
  const setFilter = async (fil) => {
    // TODO: говнокод!!  2 - фильтр по геозонам
    let x = filter.value.find((f) => f.id === fil.id)
    if (!x) {
      //  Добавить фильтр если еще нет такого
      filter.value.push(fil)
    } else if (fil.id === 2 && fil.value.length === 0) {
      filter.value = filter.value.filter((f) => f.id !== x.id)
    } else {
      //  Если фильтр точно такой какой есть, то удалить, иначе обновить
      equal(x, fil)
        ? (filter.value = filter.value.filter((f) => f.id !== x.id))
        : filter.value.forEach((f) => {
            if (f.id === fil.id) {
              f.type = fil.type
              f.keyFields = fil.keyFields
              if (f.id !== 2) {
                f.value = fil.value
              } else {
                if (fil.value.length === 1) {
                  f.value.includes(fil.value[0])
                    ? (f.value = f.value.filter((fff) => fff !== fil.value[0]))
                    : f.value.push(fil.value[0])
                } else {
                  f.value = fil.value
                }
              }
            }
          })
    }
  }

  const showSetter = (show) => {
    // {objID: [], [1], [...]
    // isMap: Bool}
    // console.log("show", show);
    // console.time("press");

    let objID = show.objID
    if (objID.length === 0) objID = [...linker.keys()]
    objID.forEach((i) => (linker.get(i).isMap = show.isMap))

    const showPoints = []
    if (objID.length > 1) {
      linker.forEach((v, k) => {
        if (v.isMap) showPoints.push(pointsLinker.get(k))
      })
      mapLayer.value = init(cars.value, 'red')
      mapLayer.value.source.addFeatures(showPoints)
    } else {
      const obj = pointsLinker.get(objID[0])
      show.isMap
        ? mapLayer.value.source.addFeature(obj)
        : mapLayer.value.source.removeFeature(obj)
    }
    // console.timeEnd("press");
    changer.value++
  }

  //TODO: old
  const updateCars = (payload) => {
    payload.forEach((car) => {
      if (car.action === 0) {
        cars.value = cars.value.filter((c) => c.objID !== car.objID)
        linker.delete(car.objID)
        let point
        pointsLinker.clear()
        cars.value.forEach((c) => {
          point = pointer(c, 'objID')
          points.push(point)
          pointsLinker.set(c.objID, point)
        })

        mapLayer.value = init(cars.value, 'red')
        if (points.length > 0) mapLayer.value.source.addFeatures(points)
        changer.value++

        // calcIntersectsMultyObj(useZonesStore().zones, cars.value)

        changer.value++
      } else {
        let isContains = false
        cars.value.forEach((i) => {
          if (i.objID === car.objID) {
            delete car.lastPos
            Object.assign(i, car)
            isContains = true
          }
        })
        if (!isContains)
          cars.value.push(
            Object.assign(car, importLastPositionDTO(car.lastPos), {
              isMap: true,
              name: nammer(car),
            })
          )
      }
    })
  }

  const updateOneProp = (x) => {
    linker.get(x.objID)[x.key] = x.val
    // cars.value.forEach((c) => {
    //   if (c.objID === x.objID) {
    //     c[x.key] = x.val;
    //   }
    // });
  }

  const reset = () => {
    cars.value = []
    names.value = ['gos', 'sn']
    sortKey.value = 'unixGmt'
    sortDir.value = 1
    cursorWait.value = false
    filter.value = []
    linker.clear()
    changer.value++
    points.length = 0
  }

  return {
    cursorWait,
    setCursorWait,
    sortAndFilter,
    cars,
    set,
    updatePosition,
    names,
    setName,
    sortDir,
    setSortDir,
    sortKey,
    setSortKey,
    filter,
    setFilter,
    delFilter,
    showSetter,
    updateCars,
    updateOneProp,
    reset,
    changer,
    mapLayer,
  }
})

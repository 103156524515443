export const WORDS_YEAR = ['год', 'года', 'лет']
export const WORDS_DAY = ['день', 'дня', 'дней']
export const WORDS_HOUR = ['час', 'часа', 'часов']
export const WORDS_MINUTE = ['минуту', 'минуты', 'минут']
export const WORDS_SECOND = ['секунду', 'секуды', 'секунд']

const getArr = (n, v) => [n === 1, n > 1 && n < 5, v > 10 && v < 20]
const selectWord = (words) => (res, i, index) => i ? words[index] : res

export const numWord =
  (words) =>
  (value, num = (Math.abs(value) % 100) % 10) =>
    getArr(num, value).reduce(selectWord(words), words[2])

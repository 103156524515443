export const managetRoute = {
  path: 'manage_set',
  name: 'manage_set',
  redirect: { name: 'single_car' },
  components: {
    manage_set: () => import('./ManagerPage.vue'),
  },
  children: [
    {
      path: 'single_car',
      name: 'single_car',
      components: {
        singleCar: () => import('./ManagetSingleCar.vue'),
      },
    },
  ],
}

import { Draw } from 'ol/interaction'

export const intDraw = {
  init: (layer, type, callback) => {
    let d = new Draw({
      source: layer,
      type: type,
    })
    d.on('drawend', callback)
    return d
  },
}

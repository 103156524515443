// ----------------------------------- Группы -----------------------------------
import { apiCore } from '@/app/deliv'
import { zones } from '@/entities/zones'
import { API_GROUPS, DEBUG } from '@/shared/config'
import store from '@/store'

export const api = {
  // Получение групп
  getGroups: async () => {
    const response = await apiCore.get(API_GROUPS.LIST)
    if (DEBUG) console.log('get groups: ', response)
    if (response.status === 200) {
      // TODO: В группах могут быть ИД объектов которых у нас нет. После каждого фетча и апдейта объектов надо грузить группы и чистить от лишнего
      // let cars = store.getters["cars_mod/ALL_CARS"];
      // response.data.forEach((g) => {
      //   if (g.objects) {
      //     let groups = [];
      //     cars.forEach((c) => {
      //       if (g.objects.includes(c.id)) groups.push(c.id);
      //     });
      //     g.objects = JSON.parse(JSON.stringify(groups));
      //   }
      // });
      await store.dispatch('g_mod/SET_GROUPS', response.data)
    }
  },

  // Получение автоматизаций
  getAutomatics: async () => {
    const response = await apiCore.get(API_GROUPS.AUTOMATIONS_LIST)
    if (DEBUG) console.log('get automatics: ', response)
    if (response.status === 200) {
      // TODO: В действиях автоматизациий могут быть ИД зон которых у нас нет. После каждого фетча и апдейта зон надо грузить автоматизации и чистить от лишнего
      // let zones = store.getters['zones_mod/ZONES']
      response.data.forEach((a) => {
        if (a.action) {
          if (a.action.spsExclude && a.action.spsInclude) {
            let gzEx = []
            let gzIn = []
            Object.values(zones.storage).forEach((z) => {
              if (a.action.spsExclude.includes(z.id)) gzEx.push(z.id)
              if (a.action.spsInclude.includes(z.id)) gzIn.push(z.id)
            })
            a.action.spsExclude = JSON.parse(JSON.stringify(gzEx))
            a.action.spsInclude = JSON.parse(JSON.stringify(gzIn))
          }
        }
      })
      await store.dispatch('g_mod/SET_AUTOMATICS', response.data)
    }
  },

  cudAutomatics: async (automatics) => {
    if (DEBUG) console.log('CrUD automatics: ', automatics)
    const response = await apiCore.post(API_GROUPS.AUTOMATIONS_EDIT, automatics)
    if (DEBUG) console.log('CrUD automatics: ', response)
  },

  // Создание, редактирование группы
  createGroup: async (group) => {
    const response = await apiCore.post(API_GROUPS.EDIT, group)
    if (DEBUG) console.log('CrUd group: ', response)
  },

  // Добавление объектов в группу
  addObject2Group: async (group) => {
    const response = await apiCore.post(API_GROUPS.ADD_OBJ, group)
    if (DEBUG) console.log('add object to group: ', response)
    return response.status
  },

  // Удаление объектов из группы
  deleteObjectInGroup: async (group) => {
    const response = await apiCore.post(API_GROUPS.REM_OBJ, group)
    if (DEBUG) console.log('delete object in group: ', response)
    return response.status
  },
}

export function useMenu() {
  return [
    {
      id: 1,
      name: "monitoring",
      title: "Мониторинг",
      icon: "monitoring",
      show: true,
      sub_menu: [
        {
          id: 1,
          name: "all_cars",
          title: "Все объекты",
        },
        {
          id: 2,
          name: "work_list",
          title: "Рабочая группа",
        },
        {
          id: 3,
          name: "groups_list",
          title: "Мониторинг по группам",
        },
      ],
    },
    {
      id: 2,
      name: "dashboard",
      title: "Dashboard",
      icon: "dashboard",
      show: true,
      sub_menu: [
        {
          id: 1,
          name: "all_dashboard",
          title: "Dashboard",
        },
      ],
    },
    {
      id: 3,
      name: "geozones",
      title: "Геозоны",
      icon: "geozones",
      show: true,
      sub_menu: [
        {
          id: 1,
          name: "all_geozones",
          title: "Все геозоны",
        },
        // {
        //   id: 2,
        //   name: "groups_geozones",
        //   title: "Группы геозон",
        // },
      ],
    },
    {
      id: 4,
      name: "reports",
      title: "Отчеты",
      icon: "reports",
      show: true,
      sub_menu: [
        {
          id: 1,
          name: "all_report",
          title: "Все отчеты",
        },
        {
          id: 2,
          name: "report_builder",
          title: "Конструктор отчетов",
        },
      ],
    },
    {
      id: 5,
      name: "notifications",
      title: "Уведомления",
      icon: "notifications",
      show: true,
      sub_menu: [{ id: 1, name: "all_notifications", title: "Уведомления" }],
    },
    {
      id: 6,
      name: "alarms",
      title: "Тревоги",
      icon: "alarms",
      show: true,
      sub_menu: [{ id: 1, name: "all_alarms", title: "Тревоги" }],
    },
    {
      id: 7,
      name: "users",
      title: "Пользователи",
      icon: "users",
      show: true,
      sub_menu: [{ id: 1, name: "all_users", title: "Пользователи" }],
    },
    {
      id: 8,
      name: "tasks",
      title: "Задания",
      icon: "tasks",
      show: true,
      sub_menu: [{ id: 1, name: "all_tasks", title: "Задания" }],
    },
    {
      id: 9,
      name: "settings",
      title: "Настройки",
      icon: "settings",
      show: true,
      sub_menu: [
        { id: 1, name: "main_settings", title: "Основные" },
        { id: 2, name: "cf_settings", title: "Поля Пользователя" },
      ],
    },
    {
      id: 10,
      name: "manage_set",
      title: "Предустановка",
      icon: "monitoring",
      show: true,
      sub_menu: [{ id: 1, name: "single_car", title: "Найти авто" }],
    },
    {
      id: 100,
      name: "car",
      title: "Объект",
      show: false,
      sub_menu: [
        {
          id: 1,
          name: "car_property",
          title: "Свойства",
        },
        {
          id: 2,
          name: "car_history",
          title: "История",
        },
        {
          id: 3,
          name: "car_setting",
          title: "Настройка",
        },
      ],
    },
  ];
}

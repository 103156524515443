// import carsGroup from "@/models/carsGroup";

export const carsGroupsModule = {
  state: () => ({
    groups: [],
  }),
  getters: {
    GET_GROUP(state, id) {
      return state.groups.filter((i) => i.id === id);
    },
    GET_ALL_GROUPS(state) {
      return state.groups;
    },
  },
  mutations: {
    SET_GROUPS(state, groups) {
      state.groups = groups;
    },
  },
  actions: {
    FETCH_GROUPS: ({ commit }) => {
      let groups = [];
      if (localStorage.groups) {
        groups = JSON.parse(localStorage.getItem("groups"));
      }
      if (!groups.find((i) => i.id === 1))
        groups.push({ id: 1, name: "Рабочая группа", carsId: [] });
      commit("SET_GROUPS", groups);
    },
    CLEAR: ({ commit }) => {
      commit("SET_GROUPS", []);
    },
    ADD_GROUP: ({ state, commit }, group) => {
      if (group.id !== 1) {
        group.id = Date.now();
      }
      let groups = JSON.parse(JSON.stringify(state.groups));
      if (groups.find((i) => i.id === group.id)) {
        groups.find((i) => i.id === group.id).name = group.name;
        groups.find((i) => i.id === group.id).carsId = group.carsId;
      } else {
        groups.push(group);
      }
      // console.log("gr", groups);
      commit("SET_GROUPS", JSON.parse(JSON.stringify(groups)));
      // window.localStorage.setItem("groups", JSON.stringify(groups));
    },
    DEL_GROUP: ({ state, commit }, id) => {
      const groups = [...state.groups].filter((i) => i.id !== id);
      commit("SET_GROUPS", groups);
      // window.localStorage.setItem("groups", JSON.stringify(groups));
    },
    DEL_ALL_GROUPS: ({ commit }) => {
      commit("SET_GROUPS", []);
    },
    EDIT_GROUP: ({ state, commit }, group) => {
      group = JSON.parse(JSON.stringify(group));
      let newGroup = JSON.parse(JSON.stringify(state.groups));
      // let newGroup = [...state.groups];
      newGroup.map((i) => {
        if (i.id === group.id) {
          i.carsId = group.carsId;
          i.name = group.name;
        }
      });
      commit("SET_GROUPS", newGroup);
    },
    SHOW_GROUP: ({ dispatch, rootGetters }, group) => {
      const cars = rootGetters[("cars_mod/GET_CARS", group.cars)];
      const allCars = cars.length;
      const isMapCars = cars.filter((i) => i.ismap).length;
      let ismap = true;
      if (allCars === isMapCars) {
        ismap = false;
      }
      dispatch(
        "cars_mod/SHOW_CAR",
        { id: group.cars, ismap: ismap },
        { root: true }
      );
    },
  },
  namespaced: true,
};

// import { FLEET_DEBUG } from "@/config/config";

export const groupControlModule = {
  state: () => ({
    // Что показываем (группы показываем всегда)
    isShowObj: true,
    isShowAutomatics: true,
    isShowActions: true,

    // В какую кнопку ткнули
    isAddGroup: false,
    isAddAutomatic: false,
    isAddAction: false,
    isEdit: false,

    // Что выбрали
    select: {
      id: null,
      parent: null,
      type: null, // group, automatic, objectFolder, object, telegram, include, exclude, include_obj, exclude_obj
    },

    // Высота обертки плавного появления
    wrappHeight: 0,
  }),
  getters: {
    GET_IS_SHOW_OBJ(state) {
      return state.isShowObj;
    },
    GET_IS_SHOW_AUTOMATICS(state) {
      return state.isShowAutomatics;
    },
    GET_IS_SHOW_ACTIONS(state) {
      return state.isShowActions;
    },

    GET_IS_ADD_GROUP(state) {
      return state.isAddGroup;
    },
    GET_IS_ADD_AUTOMATIC(state) {
      return state.isAddAutomatic;
    },
    GET_IS_ADD_ACTION(state) {
      return state.isAddAction;
    },
    GET_IS_EDIT(state) {
      return state.isEdit;
    },

    GET_SELECT(state) {
      return state.select;
    },
    GET_SELECT_ID(state) {
      return state.select.id;
    },
    GET_SELECT_PARENT(state) {
      return state.select.parent;
    },
    GET_SELECT_TYPE(state) {
      return state.select.type;
    },

    GET_WRAPP_HEIHGT(state) {
      return state.wrappHeight;
    },
  },
  mutations: {
    SET_IS_SHOW_OBJ(state, isShowObj) {
      state.isShowObj = isShowObj;
    },
    SET_IS_SHOW_AUTOMATICS(state, isShowAutomatics) {
      state.isShowAutomatics = isShowAutomatics;
    },
    SET_IS_SHOW_ACTIONS(state, isShowActions) {
      state.isShowActions = isShowActions;
    },

    SET_IS_ADD_GROUP(state, isAddGroup) {
      state.isAddGroup = isAddGroup;
    },
    SET_IS_ADD_AUTOMATIC(state, isAddAutomatic) {
      state.isAddAutomatic = isAddAutomatic;
    },
    SET_IS_ADD_ACTION(state, isAddAction) {
      state.isAddAction = isAddAction;
    },
    SET_IS_EDIT(state, isEdit) {
      state.isEdit = isEdit;
    },

    SET_SELECT(state, select) {
      state.select = select;
    },
    SET_SELECT_ID(state, id) {
      state.select.id = id;
    },
    SET_SELECT_PARENT(state, parent) {
      state.select.parent = parent;
    },
    SET_SELECT_TYPE(state, type) {
      state.select.type = type;
    },

    SET_WRAPP_HEIHGT(state, height) {
      state.wrappHeight = height;
    },
  },
  actions: {
    SET_IS_SHOW_OBJ: ({ commit }, isShowObj) => {
      commit("SET_IS_SHOW_OBJ", isShowObj);
    },
    SET_IS_SHOW_AUTOMATICS: ({ commit }, isShowAutomatics) => {
      commit("SET_IS_SHOW_AUTOMATICS", isShowAutomatics);
    },
    SET_IS_SHOW_ACTIONS: ({ commit }, isShowActions) => {
      commit("SET_IS_SHOW_ACTIONS", isShowActions);
    },

    SET_IS_ADD_GROUP: ({ commit }, isAddGroup) => {
      commit("SET_WRAPP_HEIHGT", 0);
      commit("SET_IS_ADD_AUTOMATIC", false);
      commit("SET_IS_ADD_ACTION", false);
      commit("SET_IS_EDIT", false);
      commit("SET_IS_ADD_GROUP", isAddGroup);
    },
    SET_IS_ADD_AUTOMATIC: ({ commit }, isAddAutomatic) => {
      commit("SET_WRAPP_HEIHGT", 0);
      commit("SET_IS_ADD_GROUP", false);
      commit("SET_IS_ADD_ACTION", false);
      commit("SET_IS_EDIT", false);
      commit("SET_IS_ADD_AUTOMATIC", isAddAutomatic);
    },
    SET_IS_ADD_ACTION: ({ commit }, isAddAction) => {
      commit("SET_WRAPP_HEIHGT", 0);
      commit("SET_IS_ADD_GROUP", false);
      commit("SET_IS_ADD_AUTOMATIC", false);
      commit("SET_IS_EDIT", false);
      commit("SET_IS_ADD_ACTION", isAddAction);
    },
    SET_IS_EDIT: ({ commit }, isEdit) => {
      commit("SET_WRAPP_HEIHGT", 0);
      commit("SET_IS_ADD_GROUP", false);
      commit("SET_IS_ADD_AUTOMATIC", false);
      commit("SET_IS_ADD_ACTION", false);
      commit("SET_IS_EDIT", isEdit);
    },

    SET_SELECT: ({ commit }, select) => {
      commit("SET_WRAPP_HEIHGT", 0);
      commit("SET_SELECT", JSON.parse(JSON.stringify(select)));
    },
    SET_SELECT_ID: ({ commit }, id) => {
      commit("SET_SELECT_ID", id);
    },
    SET_SELECT_PARENT: ({ commit }, parent) => {
      commit("SET_SELECT_PARENT", parent);
    },
    SET_SELECT_TYPE: ({ commit }, type) => {
      commit("SET_SELECT_TYPE", type);
    },

    SET_WRAPP_HEIHGT: ({ commit }, height) => {
      commit("SET_WRAPP_HEIHGT", height);
    },
  },
  namespaced: true,
};

import { FLEET_DEBUG } from '@/shared/config'

export const customFieldsModule = {
  state: () => ({
    customFields: [],
  }),
  getters: {
    ALL_CUSTOM_FIELDS(state) {
      return state.customFields
    },
  },
  mutations: {
    SET_CUSTOM_FIELDS(state, customFields) {
      state.customFields = customFields
    },
  },
  actions: {
    CLEAR: ({ commit }) => {
      if (FLEET_DEBUG) console.log('CLEAR_CUSTOM_FIELDS')
      commit('SET_CUSTOM_FIELDS', [])
    },
    SET_CUSTOM_FIELDS: ({ commit }, payload) => {
      if (FLEET_DEBUG) console.log('SET_CUSTOM_FIELDS', payload)
      commit('SET_CUSTOM_FIELDS', payload)
    },
    UPDATE_CUSTOM_FIELDS: ({ state, commit }, payload) => {
      if (FLEET_DEBUG) console.log('UPDATE_CUSTOM_FIELDS', payload)
      let customFields = JSON.parse(JSON.stringify(state.customFields))
      payload.forEach((custom) => {
        let isContains = false
        customFields.forEach((i) => {
          if (i.UID === custom.UID) {
            if ('isGlobal' in i) delete i.isGlobal
            Object.assign(i, custom)
            isContains = true
          }
        })
        if (!isContains) customFields.push(custom)
        if (custom.isDeleted || custom.action === 0)
          customFields = customFields.filter((i) => i.UID !== custom.UID)
      })
      commit('SET_CUSTOM_FIELDS', customFields)
    },
  },
  namespaced: true,
}

<template>
  <div class="save_btn">
    <img :src="require(`@/assets/icons/UI/${pic}.png`)" alt="pencil" />
  </div>
</template>

<script>
export default {
  props: { pic: String },
  name: "btnBlue",
};
</script>

<style scoped>
.save_btn {
  width: 26px;
  height: 18px;
  background: #1e81ce;
  border-radius: 2px;
  border: 1px solid #1e81ce;
  display: flex;
  align-items: center;
  justify-content: center;
}
.save_btn:hover {
  border: 1px solid #0b5e9f;
}
.save_btn:active {
  background: #0b5e9f;
  border: 1px solid #0b5e9f;
}
</style>

<script setup>
import { upTime as getUpTime } from '@/shared/lib/date'
import { onUnmounted, ref, watchEffect } from 'vue'

const props = defineProps({ unixGmt: Number })

const upTime = ref(null)
const setUpTime = () => (upTime.value = getUpTime(props.unixGmt))

watchEffect(setUpTime)
const timerId = setInterval(setUpTime, 60000)

onUnmounted(() => clearInterval(timerId))
</script>

<template>
  <div class="uptime">
    <div>
      <img
        class="calendar"
        :src="require('@/assets/images/icons/carcard/calendar.png')"
      />
    </div>
    <div class="uptime_text">
      {{ upTime }}
    </div>
  </div>
</template>

<style scoped>
.uptime {
  width: 100px;
  flex: 0 0 auto;
  display: flex;
  height: 24px;
  background: #f6fff1;
  border: 1px solid #09941f;
  border-radius: 4px;
  color: #1b7519;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  padding-right: 5px;
}
.uptime_text {
  padding-left: 8px;
  padding-top: 4px;
}
</style>

export const reportTemplateModule = {
  state: () => ({
    reportTemplates: [],
  }),
  getters: {
    GET_REPORT_TEMPLATE(state, id) {
      return state.reportTemplates.filter((i) => i.id === id);
    },
    GET_ALL_REPORT_TEMPLATES(state) {
      return state.reportTemplates;
    },
  },
  mutations: {
    SET_REPORT_TEMPLATES(state, reportTemplates) {
      state.reportTemplates = reportTemplates;
    },
  },
  actions: {
    FETCH_REPORT_TEMPLATES: ({ commit }) => {
      let reportTemplates = [];
      if (localStorage.reportTemplates) {
        reportTemplates = JSON.parse(localStorage.getItem("reportTemplates"));
      }
      commit("SET_REPORT_TEMPLATES", reportTemplates);
    },
    CLEAR: ({ commit }) => {
      commit("SET_REPORT_TEMPLATES", []);
    },
    ADD_REPORT_TEMPLATE: ({ state, commit }, reportTemplate) => {
      reportTemplate.id = Date.now();
      let reportTemplates = JSON.parse(JSON.stringify(state.reportTemplates));
      reportTemplates.push(reportTemplate);
      commit(
        "SET_REPORT_TEMPLATES",
        JSON.parse(JSON.stringify(reportTemplates))
      );
      window.localStorage.setItem(
        "reportTemplates",
        JSON.stringify(reportTemplates)
      );
    },
    DEL_REPORT_TEMPLATE: ({ state, commit }, id) => {
      const reportTemplates = [...state.reportTemplates].filter(
        (i) => i.id !== id
      );
      commit("SET_REPORT_TEMPLATES", reportTemplates);
      window.localStorage.setItem(
        "reportTemplates",
        JSON.stringify(reportTemplates)
      );
    },
  },
  namespaced: true,
};

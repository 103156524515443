import Overlay from 'ol/Overlay'

export const pupupOverlays = {
  //  Инит
  init: (pupUp) => {
    return {
      inLayer: '',
      inCluster: false,
      select: null,
      popup: new Overlay({
        element: pupUp, // тег всплывающего окна в html
        autoPan: true, // Если всплывающее окно находится на краю базового изображения, базовое изображение переместится
        autoPanAnimation: {
          // Анимация движения базовой карты
          duration: 500,
        },
        autoPanMargin: 20,
        positioning: 'bottom-left',
      }),
    }
  },
}

<template>
  <div @click.stop="fit()" class="card">
    <div class="top">
      <div class="type">
        <div
          class="zone_circle"
          v-if="zone.type === 'Circle'"
          :style="{ background: zone.fill_color }"
        ></div>
        <div
          class="zone_polygone"
          v-if="zone.type === 'Polygon'"
          :style="{ background: zone.fill_color }"
        ></div>
      </div>
      <div class="show">
        <div class="check">
          <CheckBox
            :mode="zone.ismap ? 'on' : 'off'"
            @change="
              showZones({
                id: zone.id,
                ismap: !zone.ismap,
              })
            "
          />
        </div>
        <div>{{ zone.name }}</div>
      </div>
      <div class="edit" @click="editGeoZone()">
        <img :src="require(`@/assets/icons/UI/pencil.png`)" alt="pencil" />
      </div>
      <div class="delete" ref="del" @click.stop="deleteZone">
        <img :src="require(`@/assets/icons/UI/garbage.png`)" alt="garbage" />
      </div>
    </div>
    <div class="wrap_scrolling_block" ref="collapse" :style="collapse">
      <edit-geo-zones @cancel="cancelGeoZone" @save="saveGeoZone" />
    </div>
  </div>
</template>

<script>
import EditGeoZones from './EditGeoZones.vue'
import { api, showZones } from '@/entities/zones'
import { CheckBox } from '@/shared/ui'
import { mapActions } from 'vuex'

export default {
  name: 'ZoneCard',
  components: { CheckBox, EditGeoZones },
  props: {
    zone: Object,
  },
  emits: ['confirm'],
  data() {
    return { showZones }
  },
  computed: {
    zoneState() {
      return this.$store.getters.ZONE_STATUS
    },
    zoneId() {
      return this.$store.getters.ZONE.id
    },
    collapse() {
      if (this.zoneState === 'EDIT' && this.zoneId === this.zone.id) {
        let ht = 0
        if (this.$refs.collapse) {
          ht = this.$refs.collapse.getBoundingClientRect().top + 30
        }
        return { 'max-height': 'calc(100vh - ' + ht.toString() + 'px)' }
      }
      return { 'max-height': '0px' }
    },
  },
  methods: {
    ...mapActions({
      fitness: 'OPN_mod/SET_FIT_LAYER',
    }),
    change(id) {
      // this.zone.ismap = !this.zone.ismap
    },
    fit() {
      // TODO: !!!
      this.fitness('zones-' + this.zone.id)
    },
    deleteZone() {
      if (this.zoneState !== 'EDIT') {
        this.$emit(
          'confirm',
          this.$refs.del.getBoundingClientRect().top,
          this.$refs.del.getBoundingClientRect().left,
          this.zone.id
        )
      }
    },
    cancelGeoZone() {
      this.$store.dispatch('INIT_ZONE')
    },
    saveGeoZone() {
      const zone = this.$store.getters.ZONE
      if (zone.name !== '') {
        api.update(zone)
        this.$store.dispatch('INIT_ZONE')
      }
    },
    editGeoZone() {
      if (this.zoneState !== 'EDIT') {
        this.$store.dispatch('SET_ZONE_STATUS', 'EDIT')
        this.$store.dispatch('SET_ZONE', JSON.parse(JSON.stringify(this.zone)))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.top {
  //width: 340px;
  height: 30px;
  //margin: 10px 5px 0 5px;
  //margin-top: 10px;
  padding: 0 5px 0 5px;
  background: #fdfdfd;
  //border: 1px solid red;
  box-sizing: border-box;
  //border-radius: 6px;
  display: flex;
  //flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.card {
  width: 360px;
  border: 1px solid #b1b1b1;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrap_scrolling_block {
  //background: #000;
  flex: 1 1 auto;
  //width: 375px;
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  //justify-content: center;
  -webkit-transition: max-height 0.3s;
  transition: max-height 0.3s;
  border-radius: 6px;
  overflow: hidden;
}
.type {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background: #f2f2f2;
  display: flex;
  justify-content: center; /* Centering y-axis */
  align-items: center; /* Centering x-axis */
}
.zone_circle {
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
  background: #7fee1d;
  border-radius: 50%;
  border: 1px solid #444444;
}
.zone_polygone {
  flex: 0 0 auto;
  width: 14px;
  height: 14px;
  background: #7fee1d;
  border: 1px solid #444444;
}
.show {
  //border: 1px solid red;
  //flex: 1 1 auto;
  //height: 50%;
  padding-left: 5px;
  width: 255px;
  overflow: hidden;
  margin-right: 5px;
  //display: flex;
  //justify-content: center; /* Centering y-axis */
  //align-items: center; /* Centering x-axis */
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: flex-end;
}
.check {
  margin-bottom: 14px;
}
.edit {
  height: 50%;
  margin-right: 10px;
  cursor: pointer;
  width: 34px;
  //height: 14px;
  text-align: center;
  padding-top: 1px;
  background: #d1ebc5;
  border: 1px solid #d1ebc5;
  border-radius: 2px;
  box-sizing: border-box;

  //border: 1px solid red;
}
.delete {
  height: 50%;
  margin-top: 3px;
  //border: 1px solid red;
}
</style>

// ----------------------------------- Кастом поля -----------------------------------
import { apiCore } from '@/app/deliv'
import { API_CUSTOM_FIELDS, DEBUG } from '@/shared/config'
import store from '@/store'

export const api = {
  // Получение кастом полей
  get: async () => {
    const response = await apiCore.get(API_CUSTOM_FIELDS.LIST)
    if (DEBUG) console.log('get custom: ', response)
    if (response.status === 200) {
      await store.dispatch('cf_mod/SET_CUSTOM_FIELDS', response.data)
    }
  },

  // Установка объекту значения кастомного поля
  setCustom: async (objId, fieldUID, value) => {
    const url = API_CUSTOM_FIELDS.SET.replace(':objId', objId).replace(
      ':fieldUID',
      fieldUID
    )
    const response = await apiCore.post(url, { value: value })
    if (DEBUG) console.log('set custom: ', response)
  },

  // Создание, модификация, удаление кастомного поля
  cudCustom: async (custom) => {
    const response = await apiCore.post(API_CUSTOM_FIELDS.CREATE, custom)
    if (DEBUG) console.log('CrUD custom: ', response)
  },
}

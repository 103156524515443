import { DEBUG } from '@/shared/config'
import jwt_decode from 'jwt-decode'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useRulesStore = defineStore('rulesStore', () => {
  const bitMap = ref('')

  const setBitMap = (detailsConn) => {
    const token = detailsConn['token']
    const accessRights = jwt_decode(token).accessRights

    bitMap.value = (accessRights >>> 0).toString(2)
    if (DEBUG)
      console.log(
        'setBitMap',
        bitMap.value,
        bitMap.value.at(-22),
        bitMap.value.at(-23),
        bitMap.value.at(-24)
      )
  }
  const FLEET_ADMIN = computed(() => {
    return bitMap.value.at(-22) === '1'
  }) // 22 - Fleet администрирование пользователей (перезаписывается из sp)
  const FLEET_GOS_EDIT = computed(() => {
    return bitMap.value.at(-23) === '1'
  }) // 23 - Fleet редактирование гос номера, марки-модели (перезаписывается из sp)
  const FLEET_SINGLE_OBJECT = computed(() => {
    return bitMap.value.at(-24) === '1'
  }) // 24 - Fleet не загружать список объектов

  const reset = () => {
    bitMap.value = ''
  }

  return { setBitMap, FLEET_ADMIN, FLEET_GOS_EDIT, FLEET_SINGLE_OBJECT, reset }
})

<template>
  <div class="main_btn">
    <div
      :class="[
        'box',
        { on: mode === 'on' },
        { off: mode === 'off' },
        { disable: mode === 'disable' },
      ]"
      @click="mode !== 'disable' ? $emit('change') : ''"
    >
      <div v-if="mode === 'on'" class="selected"></div>
    </div>
    <div
      class="label"
      :style="{
        fontSize: fontSize + 'px',
        fontWeight: fontWeight,
        color: fontColor,
      }"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RadioBtn",
  props: {
    mode: String,
    label: String,
    fontSize: {
      type: Number,
      default: 14,
    },
    fontWeight: {
      type: Number,
      default: 400,
    },
    fontColor: {
      type: String,
      default: "#374C70",
    },
  },
  emits: ["change"],
};
</script>

<style scoped>
.main_btn {
  position: relative;
}
.box {
  position: absolute;
  width: 14px;
  height: 14px;
  border: 1px solid #093863;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.on {
  /*background: #1E5486;*/
}
.off {
}
.disable {
  background: #c4c4c4;
}
.label {
  padding-left: 20px;
  line-height: 15px;
}
.selected {
  width: 8px;
  height: 8px;
  background: #1e81ce;
  border-radius: 50%;
}
.box:hover {
  border: 1px solid #1d1d1d;
}
</style>

<template>
  <div
    class="fly_wrapper"
    :style="{
      top: s(y),
      left: s(marginLeft),
      height: s(height + isHeadRowHeight),
      transition: transition,
    }"
  >
    <slot name="head"></slot>
    <div class="basic_scroll" :style="{ height: s(height) }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// У летающего окна в зависимости от событий вычисляются 2 состояния - высота и позиция первой строки.
// Изменения состояния происходят при появлении, исчезновении, изменении контента и при скроллинге.
// Изменение высоты делаем с анимацией для появления/исчезновения изменения контента, без анимации для скроллинга
// Изменение позиции делаем без анимации для скроллинга, а для появления/исчезновения изменения контента - зависит от условий
//    а) без анимации, если не надо поднимать окно из за слишком большой высоты.
//    б) без анимации показать строку, увеличить окно до нижней границы, анимировать подъем позиции и изменение высоты окна до заданного размера
//
// 1) позиция первой строки
//    'y' - равна позиции элемента с переданым ИД.
//                  Если 'y' слишком высоко ('y' меньше марджинТОП (yMin)), то y = yMin.
//                  Если 'y' слишком низко ('y' больше чем высота экрана - марджинБАТТОМ - hMin (yMax)):
//                    а) ситуация возникла при скроллинге, то просто залипаем снизу - 'y' = yMax
//                    б) ситуация возникла при инициализации или при изменении высоты контента.
//                      Проверить надо ли поднимть первую строку - позиция с высотой окна ('y' + height) > yMax.
//                      Если надо, то ПОСЛЕ ИЗМЕНЕНИЯ ВЫСОТЫ ДО НИЗА ЭКРАНА!! поднять на 'y' + height - yMax
//
//
// 2) высота
//    height  - высота окна
//    h       - высота контента
//    hMax    - максимальная высота. Либо принимается либо (высота экрана - марджины) * 80%.
//                Необходима чтоб видели скроллинг и залипание окна сверху
//    hMin    - минимальная высота. Либо принимается, либо дефолт значение 200, либо = высоте контента h.
//                Нужна, тк неудобно скроллить длинный список, когда видишь всего пару строк.
//                Если контент маленький и не имеет скролла (пара строк), то hMin = высоте контента h

export default {
  name: "flyWrapper",
  props: {
    id: Number,

    w: Number,
    h: Number,

    heightMin: Number,
    heightMax: Number,

    isScroll: Number,

    marginBottom: {
      type: Number,
      default: 0,
    },
    marginTop: {
      type: Number,
      default: 0,
    },
    marginLeft: {
      type: Number,
      default: 0,
    },
    marginRight: {
      type: Number,
      default: 0,
    },

    isHeadRowHeight: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      position: null,
      height: 0,
      x: Number,
      y: Number,
      animeH: false,
      animeY: false,
      hMinDefault: 200,
    };
  },
  // watch: {
  //   h: {
  //     handler() {
  //       this.animeTop = true;
  //       this.animeH = true;
  //       setTimeout(() => {
  //         this.animeTop = false;
  //       }, 300);
  //       this.height = this.h;
  //       this.positionUpdate();
  //     },
  //   },
  //   height: {
  //     handler() {},
  //   },
  //   // id: {
  //   //   handler() {
  //   //     this.animeTop = true;
  //   //     this.height = 0;
  //   //     setTimeout(() => {
  //   //       this.positionUpdate();
  //   //       // this.animeTop = true;
  //   //       setTimeout(() => {
  //   //         this.height = this.h;
  //   //         this.positionUpdate();
  //   //         // this.animeTop = false;
  //   //       }, 300);
  //   //     }, 300);
  //   //   },
  //   // },
  //   isScroll: {
  //     handler() {
  //       let hMin = 200;
  //       if (this.h < hMin) hMin = this.h;
  //       const hMax = 500;
  //       const position = document
  //         .getElementById(this.id)
  //         .getBoundingClientRect();
  //       const hCourent = this.windowHeight - position.y - this.marginBottom;
  //       console.log("hCourent", hCourent, position.y, this.position.y);
  //       if (hCourent > hMax) {
  //         //  Если расстояние до низа экрана больше максимальной высоты окна - просто двигать окно
  //
  //         if (position.y > this.marginTop) this.position.y = position.y;
  //       } else if (hCourent < hMin) {
  //         //  Если расстояние до низа экрана меньше и равно минимальной высоте окна то ничего не делаем
  //       } else {
  //         this.animeH = false;
  //         this.position.y = position.y;
  //         //  Изменяем размер окна до низа экрана или до минимума
  //         if (hCourent - this.marginBottom < this.h) {
  //           this.height = hCourent - this.marginBottom;
  //         } else {
  //           this.height = this.h;
  //         }
  //       }
  //
  //       // this.positionUpdate();
  //     },
  //   },
  // },
  // mounted() {
  //   // this.maxWidth = this.width;
  //
  //   this.windowHeight = window.innerHeight;
  //   this.windowWidth = window.innerWidth;
  //
  //   //  Ставим окно напротив строчки
  //   const position = document.getElementById(this.id).getBoundingClientRect();
  //   this.animeTop = false;
  //   this.position.y = position.y;
  //   this.height = 0;
  //
  //   const hCourent = this.windowHeight - position.y - this.marginBottom;
  //   let hMin = 200;
  //   if (this.h < hMin) hMin = this.h;
  //   const hMax = 500;
  //   if (hCourent < hMin) {
  //     //  Если расстояние до низа экрана меньше минимальной высоты, включаем анимацию,
  //     //  1) изменяем размер окна до нижней границы
  //     //  2) Изменям размер до минимального, одновременно сдвигая окно вверх
  //     setTimeout(() => {
  //       this.animeTop = false;
  //     }, 600);
  //     setTimeout(() => {
  //       this.animeTop = true;
  //       this.height = hCourent - 30;
  //     }, 0);
  //     setTimeout(() => {
  //       this.height = hMin;
  //       this.position.y = this.windowHeight - this.marginBottom - hMin - 30;
  //     }, 300); // (300 * hCourent) / hMin);
  //   } else if (hCourent > hMax) {
  //     //  Если расстояние до низа экрана больше максимальной высоты, изменяем размер окна до максимального или переданного, если оно меньше
  //     this.h < hMax ? (this.height = this.h) : (this.height = hMax);
  //   } else {
  //     //  Изменяем размер окна до низа экрана или до минимума
  //     if (hCourent - this.marginBottom < this.h) {
  //       this.height = hCourent - this.marginBottom;
  //     } else {
  //       this.height = this.h;
  //     }
  //   }
  //
  //   // this.positionUpdate();
  //   // addEventListener("resize", () => {
  //   //   this.resize();
  //   // });
  //   // this.animeTop = true;
  //   //
  //   // setTimeout(() => {
  //   //   this.animeTop = false;
  //   // }, 300);
  //   // this.height = this.h;
  //   // this.positionUpdate();
  //   //
  //   // setTimeout(() => {
  //   //   this.height = this.h;
  //   //   this.positionUpdate();
  //   // }, 300);
  // },
  // beforeUnmount() {
  //   removeEventListener("resize", () => {
  //     this.resize();
  //   });
  // },
  watch: {
    isScroll: {
      handler() {
        this.scrollOn();
      },
    },
    h: {
      handler() {
        this.changeContent();
      },
    },
  },
  mounted() {
    this.position = document.getElementById(this.id).getBoundingClientRect();
    this.initOn();
  },
  computed: {
    yMin() {
      return this.marginTop;
    }, // Постоянное значение
    yMax() {
      return (
        window.innerHeight -
        this.marginBottom -
        this.hMin -
        this.isHeadRowHeight
      );
    }, // Зависит от высоты контента
    hMin() {
      let hMin = this.hMinDefault + this.isHeadRowHeight;
      if (this.heightMin) hMin = this.heightMin;
      if (this.h < hMin) return this.h;
      return hMin;
    }, // Зависит от высоты контента
    hMax() {
      let hMax =
        (window.innerHeight - this.marginBottom - this.marginTop) / 1.2 +
        this.isHeadRowHeight;
      if (this.heightMax) hMax = this.heightMax;
      if (this.h < hMax) return this.h;
      return hMax;
    }, // Зависит от высоты контента

    transition() {
      const top = "top 300ms";
      const height = "height 300ms";
      let anime = "";
      if (this.animeH) anime = height;
      if (this.animeY) {
        anime === "" ? (anime = top) : (anime += ", " + top);
      }
      return anime;
    },
  },
  methods: {
    initOn() {
      let startPosition = this.setY("startPosition");
      if (startPosition === "onBorder") {
        this.stepAnimeH();
        this.height = this.yMax + this.hMin - this.position.y;
        setTimeout(() => {
          this.stepAnimeH();
          this.stepAnimeY();
          this.setY();
          this.height = this.hMin;
        }, 300);
      } else if (startPosition === "overBorder") {
        setTimeout(() => {
          this.stepAnimeH();
          this.stepAnimeY();
          this.setY();
          this.height = this.hMin;
        }, 0);
      } else {
        this.stepAnimeH();
        this.setH();
      }
      // this.setY();
      // if (this.setY("startPosition")) this.setH("startPosition");
      // this.setY("initOnMove");
      // this.setH();
    }, //  Появление компонента
    initOff() {
      //  Исчезновение компонента
    }, //  Исчезновение компонента
    scrollOn() {
      this.position = document.getElementById(this.id).getBoundingClientRect();
      this.animeH = false;
      this.animeY = false;
      this.setH("scrollOn");
      this.setY("");
    }, //  Случился скроллинг
    changeContent() {
      this.animeH = true;
      this.animeY = true;
      this.setH();
      //  Изменилась высота контента
    }, //  Изменилась высота контента

    setY(event) {
      if (this.position.y < this.yMin) {
        this.y = this.yMin;
      } // Позиция выше верхней границы
      else if (this.position.y > this.yMax) {
        if (event === "startPosition") {
          if (this.position.y > this.yMax + this.hMin) {
            this.y = this.yMax + this.hMin;
            return "overBorder";
          } // Позиция за пределами экрана
          else {
            this.y = this.position.y;
            return "onBorder";
          } // Позиция на экране, просто сильно низко
        } else {
          this.y = this.yMax;
        }
      } // Позиция ниже нижней границы, танцуем с бубном
      else {
        this.y = this.position.y;
      } // Позиция хорошая
    },
    setH(event) {
      if (this.position.y < this.yMin) {
        this.height = this.hMax;
      } // Позиция выше верхней границы
      else if (this.position.y > this.yMax) {
        switch (event) {
          case "scrollOn":
            this.height = this.hMin;
            break; // Если скролл - установить минимальную высоту
        }
        if (this.h < this.hMin) {
          this.height = this.h;
        } else {
          this.height = this.hMin;
        }
        this.height = this.hMin;
      } // Позиция ниже нижней границы, танцуем с бубном
      else {
        this.height = this.yMax - this.position.y + this.hMin;
        if (this.h < this.height) this.height = this.h;
      } // Позиция хорошая
    },

    s(x) {
      return x.toString() + "px";
    },
    stepAnimeH() {
      this.animeH = true;
      setTimeout(() => {
        this.animeH = false;
      }, 300);
    },
    stepAnimeY() {
      this.animeY = true;
      setTimeout(() => {
        this.animeY = false;
      }, 300);
    },

    // positionUpdate() {
    //   //  Позиция выбраной строки
    //   const position = document.getElementById(this.id).getBoundingClientRect();
    //
    //   // Индикатор что подошли к нижней границе
    //   const down =
    //     this.windowHeight <
    //     this.height + this.isHeadRowHeight + position.y + this.marginBottom;
    //   if (down) {
    //     this.position.y =
    //       this.windowHeight -
    //       this.height -
    //       this.isHeadRowHeight -
    //       this.marginBottom;
    //   } else {
    //     this.position.y = position.y;
    //   }
    //
    //   // Индикатор что подошли к верхней границе
    //   const up = this.position.y < this.marginTop;
    //   // Если подошли к верхней границе, то не двигаем окно
    //   if (up) this.position.y = this.marginTop;
    // },
    // resize() {
    //   this.positionUpdate();
    // },
  },
};
</script>

<style scoped>
.fly_wrapper {
  z-index: 100;
  background: #fdfdfd;
  box-shadow: 0 4px 6px 2px #dddddd;
  border-radius: 6px;
  position: absolute;
  width: 300px;
  overflow: hidden;
}
</style>

<template>
  <div class="main">
    <div class="label">{{ label }}:</div>
    <div :class="[{ input: true }, { input_error: isError }]">
      <input
        type="text"
        :placeholder="label"
        @change="$emit('change', $event.target.value)"
        :value="name"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputField",
  props: {
    name: {
      type: String,
      default: "",
    },
    label: String,
    isError: Boolean,
  },
  emits: ["change"],
};
</script>

<style scoped>
.main {
  flex: 0 0 auto;
  /*border: 1px solid blue;*/
  width: 100%;
  /*height: 40px;*/
  /*display: flex;*/
}
.label {
  font-size: 8px;
  line-height: 9px;
  color: #a8b2bd;
  padding: 6px 0 0 24px;
}
.input {
  width: 100%;
  height: 22px;
  background: #ffffff;
  border: 1px solid #a8abac;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 2px 0 0 0;
  padding: 2px;
}
.input:focus-within {
  border: 1px solid #1e81ce;
}
.input_error {
  border: 1px solid #fd6262;
}
input {
  padding: 2px 10px 0 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #000000;
  width: 100%;
}
</style>

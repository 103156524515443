// ----------------------------------- Объекты -----------------------------------
import { apiCore } from '@/app/deliv'
import { API_OBJ, DEBUG } from '@/shared/config'
import store from '@/store'
import { useCarsStore } from '@/stores/carsStore'
import { getCarsAddress } from './addressor'
import { importDTO } from './dto/importDTO'

// const getCoordinates = (cars): Record<UniqId, [number, number]> =>
//   cars.reduce((res, current) => {
//     res[current.id] = [
//       current.lastPosition.positionGPS.lat,
//       current.lastPosition.positionGPS.lon,
//     ]
//     return res
//   }, {})

export const api = {
  // Получение объектов
  get: async () => {
    const response = await apiCore.get(API_OBJ.LIST)
    if (DEBUG) console.log('get cars: ', response)
    if (response.status === 200) {
      await store.dispatch('cars_mod/SET_CARS', response.data)
      const cars = response.data.map((car) => importDTO(car))

      const address = await getCarsAddress(cars)

      cars.forEach((i) => (i.lastPos.address = address[i.objID]))

      useCarsStore().set(cars)
    }
  },

  // Получение одного объекта по запросу
  getOne: async (sn) => {
    const response = await apiCore.get(API_OBJ.SINGLE + sn)
    if (DEBUG) console.log('get cars: ', response)
    if (response.status === 200) {
      const cars = [response.data].map((car) => importDTO(car))

      const address = await getCarsAddress(cars)

      cars.forEach((i) => (i.lastPos.address = address[i.objID]))

      useCarsStore().set(cars)
    }
  },

  // Получение истории по объекту
  getHistory: async (objID, fromTill) => {
    const url = API_OBJ.HISTORY.replace(':carID', objID.toString())
      .replace(':from', fromTill[0].toString())
      .replace(':till', fromTill[1].toString())
    const response = await apiCore.get(url)
    if (DEBUG) console.log('get history: ', response)
    if (response.status === 200) return response.data
  },

  // Установка не пустых инфо полей
  // type ReqSetObjectInfo struct {
  //   ObjID     int    json:"objID"
  //   Gos       string json:"gos"
  //   Color     string json:"color"
  //   MarkModel string json:"markModel"
  //   Vin       string json:"vin"
  // }
  setInfo: async (ObjectInfo) => {
    const url = API_OBJ.SET_INFO.replace(':objID', ObjectInfo.objID.toString())
    const response = await apiCore.post(url, ObjectInfo)
    if (DEBUG) console.log('set info: ', response)
    return response.status
  },
}

<template>
  <div style="position: relative">
    <!--    Строка в обычном состоянии и при редактировании-->
    <simple-row
      v-if="filterName"
      @click="!isHidden ? setSelected(item.spID, item.parentSpID, 'group') : ''"
      @dblclick="!isHidden ? (isOpen = !isOpen) : ''"
      @save="saveName($event)"
      @cancel="setIsEdit(false)"
      :selected="!isAddAutomatic && !isAddGroup"
      :isInput="isSelected(item.spID, item.parentSpID, 'group') && isEdit"
      :upper="
        isSelected(item.spID, item.parentSpID, 'group') &&
        (isEdit || isAddAutomatic || isAddGroup)
      "
      :level="level"
      :select="{ id: item.spID, parent: item.parentSpID, type: 'group' }"
      :name="item.name"
      :isOpen="isOpen"
      :folderPic="'groups-folder'"
    />
    <!--    Пустышка для фильтра-->
    <div v-else :style="{ paddingLeft: (level * 10).toString() + 'px' }">
      ...
    </div>
    <!--    Действия пользователя-->
    <!--    Добавление группы-->
    <height-wrapp v-if="showGroupAdder && filterName" :h="wrappHeight + 30">
      <simple-row
        @save="saveGroup($event)"
        @cancel="setIsAddGroup(false)"
        :isInput="isSelected(item.spID, item.parentSpID, 'group') && isAddGroup"
        :upper="true"
        :level="level + 1"
        :select="{ id: item.spID, parent: item.parentSpID, type: 'group' }"
        :name="'Новая группа'"
        :isOpen="false"
        :folderPic="'groups-folder'"
      />
    </height-wrapp>
    <!--    Добавление автоматизации (wrappHeight + 30 - добавка на строку заголовок с кнопками управления) -->
    <height-wrapp v-if="showAutomaticAdder && filterName" :h="wrappHeight + 30">
      <!--      Строка заголовок с кнопками управления-->
      <simple-row
        @save="saveAutomaticAdder = true"
        @cancel="setIsAddAutomatic(false)"
        :save="true"
        :upper="true"
        :level="level + 1"
        :select="{ id: item.spID, parent: item.parentSpID, type: 'group' }"
        :name="'Новая автоматизация'"
        :isOpen="false"
        :folderPic="'auto-folder'"
      />
      <automatic-editor
        :type="'new'"
        :level="level"
        :save="saveAutomaticAdder"
      />
    </height-wrapp>
    <!--    Потомки-->
    <div v-if="isOpen">
      <!--      Папка с объектами группы-->
      <obj-folder
        v-if="isShowObj && filterName"
        :parent="item.spID"
        :level="level + 1"
      />
      <!--      Автоматизации группы-->
      <div v-if="isShowAutomatics && filterName">
        <div v-for="a in automatics(item.spID)" :key="a.autoID">
          <automatics-item :item="a" :level="level + 1" />
        </div>
      </div>
      <!--      Чилдрен отростки-->
      <div v-for="chil in item.children" :key="chil.spID">
        <tree-item :filter="filter" :item="chil" :level="level + 1" />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import mixin from '@/mixins/groups'
import { TYPE_OF_GROUP } from '@/shared/config/config'
import { HeightWrapp } from '@/shared/ui'
import { mapActions, mapGetters } from 'vuex'
import { api } from '../api'
import AutomaticEditor from './AutomaticEditor.vue'
import AutomaticsItem from './AutomaticsItem.vue'
import ObjFolder from './ObjFolder.vue'
import SimpleRow from './SimpleRow.vue'

export default {
  name: 'TreeItem',
  components: {
    ObjFolder,
    AutomaticsItem,
    HeightWrapp,
    AutomaticEditor,
    SimpleRow,
  },
  mixins: [mixin],
  props: { item: Object, level: Number, filter: String },
  data() {
    return {
      showAutomaticAdder: false, // Тромоз (300 мс) выключения компонента добавления автоматизации
      saveAutomaticAdder: false, // Передача в компонент сигнала на сейв (кнопки находятся тут, а сейв происходит в компоненте)
      showGroupAdder: false, // Тромоз (300 мс) выключения добавления новой группы
      isOpen: true,
      TYPE_OF_GROUP: {},
    }
  },
  created() {
    Object.assign(this.TYPE_OF_GROUP, TYPE_OF_GROUP)
  },
  watch: {
    enableAutomaticAdder: {
      handler(v) {
        if (v) {
          this.showAutomaticAdder = v
        } else {
          this.setWrappHeight(-30)
          setTimeout(() => {
            this.showAutomaticAdder = v
          }, 300)
        }
      },
    }, // Включаем тормоз
    saveAutomaticAdder: {
      handler(v) {
        if (v)
          setTimeout(() => {
            this.saveAutomaticAdder = false
          }, 300)
      },
    }, // Переводим индикатор в положение по умолчанию
    enableGroupAdder: {
      handler(v) {
        if (v) {
          this.showGroupAdder = v
        } else {
          this.setWrappHeight(-30)
          setTimeout(() => {
            this.showGroupAdder = v
          }, 300)
        }
      },
    }, // Включаем тормоз
  },
  computed: {
    ...mapGetters({
      automatics: 'g_mod/GET_AUTOMATICS',
    }),
    filterName() {
      return (
        this.item.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      )
    },
    enableAutomaticAdder() {
      return (
        this.isSelected(this.item.spID, this.item.parentSpID, 'group') &&
        this.isAddAutomatic
      )
    },
    enableGroupAdder() {
      return (
        this.isSelected(this.item.spID, this.item.parentSpID, 'group') &&
        this.isAddGroup
      )
    },
  },
  methods: {
    ...mapActions({
      saveNameG: 'g_mod/SAVE_GROUP_NAME',
    }),
    // Сохраняю группу после ЕдитНейма
    saveName(name) {
      let newGroup = JSON.parse(JSON.stringify(this.item))
      newGroup.name = name
      api.createGroup(newGroup)
      // TODO: Вношу правку в хранилище, что  спорно, но будет так пока нет ws апдейта
      // this.saveNameG(this.name);
      this.setIsEdit(false)
    },
    // Сохраняю новую группу
    saveGroup(name) {
      let newGroup = {
        name: name,
        parentSpID: this.item.spID,
        spID: 0,
        isForUM: false,
      }
      api.createGroup(newGroup)
      this.setIsAddGroup(false)
    },
  },
}
</script>

<style scoped></style>

// В сторе храним для каждого пункта меню выбранное сабменю

import { defineStore } from "pinia";
import { useMenu } from "@/mod/menu";
import { computed, ref } from "vue";

export const useMenuStore = defineStore("menuStore", () => {
  const usersMenu = ref([]);
  const menu = ref([]);
  const selectMenu = (m, s) => {
    menu.value.forEach((f) => {
      if (f.menu.value === m) f.sub_menu = s;
    });
  };

  const menuInit = (role) => {
    usersMenu.value = useMenu().filter((i) => role.includes(i.name));
    usersMenu.value.forEach((f) => {
      menu.value.push({ menu: f.name, sub_menu: f.sub_menu[0].name });
    });
  };
  const userMenu = computed(() => {
    return usersMenu.value;
  });
  const reset = () => {
    usersMenu.value = [];
    menu.value = [];
  };
  return { menu, selectMenu, userMenu, menuInit, reset };
});

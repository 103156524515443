import {
  coordinateExportConverter,
  coordinatesToPoints,
  isCircle,
  isPolygon,
  radiusExportConverter,
  typeExportConverter,
} from '@/shared/lib/olHelpers'

// Подготовка данных при экспорте геозон
export const exportDTO = function ({
  id = null,
  uuid,
  name,
  type,
  isActive = 1,
  action,
  center = [],
  radius = null,
  coordinates = [],
  objID = 0,
  fill_color,
}) {
  const zoneID = id
  const fillColor = fill_color
  const zoneType = Number(typeExportConverter(type))

  const [lon, lat] = isCircle(type)
    ? coordinateExportConverter(center)
    : [null, null]

  const exportRadius = isCircle(type)
    ? radiusExportConverter(radius, center)
    : null

  const points = isPolygon(type) ? coordinatesToPoints(coordinates) : null

  return {
    zoneID,
    uuid,
    name,
    zoneType,
    lon,
    lat,
    radius: exportRadius,
    points,
    isActive,
    action,
    objID,
    fillColor,
  }
}

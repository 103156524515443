<template>
  <div class="main2">
    <div class="left">
      <div class="line_one">
        <div class="nammer">
          <div class="label">Наименование геозоны:</div>
          <div class="input_name">
            <input
              type="text"
              maxlength="50"
              :value="geoZone.name"
              @input="$store.dispatch('SET_ZONE_NAME', $event.target.value)"
              placeholder="Наименование геозоны"
            />
          </div>
        </div>
      </div>
      <div class="line_two"></div>
      <div class="line_three">
        <div class="color_text">Цвет геозоны:</div>
        <div
          class="color_pick"
          :style="{ background: geoZone.fill_color }"
          @click.stop="position2ColorPicker"
          ref="position2colorpicker"
        ></div>
      </div>
    </div>
    <div class="right">
      <div class="btn">
        <green-button :label="'Сохранить'" @click.stop="$emit('save')" />
      </div>
      <div class="btn">
        <green-button :label="'Отменить'" @click.stop="$emit('cancel')" />
      </div>
    </div>
  </div>
</template>

<script>
import { GreenButton } from '@/shared/ui/greenButton'

export default {
  name: 'AddGeoZones',
  components: { GreenButton },
  emits: ['save', 'cancel'],
  data() {
    return {}
  },
  created() {},
  computed: {
    geoZone() {
      return this.$store.getters.ZONE
    },
    adderStatus() {
      return this.$store.getters.ZONE_STATUS
    },
  },
  methods: {
    setZoneType(type) {
      if (this.$store.getters.ZONE_STATUS === 'NEW') {
        this.$store.dispatch('SET_ZONE_TYPE', type)
      }
    },
    position2ColorPicker() {
      const enable = !this.$store.getters.COLOR_PICKER.enable
      this.$store.dispatch('SET_COLOR_PICKER', {
        top: this.$refs.position2colorpicker.getBoundingClientRect().top,
        left: this.$refs.position2colorpicker.getBoundingClientRect().left + 52,
        enable: enable,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.main2 {
  width: 365px;
  flex: 1 1 auto;
  border-top: 1px solid #34405e;
  //border: 1px solid green;
  //flex: 0 0 50px;
  display: flex;
  //flex-direction: column;
  padding: 5px 15px 5px 15px;
  //align-items: center;
  justify-content: flex-start;
  //border: 1px solid green;
}
.left {
  flex: 1 1 auto;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn {
  padding: 5px 0 5px 10px;
}
.line_one {
  flex: 1 1 auto;
  display: flex;
}
.nammer {
  flex: 1 1 auto;
}

input {
  padding: 2px 10px 0 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #000000;
  width: 100%;
}
.label {
  font-size: 8px;
  line-height: 9px;
  color: #a8b2bd;
  padding-top: 5px;
  padding-bottom: 5px;
  //padding: 6px 0 0 24px;
}
.input_name {
  width: auto;
  height: 22px;
  background: #ffffff;
  border: 1px solid #a8abac;
  box-sizing: border-box;
  border-radius: 4px;
  //margin: 2px 24px 0 24px;
  padding: 2px;
}
.line_two {
}
.zone_type_selector {
  display: flex;
  padding-top: 10px;
  //padding-left: 10px;
}
.zone_text {
  margin-right: 15px;
  padding-top: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #5a5a5a;
  flex: 0 0 auto;
}
.zone_type {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  margin-right: 20px;
}
.zone_circle {
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
  background: #7fee1d;
  border-radius: 50%;
  border: 1px solid #444444;
}
.zone_polygone {
  flex: 0 0 auto;
  width: 14px;
  height: 14px;
  background: #7fee1d;
  border: 1px solid #444444;
}
.zone_selected {
  box-shadow: 1px 1px 1px #1e81ce, -1px -1px 1px #1e81ce, 1px -1px 1px #1e81ce,
    -1px 1px 1px #1e81ce;
}
.zone_type:hover {
  border: #1e81ce solid 1px;
}
.line_three {
  display: flex;
  padding-top: 5px;
}
.color_pick {
  flex: 0 0 auto;
  width: 43px;
  height: 17px;
  border: 1px solid #000000;
  box-sizing: border-box;
}
.color_text {
  margin-right: 15px;
  padding-top: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #5a5a5a;
  flex: 0 0 auto;
}
</style>

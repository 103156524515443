<template>
  <div
    class="basic_content_row"
    style="
      padding-left: 15px;
      padding-right: 15px;
      width: 382px;
      overflow: hidden;
    "
  >
    <div class="field_editor_text">
      <div class="field_editor_pic">
        <div v-show="!editMode" class="btn" @click="EditModeOn">
          <img :src="require(`@/assets/icons/UI/pencil.png`)" alt="pencil" />
        </div>
        <div class="wrap_btn">
          <div
            v-show="editMode"
            class="left_btn"
            @mouseover="imgSave = imgSaveOn"
            @mouseout="imgSave = imgSaveOff"
            @click="saveEdit"
          >
            <img :src="require(`@/assets/icons/${imgSave}`)" alt="save" />
          </div>

          <div
            v-show="editMode"
            class="right_btn"
            @mouseover="imgCancel = imgCancelOn"
            @mouseout="imgCancel = imgCancelOff"
            @click="cancelEdit"
          >
            <img :src="require(`@/assets/icons/${imgCancel}`)" alt="cancel" />
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="editMode" class="field_editor glass_effect">
          <transition name="fade">
            <input
              ref="input"
              v-if="editMode"
              class="input"
              type="text"
              v-model="estring"
              @keydown.esc="cancelEdit"
              @keypress.enter="saveEdit"
            />
          </transition>
        </div>
      </transition>
      <div class="left">{{ property }}</div>
      <div class="right">{{ editString }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldEditor",
  props: { property: String, editString: String, isOpenCustom: Boolean },
  emits: ["save"],
  data() {
    return {
      estring: "",
      imgSave: "save.png",
      imgSaveOff: "save.png",
      imgSaveOn: "save_on.png",
      imgCancel: "cancel.png",
      imgCancelOn: "cancel_on.png",
      imgCancelOff: "cancel.png",
      editMode: false,
    };
  },
  watch: {
    isOpenCustom: {
      handler() {
        this.editMode = false;
      },
    },
  },
  mounted() {
    this.estring = this.editString;
  },
  methods: {
    EditModeOn() {
      this.estring = this.editString;
      this.editMode = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    EditModeOff() {
      this.editMode = false;
      this.imgCancel = this.imgCancelOff;
      this.imgSave = this.imgSaveOff;
    },
    saveEdit() {
      this.$emit("save", this.estring);
      this.EditModeOff();
    },
    cancelEdit() {
      this.estring = this.editString;
      this.EditModeOff();
    },
  },
};
</script>

<style scoped>
.input {
  background: #ffffff;
  border: 1px solid #bde6ff;
  border-radius: 4px;

  width: 309px;
  height: 24px;

  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  /*text-align: right;*/
  color: #000000;
  padding: 2px 5px 0 5px;
  /*margin-top: 3px;*/
}
.field_editor_text {
  /*padding-top: 3px;*/
  display: flex;
  width: 305px;
  position: relative;
}
.field_editor {
  z-index: 1;
  position: absolute;
  top: -8px;
  left: -32px;
  height: 30px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 30px;
}
/* slightly transparent fallback */
.glass_effect {
  background: #cee4f0;
  /*background-color: rgba(240, 246, 253, 0.9);*/
}
/* if backdrop support: very transparent and blurred */
/*@supports (*/
/*  (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))*/
/*) {*/
/*  .glass_effect {*/
/*    background-color: rgba(240, 246, 253, 0.5);*/
/*    !*background-color: rgba(185, 185, 185, 0.5);*!*/
/*    -webkit-backdrop-filter: blur(2px);*/
/*    backdrop-filter: blur(2px);*/
/*  }*/
/*}*/
.field_editor_pic {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 320px;
}
.left {
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 20px;
  text-align: left;

  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #000000;
}
.right {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #093863;
  text-align: right;
  margin-left: 10px;
  text-decoration-line: underline;
}

.btn {
  cursor: pointer;
  width: 34px;
  height: 14px;
  text-align: center;
  padding-top: 1px;
  background: #d1ebc5;
  border: 1px solid #d1ebc5;
  border-radius: 2px;
  box-sizing: border-box;
}
.btn:hover {
  border: 1px solid #87c19b;
}
.btn:active {
  background: #b2de9d;
}
.wrap_btn {
  display: flex;
  box-shadow: 0 0 3px rgba(59, 79, 62, 0.8);
}
.left_btn {
  cursor: pointer;
  width: 17px;
  height: 14px;
  text-align: center;
  padding-top: 3px;
  background: #d1ebc5;
  border: 1px solid #d1ebc5;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
}
.right_btn {
  cursor: pointer;
  width: 17px;
  height: 14px;
  text-align: center;
  padding-top: 1px;
  background: #d1ebc5;
  border: 1px solid #d1ebc5;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
}
.left_btn:hover {
  border: 1px solid #87c19b;
}
.left_btn:active {
  background: #b2de9d;
}
.right_btn:hover {
  border: 1px solid #87c19b;
}
.right_btn:active {
  background: #b2de9d;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

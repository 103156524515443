<script setup>
import { ref } from 'vue'

defineProps({ config: { type: Object, required: true } })

const settings = [
  {
    id: 1001,
    name: 'Режим работы',
    desc: 'Режим работы маяка',
    values: ['Раз в 10 минут', 'Раз в 1 час', 'Раз в 4 часа', 'Раз в 24 часа'],
  },
  {
    id: 1002,
    name: 'Пропуск GPS сеансов',
    desc: 'Пропуск GPS сеансов',
    values: [
      'Не пропускать',
      'Пропустить 1 раз',
      'Пропустить 2 раза',
      'Пропустить 3 раза',
      'Пропустить 4 раза',
      'Пропустить 5 раз',
      'Пропустить 6 раз',
      'Пропустить 7 раз',
    ],
  },
  {
    id: 1003,
    name: 'Таймеры LORA',
    desc: 'Таймеры LORA',
    values: [
      'Раз в 10 минут',
      'Раз в 20 минут',
      'Раз в 30 минут',
      'Раз в 60 минут',
    ],
  },
]

const open = ref(true)
</script>

<template>
  <div class="basic_content" :style="`height: ${open ? 120 : 30}px`">
    <div class="basic_content_head" @click="open = !open">Настройки маяка</div>
    <div class="in_basic_content basic_top_border">
      <div
        class="basic_content_row"
        v-for="setting in settings"
        :key="setting.id"
      >
        <div style="flex: 1 1 auto; margin-left: 15px">{{ setting.name }}:</div>
        <div style="margin-right: 15px; font-weight: 500">
          {{ config[setting.id] }}
        </div>
      </div>
    </div>
  </div>
</template>

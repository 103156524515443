<template>
  <div
    style="
      overflow: hidden;
      flex: 1 1 auto;
      transition-property: all;
      transition-duration: 0.3s;
      display: flex;
      flex-direction: column;
      align-items: start;
      padding-left: 5px;
    "
    :style="{ maxHeight: cardHeight.toString() + 'px' }"
  >
    <car-card :car="car" />
  </div>
  <div
    style="
      overflow: hidden;
      flex: 1 1 auto;
      transition-property: all;
      transition-duration: 0.3s;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
    :style="{ maxHeight: listHeight.toString() + 'px' }"
    @click="$emit('change', car.id)"
  >
    <div class="basic_content_row" style="padding-left: 15px">
      {{ car.name }}
    </div>
  </div>
</template>

<script>
import { CarCard } from '@/entities/cars'

export default {
  name: 'CarCardWrapper',
  components: { CarCard },
  props: {
    car: {
      type: Object,
      required: true,
    },
    modeEditOn: Boolean,
  },
  emits: ['change'],
  computed: {
    cardHeight() {
      let x = 150
      if (this.modeEditOn) x = 0
      return x
    },
    listHeight() {
      let x = 30
      if (!this.modeEditOn) x = 0
      return x
    },
  },
}
</script>

<style scoped></style>

import { FLEET_DEBUG } from '@/shared/config'
import { alarmDataPrepare } from '@/shared/lib/helpers'

export const alarmsModule = {
  state: () => ({
    alarms: [],
  }),
  getters: {
    ALL_ALARMS(state) {
      return state.alarms
    },
  },
  mutations: {
    SET_ALARMS: (state, alarms) => {
      state.alarms = alarms
    },
  },
  actions: {
    CLEAR: ({ commit }) => {
      commit('SET_ALARMS', [])
    },
    UPDATE_ALARMS: ({ state, commit }, newAlarms) => {
      // Получили в обновлении массив тревог.
      // Пока что просто добавляю к имеющимся такой тревоги нет, правлю если есть.
      // TODO: при каком условии удалять тревогу?
      if (FLEET_DEBUG) console.log('UPDATE_ALARMS ')
      let alarms = [...state.alarms]

      newAlarms.forEach((i) => {
        let oldAlarm = alarms.find((j) => j.alarmID === i.alarmID)
        if (i.alarmState === 100) {
          if (oldAlarm) {
            alarms = alarms.filter((j) => j.alarmID !== i.alarmID)
          }
        } else {
          if (oldAlarm) {
            Object.assign(oldAlarm, alarmDataPrepare(i))
          } else {
            alarms.push(alarmDataPrepare(i))
          }
        }
      })

      commit('SET_ALARMS', alarms)
    },
    // SET_ALARMS: ({ commit }, alarms) => {},
    // ADD_ALARM: ({ commit }, alarms) => {},
    // EDIT_ALARM: ({ commit }, alarms) => {},
    // DEL_ALARM: ({ commit }, alarms) => {},
  },
  namespaced: true,
}

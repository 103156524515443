import { local } from '@/app/deliv'
import Connect from '@/entities/connect'
import { createRouter, createWebHistory } from 'vue-router'
import { wamp } from '../../deliv'
import { enableLayer } from './middleware/layersSwitcher'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to) => {
  if (!Connect.isLogin.value && to.name !== 'loginPage') {
    const login = local.get('fleet_login')
    const pass = local.get('fleet_pass')
    if (!login || !pass) {
      return { name: 'loginPage' }
    } else {
      const appVersion = 'v' + '1.1.2' // + version;
      wamp.open(login, pass, 'wampcra', appVersion, true)
    }
  }

  if (Connect.isLogin.value && to.name === 'loginPage') {
    return { name: 'monitoring' }
  }
})

router.afterEach((to, from) => {
  // disableLayer(from.name);
  if (!from.name || from.name === 'loginPage')
    setTimeout(() => {
      enableLayer(to.name, from.name)
    }, 1000)

  enableLayer(to.name, from.name)
})

export { router }

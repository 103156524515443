import menu from "@/models/menu";

const state = {
  menu: menu,
};
const getters = {
  MENU: (state) => {
    return state.menu;
  },
};
const mutations = {
  SET_MENU: (state, payload) => {
    state.menu = payload;
  },
};
const actions = {
  GET_MENU: (context) => {
    if (localStorage.menu) {
      const payload = localStorage.getItem("menu");
      context.commit("SET_MENU", JSON.parse(payload));
    }
  },
  SET_MENU: (context, payload) => {
    // console.log("payload", payload);
    localStorage.setItem("menu", JSON.stringify(payload));
    context.commit("SET_MENU", payload);
    context.dispatch("INIT_CAR_BOX");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

export const monitoringRoute = {
  path: 'monitoring',
  redirect: { name: 'all_cars' },
  name: 'monitoring',
  components: {
    monitoring: () => import('./MonitoringPage.vue'),
  },
  children: [
    {
      path: 'all_cars',
      name: 'all_cars',
      components: {
        allCars: () => import('./MonitoringAllCars.vue'),
      },
    },
    {
      path: 'work_list',
      name: 'work_list',
      components: {
        workList: () => import('./MonitoringWorkList.vue'),
      },
    },
    {
      path: 'groups_list',
      name: 'groups_list',
      components: {
        groupsList: () => import('./MonitoringGroupsList.vue'),
      },
    },
  ],
}

export default {
  car_id: 0,
  menu: [
    {
      id: 1,
      name: "carproperty",
      title: "Свойства",
      selected: false,
    },
    {
      id: 2,
      name: "carhistory",
      title: "История",
      selected: false,
    },
    {
      id: 3,
      name: "carsetting",
      title: "Настройка",
      selected: false,
    },
  ],
};

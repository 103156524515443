import jwt_decode from 'jwt-decode'
import { computed, ref } from 'vue'

const isLogin = ref(false)
const isConnected = ref(false)
const session = ref()
const details = ref()
const close_details = ref()
const close_reason = ref()
const token = ref()
const wamp = ref()

const logout = (reasonClose, detailsClose) => {
  isLogin.value = false
  isConnected.value = false
  close_details.value = detailsClose
  close_reason.value = reasonClose
  session.value = null
  details.value = null
  token.value = null
  wamp.value = null
}
const connected = (sessionConn, detailsConn) => {
  isLogin.value = true
  isConnected.value = true
  details.value = detailsConn
  session.value = sessionConn
  token.value = detailsConn['token']
}
const disconnected = (reasonDisConn, detailsDisConn) => {
  isConnected.value = false
  close_details.value = detailsDisConn
  close_reason.value = reasonDisConn
}
const userName = computed(() => {
  if (token.value) return jwt_decode(token.value).name
  return ''
})
const userID = computed(() => {
  if (token.value) return jwt_decode(token.value).userID.toString()
  return null
})
const spID = computed(() => {
  if (token.value) return jwt_decode(token.value).spID.toString()
  return null
})

const login = computed(() => {
  if (token.value) return jwt_decode(login.value).spID.toString()
  return null
})
const setWamp = (w) => {
  wamp.value = w
}

export default {
  close_details,
  close_reason,
  connected,
  details,
  disconnected,
  isConnected,
  isLogin,
  login,
  logout,
  session,
  setWamp,
  spID,
  token,
  userID,
  userName,
  wamp,
}

import {
  carBoxRoute,
  geozonesRoute,
  getPrivateRoute,
  monitoringRoute,
  publicRoute,
  settingsRoute,
} from '@/pages'
import { alarmsRoute } from '@/pages/alarms/route'
import { managetRoute } from '@/pages/manager/route'
import { reportsRoute } from '@/pages/reports/route'

export default [
  publicRoute,
  getPrivateRoute([
    carBoxRoute,
    monitoringRoute,
    geozonesRoute,
    settingsRoute,
    reportsRoute,
    alarmsRoute,
    managetRoute,
  ]),
]

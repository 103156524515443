<template>
  <div class="range_picker_main">
    <div
      class="range_picker_btn"
      :class="[{ range_picker_btn_selected: selected === range.id }]"
      v-for="range in ranges"
      :key="range.id"
      @click="setDate(range.id)"
    >
      {{ range.name }}
    </div>
    <div
      class="range_picker_btn"
      :class="[{ range_picker_btn_selected: selected === 'calendar' }]"
      style="padding-top: 0; width: 64px"
      @click="setDate('calendar')"
    >
      <img :src="require(`@/assets/icons/calendar.png`)" alt="calendar" />
    </div>
  </div>
  <div class="range_picker_dp">
    <Datepicker
      ref="dp"
      style="width: 360px"
      v-model="date"
      range
      maxRange="30"
      :clearable="false"
      selectText="Выбрать"
      cancelText="Отменить"
      locale="ru"
      closeOnScroll
      enableSeconds
      :partialRange="false"
      :format="'yyyy.MM.dd HH:mm:ss'"
      :maxDate="maxDate"
      :readonly="selected !== 'calendar'"
      multiCalendars
      @update:modelValue="setDateCalendar"
      @open="menuOpen"
    ></Datepicker>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'RangePicker',
  components: { Datepicker },
  props: {
    historyRange: {
      type: Object,
      default() {
        return {
          range: 'week',
          from: null,
          till: null,
        }
      },
    },
  },
  emits: ['changeHistoryRange'],
  data() {
    return {
      date: [],
      selected: '',
      ranges: [
        { id: 'day', name: 'День' },
        { id: 'week', name: 'Неделя' },
        { id: 'month', name: 'Месяц' },
      ],
    }
  },
  watch: {
    historyRange: {
      handler() {
        this.setDate(this.historyRange.range)
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    maxDate() {
      let endDate = new Date()
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      return endDate
    },
  },
  methods: {
    setDate(id) {
      this.selected = id
      let endDate = new Date()
      let startDate = new Date(new Date().setDate(endDate.getDate() - 1))
      switch (id) {
        case 'day':
          this.date = [startDate, endDate]
          break
        case 'week':
          startDate = new Date(new Date().setDate(endDate.getDate() - 7))
          this.date = [startDate, endDate]
          break
        case 'month':
          startDate = new Date(new Date().setMonth(endDate.getMonth() - 1))
          this.date = [startDate, endDate]
          break
        case 'calendar':
          if (this.date[0] && this.date[1]) {
            startDate = this.date[0]
            endDate = this.date[1]
            setTimeout(() => {
              this.$refs.dp.openMenu()
            }, 0)
          } else {
            startDate = new Date(this.historyRange.from * 1000)
            endDate = new Date(this.historyRange.till * 1000)
            this.date = [startDate, endDate]
          }
          break
      }
      this.$emit('changeHistoryRange', {
        range: this.selected,
        from: Math.floor(startDate.getTime() / 1000),
        till: Math.floor(endDate.getTime() / 1000),
      })
    },
    menuOpen() {
      let endDate = new Date()
      let startDate = this.date[0]
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      endDate = this.date[1]
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      this.date = [startDate, endDate]
    },
    setDateCalendar() {
      this.$emit('changeHistoryRange', {
        range: this.selected,
        from: Math.floor(this.date[0].getTime() / 1000),
        till: Math.floor(this.date[1].getTime() / 1000),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin no-selected {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.range_picker_main {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.range_picker_dp {
  padding-bottom: 5px;
}
.range_picker_btn {
  @include no-selected;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
  transition: all 0.2s;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #374c70;
}
.range_picker_btn:hover {
  border: 1px solid #0288d1;
}
.range_picker_btn:active {
  //background: #daf2ff;
  border: 1px solid #0288d1;
  box-shadow: 0px 0px 3px 1px #79c7f8;
}
.range_picker_btn_selected {
  background: #daf2ff;
  border: 1px solid #0288d1;
  transition: all 0.2s;
}
.range_picker_btn_selected:active {
  background: #daf2ff;
  box-shadow: 0px 0px 3px 1px #79c7f8;
}
</style>

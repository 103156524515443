<template>
  <transition name="fadeTT">
    <div
      class="tooltiptext"
      v-if="isShow"
      style="position: absolute"
      :style="{
        top: toolTip.y.toString() + 'px',
        left: toolTip.x.toString() + 'px',
      }"
    >
      {{ toolTip.text }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "ToolTip",
  props: { toolTip: Object }, // Принимает объект: x, y, text
  data() {
    return {
      isShow: false,
      isEnable: false, // надо чтоб не всплывало пустой месседж при быстром (овер менее 1 с) проведении мышкой над целью
    };
  },
  // Задержка появления тултипа
  watch: {
    toolTip: {
      handler(v) {
        if (v.text !== "") {
          this.isEnable = true;
          setTimeout(() => {
            if (this.isEnable) this.isShow = true;
          }, 1000);
        } else {
          this.isEnable = false;
          this.isShow = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.tooltiptext {
  background: white;
  border: 1px solid #1e81ce;
  border-radius: 4px;
  padding: 4px;
  z-index: 12;
  font-weight: 400;
  font-size: 10px;
  box-shadow: 0 4px 6px 2px #dddddd;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 15px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1e81ce transparent transparent transparent;
}
.fadeTT-enter-active,
.fadeTT-leave-active {
  transition: opacity 0.2s ease;
}
.fadeTT-enter-from,
.fadeTT-leave-to {
  opacity: 0;
}
</style>

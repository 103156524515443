<template>
  <groups-folder
    @save="$emit('save')"
    :parent="parent"
    :type="'include'"
    :items="include"
    :level="level"
  />
  <groups-folder
    @save="$emit('save')"
    :parent="parent"
    :type="'exclude'"
    :items="exclude"
    :level="level"
  />
</template>

<script>
import mixin from '@/mixins/groups'
import GroupsFolder from './GroupsFolder.vue'

export default {
  name: 'ActionChangeGroup',
  components: { GroupsFolder },
  mixins: [mixin],
  props: {
    level: Number,
    item: Object,
    parent: String,
  },
  emits: ['save'],
  computed: {
    selectID() {
      return this.parent + '-' + this.item.id.toString()
    },
    include() {
      return this.item.spsInclude
    },
    exclude() {
      return this.item.spsExclude
    },
  },
}
</script>

<style scoped></style>

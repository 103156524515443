// ----------------------------------- Тревоги -----------------------------------
import { apiCore } from '@/app/deliv'
import { API_ALARMS, DEBUG } from '@/shared/config'
import store from '@/store'

export const api = {
  // Получение списка тревог
  get: async () => {
    const response = await apiCore.get(API_ALARMS.LIST)
    if (DEBUG) console.log('get alarms: ', response)
    if (response.status === 200) {
      store.dispatch('alarms_mod/UPDATE_ALARMS', response.data)
    }
  },

  // Получение данныч по тревоге
  getAlarm: async (alarmUID) => {
    const response = await apiCore.get(API_ALARMS.TAKE + alarmUID)
    if (DEBUG) console.log('get alarm: ', response)
  },

  // Добавление комментария к тревоге
  update: async (alarm) => {
    const response = await apiCore.post(API_ALARMS.UPDATE, alarm)
    if (DEBUG) console.log('update alarm: ', response)
  },

  // Закрытие тревоги
  finish: async (alarm) => {
    const response = await apiCore.post(API_ALARMS.FIN, alarm)
    if (DEBUG) console.log('finish alarm: ', response)
  },
}

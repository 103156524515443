import box from "@/models/carbox";

const state = {
  carbox: JSON.parse(JSON.stringify(box)),
};
const getters = {
  CAR_BOX: (state) => {
    return state.carbox;
  },
  CAR_BOX_ACTIV_MENU: (state) => {
    if (state.carbox.menu.find((i) => i.selected))
      return state.carbox.menu.find((i) => i.selected).id;
  },
};
const mutations = {
  SET_CAR_BOX: (state, payload) => {
    state.carbox = payload;
  },
  INIT_CAR_BOX: (state) => {
    state.carbox = JSON.parse(JSON.stringify(box));
  },
};
const actions = {
  SET_CAR_BOX: (context, payload) => {
    context.dispatch("carsHistory_mod/CLEAR");
    context.commit("SET_CAR_BOX", payload);
  },
  INIT_CAR_BOX: (context) => {
    context.dispatch("carsHistory_mod/CLEAR");
    context.dispatch("map_mod/SET_CARS", false);
    context.commit("INIT_CAR_BOX");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

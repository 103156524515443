import {
  coordinateImportConverter,
  isCircle,
  isUnknownType,
  pointsToCoordinates,
  radiusImportConverter,
  typeImportConverter,
} from '@/shared/lib/olHelpers'

import {
  DEFAULT_FILL_COLOR,
  DEFAULT_STROKE_COLOR,
  DEFAULT_STROKE_WIDTH,
} from '@/shared/config'
import { isNull } from '@/shared/lib'

// Подготовка данных при импорте геозон
export const importDTO = ({
  zoneID = null,
  name = '',
  zoneType = null,
  lon = null,
  lat = null,
  radius = null,
  points = null,
  uuid = null,
  fillColor = null,
  ismap = false,
}) => {
  const type = typeImportConverter(zoneType)

  //  TODO: требуются проверки всех входящих. реализовать на тс
  if (isUnknownType(type) || isNull(zoneID || isNull(uuid))) return false

  const id = zoneID

  const fill_color = fillColor ? fillColor : DEFAULT_FILL_COLOR
  const stroke_color = DEFAULT_STROKE_COLOR
  const stroke_width = DEFAULT_STROKE_WIDTH

  const coordinates = isCircle(type) ? null : pointsToCoordinates(points)

  const center = isCircle(type) ? coordinateImportConverter([lon, lat]) : null

  const importRadius = isCircle(type)
    ? radiusImportConverter(radius, center)
    : []

  return {
    id,
    uuid,
    name,
    type,
    center,
    radius: importRadius,
    coordinates,
    fill_color,
    stroke_color,
    stroke_width,
    ismap,
  }
}

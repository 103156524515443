export default function save2LocalStorage(store) {
  store.subscribe((mutation) => {
    // if (mutation.type === "cf_mod/SET_CUSTOM_FIELDS") {
    //   const payload = JSON.stringify(mutation.payload);
    //   localStorage.setItem("custom_fields", payload);
    // }
    if (mutation.type === "carsGroups_mod/SET_GROUPS") {
      const payload = JSON.stringify(mutation.payload);
      localStorage.setItem("groups", payload);
    }
  });
}

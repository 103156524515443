import { RGEOCODE } from '@/shared/config'
import axios from 'axios'

const KEYS = ['city', 'street', 'house_number', 'name']

const api = axios.create({ headers: { 'Access-Control-Allow-Origin': '*' } })
api.interceptors.response.use(
  (response) => response,
  () => 'не удалось определить адрес'
)

const fn = (obj) => (arr, key) => {
  if (obj && key in obj && obj[key] !== '') arr.push(obj[key])
  return arr
}

//  Получение одного адреса по координатам
const getSingle = async (lat, lon) => {
  const res = await api.get(RGEOCODE.SINGLE(lat, lon))
  return res.status === 200
    ? KEYS.reduce(fn(res.data), []).join(', ')
    : 'не удалось определить адрес'
}

//  Получение нескольких адресов по координатам
//  coordinates {id: [lat, lon], ...}
const getMulti = async (coordinates) => {
  const res = await api.post(RGEOCODE.MULTI, Object.values(coordinates))
  const addressArr =
    res.status === 200
      ? res.data.map((i) => KEYS.reduce(fn(i), []).join(', '))
      : false

  const addressObj = Object.keys(coordinates).reduce(
    (address, current, index) => {
      address[current] = addressArr[index]
      return address
    },
    {}
  )

  return addressObj
}

export { getMulti, getSingle }

export default {
  group_id: 0,
  menu: [
    {
      id: 1,
      name: "group_property",
      title: "Просмотр",
      selected: false,
    },
    {
      id: 2,
      name: "group_manual",
      title: "Состав",
      selected: false,
    },
    {
      id: 3,
      name: "grou_pauto",
      title: "Автоматизация",
      selected: false,
    },
  ],
};

export const alarmsRoute = {
  path: 'alarms',
  name: 'alarms',
  redirect: { name: 'all_alarms' },
  components: {
    alarms: () => import('./AlarmsPage.vue'),
  },
  children: [
    {
      path: 'all_alarms',
      name: 'all_alarms',
      components: {
        allAlarms: () => import('./AlarmsAll.vue'),
      },
    },
  ],
}

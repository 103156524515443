const nest = (items, rootID = "0", link = "parentSpID") =>
  items
    .filter((item) => item[link] === rootID)
    .map((item) => ({
      ...item,
      children: nest(items, item.spID),
    }));

export default function flat2tree(store) {
  store.subscribe((mutation) => {
    if (mutation.type === "g_mod/SET_GROUPS") {
      store.dispatch("g_mod/SET_TREE", nest(mutation.payload, 0, "parentSpID"));
    }
  });
}

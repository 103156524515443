export const OPNMapModule = {
  state: () => ({
    //  Показываем/скрываем слои
    //    cars - Слой кластер автомобилей
    //    history - Слой кластер истории
    //    alarms - Слой кластер тревог
    //    one - Слой одного объекта (выбрали объект или тревогу)
    //    zones - Слой для геозон
    //    draw - Слой для рисования и модификаций геозон
    showLayers: ["zones"],

    //    Слои для которых включено выделение фьутерсов
    selectLayers: [],

    //    Слой для прокачки
    fitLayer: "",

    //    Объект для one-слоя
    oneObject: null,

    //    Рисовалка
    drawLayer: {
      type: "Polygon", //                   Тип фигуры для рисования геозоны (Polygon/Circle)
      color: "rgba(162,239,223,0.76)", //   Цвет геозоны
    },
  }),
  getters: {
    SHOW_LAYERS(state) {
      return state.showLayers;
    },
    SELECT_LAYERS(state) {
      return state.selectLayers;
    },
    FIT_LAYER(state) {
      return state.fitLayer;
    },
    GET_ONE(state) {
      return state.oneObject;
    },
    GET_DRAW(state) {
      return state.drawLayer;
    },
  },
  mutations: {
    SET_SHOW_LAYERS(state, layers) {
      state.showLayers = layers;
    },
    SET_SELECT_LAYERS(state, layers) {
      state.selectLayers = layers;
    },
    SET_FIT_LAYER(state, layer) {
      state.fitLayer = layer;
    },
    SET_ONE(state, object) {
      state.oneObject = object;
    },
    SET_DRAW_LAYER(state, layer) {
      state.drawLayer = layer;
    },
  },
  actions: {
    SET_SHOW_LAYERS: ({ commit }, layers) => {
      commit("SET_SHOW_LAYERS", layers);
    },
    ADD_SHOW_LAYERS: ({ state, commit }, layer) => {
      let layers = [...state.showLayers];
      if (!layers.find((l) => l === layer)) layers.push(layer);
      commit("SET_SHOW_LAYERS", layers);
    },
    DEL_SHOW_LAYERS: ({ state, commit }, layer) => {
      commit(
        "SET_SHOW_LAYERS",
        [...state.showLayers].filter((i) => i !== layer)
      );
    },

    SET_SELECT_LAYERS: ({ commit }, layers) => {
      commit("SET_SELECT_LAYERS", layers);
    },
    ADD_SELECT_LAYERS: ({ state, commit }, layer) => {
      let layers = [...state.selectLayers];
      if (!layers.find((l) => l === layer)) layers.push(layer);
      commit("SET_SELECT_LAYERS", layers);
    },
    DEL_SELECT_LAYERS: ({ state, commit }, layer) => {
      commit(
        "SET_SELECT_LAYERS",
        [...state.selectLayers].filter((i) => i !== layer)
      );
    },

    SET_FIT_LAYER: ({ commit }, layer) => {
      commit("SET_FIT_LAYER", layer);
    },
    SET_ONE: ({ commit }, object) => {
      commit("SET_ONE", object);
    },

    SET_DRAW_TYPE: ({ state, commit }, type) => {
      let layer = Object.assign({}, state.drawLayer);
      layer.type = type;
      commit("SET_DRAW_LAYER", layer);
    },
    SET_DRAW_COLOR: ({ state, commit }, color) => {
      let layer = Object.assign({}, state.drawLayer);
      layer.color = color;
      commit("SET_DRAW_LAYER", layer);
    },
  },
  namespaced: true,
};

<template>
  <div
    @click.stop="setSelectObj(obj.objID)"
    class="basic_content_row"
    style="padding: 10px; height: 24px"
    v-for="obj in sortedAndSearchedAndFilteredCars"
    :key="obj.objID"
  >
    <check-box
      :label="obj.name"
      :mode="selectObjets.includes(obj.objID) ? 'on' : 'off'"
    />
  </div>
</template>

<script>
import mixin from '@/mixins/groups'
import { CheckBox } from '@/shared/ui'
import { useCarsStore } from '@/stores/carsStore'
import { computed } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ObjectSelector',
  components: { CheckBox },
  mixins: [mixin],
  mounted() {
    this.allObjs2Select(this.list(this.selectId))
  },
  setup() {
    const sortedAndSearchedAndFilteredCars = computed(() => {
      return useCarsStore().sortAndFilter
    })
    return { sortedAndSearchedAndFilteredCars }
  },
  watch: {
    selectId: {
      handler() {
        this.allObjs2Select(this.list(this.selectId))
      },
    },
  },
  computed: {
    ...mapGetters({
      // sortedAndSearchedAndFilteredCars:
      //   "sfs_mod/SORTED_AND_SEARCHED_AND_FILTERED_CARS",
      list: 'g_mod/GET_GROUP_OBJECTS',
      selectObjets: 'g_mod/GET_OBJECTS_SELECT',
      allGroups: 'g_mod/GET_GROUPS',
    }),
  },
  methods: {
    ...mapActions({
      allObjs2Select: 'g_mod/SET_OBJETCS_SELECT',
      setSelectObj: 'g_mod/ADD_REMOVE_OBJECT_TO_SELECT',
    }),
  },
}
</script>

<style scoped></style>

export const geozonesRoute = {
  path: 'geozones',
  redirect: { name: 'all_geozones' },
  name: 'geozones',
  components: {
    geozones: () => import('./GeozonesPage.vue'),
  },
  children: [
    {
      path: 'all_geozones',
      name: 'all_geozones',
      components: {
        allZones: () => import('./GeozonesAllZones.vue'),
      },
    },
  ],
}

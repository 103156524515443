import { FLEET_DEBUG } from '@/shared/config'
import { flat2Tree } from '@/shared/lib/helpers'

export const objectGroupsModule = {
  state: () => ({
    groups: [],
    groupsTree: null,
    selectId: null,
    isEdit: false,
  }),
  getters: {
    GET_ALL_GROUPS(state) {
      return state.groups
    },
    GET_GROUPS_TREE(state) {
      return state.groupsTree
    },
    GET_SELECT_ID(state) {
      return state.selectId
    },
    GET_IS_EDIT(state) {
      return state.isEdit
    },
  },
  mutations: {
    SET_GROUPS(state, groups) {
      // console.log("mutations - SET_GROUPS");
      state.groups = groups
    },
    SET_GROUPS_TREE(state, groupsTree) {
      // console.log("mutations - SET_GROUPS_TREE");
      state.groupsTree = groupsTree
    },
    SET_SELECT_ID(state, selectId) {
      // console.log("mutations - SET_SELECT_ID group");
      state.selectId = selectId
    },
    SET_IS_EDIT(state, isEdit) {
      // console.log("mutations - SET_IS_EDIT group");
      state.isEdit = isEdit
    },
  },
  actions: {
    SET_SELECT_ID: ({ commit }, id) => {
      commit('SET_IS_EDIT', false)
      commit('SET_SELECT_ID', id)
    },
    SET_IS_EDIT: ({ commit }, isEdit) => {
      commit('SET_IS_EDIT', isEdit)
    },
    FETCH_GROUPS: ({ commit }, groups) => {
      // struct {
      //   SpID                int    json:"spID" //если = 0 то создание
      //   ParentSpID          int    json:"parentSpID" //для уровней
      //   Name                string json:"name"
      //   IsForUserManagement bool   json:"isForUM" //используется только в управлении пользователями (группа видна определённому пользователю, но отравляется пользователю тк у него есть права администрирования пользователей)
      // }
      // var courentGroups = JSON.parse(JSON.stringify(state.groups));
      //
      // const addChildren = (root, item) => {
      //   if (root.spID === item.ParentSpID) {
      //     item.children = [];
      //     root.children.push(item);
      //   }
      // };
      // const adder2Tree = (tree, item) => {
      //   tree.children.forEach((c) => {
      //     if (c.spID === item.parentSpID) {
      //       c.push(item);
      //     } else if (c.children.length > 0) {
      //       c.children.forEach((subChil) => adder2Tree(subChil, item));
      //     }
      //   });
      // };

      let tree = {
        name: 'Все объекты',
        spID: 0,
      }
      groups.sort((a, b) => (a.name > b.name ? 1 : -1))
      tree.children = flat2Tree(groups)

      if (FLEET_DEBUG) console.log('FETCH_GROUPS groups: ', groups)
      if (FLEET_DEBUG) console.log('FETCH_GROUPS tree: ', tree)

      // localStorage.setItem("newGroups", JSON.stringify(groups));

      commit('SET_GROUPS', groups)
      commit('SET_GROUPS_TREE', tree)
    },
    UPDATE_GROUPS: ({ state, commit }, newGroups) => {
      if (FLEET_DEBUG) console.log('UPDATE_GROUPS: ', newGroups)

      // let newGroups = [
      //   {
      //     isForUM: false,
      //     name: "Новая группа",
      //     objects: null,
      //     parentSpID: 1463,
      //     spID: 2470,
      //     spType: 3,
      //   },
      // ];
      let groups = JSON.parse(JSON.stringify(state.groups))
      // let groupTree = state.groupsTree;
      let newGroupsId = []
      let groupsId = []
      // console.log("groupTree", groupTree);

      newGroups.forEach((g) => newGroupsId.push(g.spID))
      groups.forEach((g) => groupsId.push(g.spID))

      groups.forEach((g) => {
        if (newGroupsId.includes(g.spID)) {
          let ng = newGroups.find((i) => i.spID === g.spID)
          g.isForUM = ng.isForUM
          g.name = ng.name
          g.objects = ng.objects
          g.parentSpID = ng.parentSpID
          g.spType = ng.spType
        }
      })
      newGroups.forEach((g) => {
        if (!groupsId.includes(g.spID)) {
          groups.push(g)
        }
      })

      let tree = {
        name: 'Все объекты',
        spID: 0,
      }

      groups.sort((a, b) => (a.name > b.name ? 1 : -1))
      tree.children = flat2Tree(groups)

      commit('SET_GROUPS', groups)
      commit('SET_GROUPS_TREE', tree)
    },
  },
  namespaced: true,
}

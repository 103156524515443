import { addConfig2Cars, rounded10 } from '@/shared/lib'
import { unixGmtToDateTime } from '@/shared/lib/date'
import { coordinateImportConverter } from '@/shared/lib/olHelpers'

const getPower = (accum) => (accum > 3 ? 100 : Math.ceil(accum / 0.0003) / 100)
const getConnectType = (gps) => (gps === 0 ? 'LBS' : 'GPS')
const getRealPosition = ({ lat, lon, gLat, gLon }) =>
  lat === 0 && lon === 0
    ? [rounded10(gLon), rounded10(gLat)]
    : [rounded10(lon), rounded10(lat)]

// Подготовка данных по пришедшей последней позиции объекта
export const importLastPositionDTO = (
  {
    objID,
    unixGmt,
    unixEvGmt,
    descIds,
    lat,
    lon,
    // isGuard,
    // isAlarm,
    // isService,
    // isIgn,
    // isPark,
    // isLock,
    // isEngineLocked,
    // isSiren,
    speed,
    // s1,
    // s2,
    // s3,
    // s4,
    // s5,
    // s6,
    // s7,
    // s8,
    // r1,
    // r2,
    // r3,
    // r4,
    // r5,
    // r6,
    // r7,
    // r8,
    // a1,
    // a2,
    // a3,
    // a4,
    // a5,
    gps,
    gsm,
    board,
    accum,
    course,
    // path,
    gLat,
    gLon,
    // useGsm,
  },
  [rLon, rLat] = getRealPosition({ lat, lon, gLat, gLon }),
  [mLon, mLat] = coordinateImportConverter([rLon, rLat])
) => ({
  objID,
  unixGmt,
  unixEvGmt,
  descIds,
  lat,
  lon,
  speed,
  gps,
  gsm,
  board,
  accum,
  course,
  gLat,
  gLon,
  powervolume: getPower(accum),
  connecttype: getConnectType(gps),
  datetime: unixGmtToDateTime(unixGmt),
  rLat,
  rLon,
  mLat,
  mLon,
  address: '-',
})

export const importDTO = ({
  objID,
  sn,
  gos,
  model,
  vin,
  color,
  year,
  // zoneIds,
  // dopSpIds,
  // zmbObjID1,
  // zmbObjID2,
  // owner,
  // ownerPhone,
  // user1,
  // user2,
  // user3,
  // user4,
  // phone1,
  // phone2,
  // phone3,
  // phone4,
  // clName,
  // clGroupName,
  // clTexOsm,
  // billAgrDate,
  reqClientName,
  // lastConGmt,
  lastPos,
  // checkEngine,
  // autoZoneID,
  // projectID,
  // demurrageLim,
  action,
  // billState,
  customFields,
  // lastCmt,
  // lastCmtGmt,
  isTracker,
}) => ({
  objID,
  sn,
  gos,
  model,
  vin,
  color,
  year,
  reqClientName,
  lastPos: importLastPositionDTO(lastPos),
  action,
  customFields,
  isTracker,
  isMap: true,
  name: gos,
  config: addConfig2Cars(objID),
})

<template>
  <div
    style="
      flex: 1 1 auto;
      display: flex;
      padding: 0 15px 0 15px;
      background: #f6fcff;
      border-top: 1px solid #a6ddff;
      border-bottom: 1px solid #a6ddff;
    "
  >
    <div style="flex: 1 1 auto; padding-right: 15px; height: 90px">
      <div class="label">Наименование поля:</div>
      <div :class="[{ input: true }, { input_error: isError }]">
        <input
          type="text"
          v-model="name"
          placeholder="Наименование поля"
          @focus="isError = false"
        />
      </div>
      <div class="label">Описание поля:</div>
      <div class="input">
        <input type="text" v-model="desc" placeholder="Описание поля" />
      </div>
    </div>
    <div
      style="
        flex: 0 0 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 0 8px 0;
        height: 90px;
      "
    >
      <check-box
        :fontSize="12"
        :mode="global ? 'on' : 'off'"
        :label="'глобальное'"
        @click="global = !global"
      />
      <green-button :label="'Сохранить'" @click="saveCustom" />
      <green-button :label="'Отменить'" @click="cancelCustom" />
    </div>
  </div>
</template>

<script>
import { CheckBox } from '@/shared/ui'
import { GreenButton } from '@/shared/ui/greenButton'

export default {
  name: 'CustomFieldEdit',
  components: { GreenButton, CheckBox },
  emits: ['cancel', 'save'],
  props: {
    fieldId: String,
    fieldName: String,
    fieldDesc: String,
    fieldGlobal: Boolean,
  },
  data() {
    return { name: '', desc: '', global: false, isError: false }
  },
  mounted() {
    this.name = this.fieldName
    this.desc = this.fieldDesc
    this.global = this.fieldGlobal
  },
  methods: {
    cancelCustom() {
      this.$emit('cancel')
    },
    saveCustom() {
      if (this.name !== '') {
        this.$emit('save', {
          id: this.fieldId,
          name: this.name,
          desc: this.desc,
          global: this.global,
        })
      } else {
        this.isError = true
      }
    },
  },
}
</script>

<style scoped>
.label {
  font-size: 8px;
  line-height: 9px;
  color: #a8b2bd;
  padding: 6px 0 0 24px;
}
.input {
  width: 100%;
  height: 22px;
  background: #ffffff;
  border: 1px solid #a8abac;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 2px 0 0 0;
  padding: 2px;
}
.input:focus-within {
  border: 1px solid #1e81ce;
}
.input_error {
  border: 1px solid #fd6262;
}
input {
  padding: 2px 10px 0 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #000000;
  width: 100%;
}
</style>

export const carsHistoryModule = {
  state: () => ({
    selected: 0,
    cars: [],
  }),
  getters: {
    ALL_CARS_HISTORY(state) {
      return state.cars;
    },
    GET_HISTORY_TIME(state) {
      return state.selected;
    },
  },
  mutations: {
    SET_CARS_HISTORY(state, cars) {
      state.cars = cars;
    },
    DEL_CARS_HISTORY(state) {
      state.cars = [];
    },
    SET_HISTORY_TIME(state, x) {
      state.selected = x;
    },
  },
  actions: {
    CLEAR: ({ commit }) => {
      commit("DEL_CARS_HISTORY", []);
    },
    SET_CARS_HISTORY({ commit }, cars) {
      cars.sort((b, a) => (a.datetime > b.datetime ? 1 : -1));
      let n = cars.length;
      cars.map((i) => {
        i.name = n.toString();
        n--;
      });
      commit("SET_CARS_HISTORY", cars);
    },
    SET_HISTORY_TIME({ commit }, x) {
      commit("SET_HISTORY_TIME", x);
    },
    DEL_HISTORY_TIME({ commit }) {
      commit("SET_HISTORY_TIME", 0);
    },
    DEL_CARS_HISTORY({ commit }) {
      commit("SET_CARS_HISTORY");
    },
    SHOW_CAR: ({ state, commit }, payload) => {
      let cars = [...state.cars];
      if (payload.id.length > 0) {
        cars.forEach((i) => {
          if (payload.id.includes(i.lastPos.unixGmt)) {
            if (payload.isMap === "invert") {
              i.isMap = !i.isMap;
            } else {
              i.isMap = payload.isMap;
            }
          }
        });
      } else {
        cars.forEach((i) => (i.isMap = payload.isMap));
      }
      commit("SET_CARS_HISTORY", cars);
    },
  },
  namespaced: true,
};

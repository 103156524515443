import { createStore } from "vuex";
import menu from "@/store/modules/menu";
import geozone from "@/store/modules/geozone";
import carbox from "@/store/modules/carbox";
import groupbox from "@/store/modules/groupbox";
import { carsModule } from "@/store/modules/carsModule";
// import { zonesModule } from "@/store/modules/geozones";
import { sfsModule } from "@/store/modules/searchFilterSortModule";
import { carsGroupsModule } from "@/store/modules/carsGroupsModule";
import { carsHistoryModule } from "@/store/modules/carsHistoryModule";
import { customFieldsModule } from "@/store/modules/customFieldsModule";
import { mapModule } from "@/store/modules/mapModule";
import { reportTemplateModule } from "@/store/modules/reportTemplateModule";
import { objectGroupsModule } from "@/store/modules/objectGroupsModule";
import { groupsModule } from "@/store/modules/groupModule";
import { groupControlModule } from "@/store/modules/groupControlModule";
import { alarmsModule } from "@/store/modules/alarmsModule";
import { OPNMapModule } from "@/store/modules/OPNMapModule";

import flat2tree from "@/store/plugins/groups";

import save2LocalStorage from "@/store/plugins/save2LocalStorage";

export default createStore({
  plugins: [save2LocalStorage, flat2tree],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    menu,
    carbox,
    groupbox,
    geozone,
    cars_mod: carsModule,
    // zones_mod: zonesModule,
    sfs_mod: sfsModule,
    carsGroups_mod: carsGroupsModule,
    carsHistory_mod: carsHistoryModule,
    cf_mod: customFieldsModule,
    map_mod: mapModule,
    reportTemp_mod: reportTemplateModule,
    objGroup_mod: objectGroupsModule,
    g_mod: groupsModule,
    gc_mod: groupControlModule,
    alarms_mod: alarmsModule,
    OPN_mod: OPNMapModule,
  },
});

import { Select } from 'ol/interaction'
import { toRaw } from 'vue'
import { style } from './style'

const select = (s, layers, overlays) => {
  //  Лаерсы попадают в прокси-обертку. Вытаскиваются из не тоРавом
  return (e) => {
    if (e.selected.length > 0) {
      let features = [] //  Масив того что выбрали
      const selectPosition = e.selected[0].getGeometry().getCoordinates() // Позишн
      const layer = Reflect.ownKeys(layers).find(
        (l) => s.getLayer(e.selected[0]) === toRaw(layers[l].vector)
      ) // Слой с объектом
      overlays.car.inLayer = layer
      if (layer === 'one') {
        features = e.selected
        e.selected[0].setStyle(style.cars('green')) //  Назначаем стиль селекту
      } else {
        features = e.selected[0].values_.features
        e.selected[0].values_.features[0].setStyle(e.selected[0].getStyle()) //  Назначаем стиль селекту
      }
      if (features.length === 1) {
        overlays.car.inCluster = false
        overlays.car.popup.setPosition(selectPosition)
        overlays.cluster.popup.setPosition(undefined)
        overlays.car.select = features[0].getId()
      } else {
        overlays.car.inCluster = true
        overlays.cluster.popup.setPosition(selectPosition)
        overlays.car.popup.setPosition(undefined)
        overlays.cluster.select = []
        features.forEach((f) => overlays.cluster.select.push(f.getId()))
      }
    } else {
      //  Ничего не выбрали
      overlays.car.popup.setPosition(undefined)
      overlays.cluster.popup.setPosition(undefined)
    }
  }
}

export const intSelect = {
  init: (layers, overlays, keys = ['cars', 'alarms', 'one', 'history']) => {
    let rawLayers = []
    keys.forEach((key) => {
      rawLayers.push(toRaw(layers[key].vector))
    })
    let s = new Select({
      layers: rawLayers,
    })
    s.on('select', select(s, layers, overlays))
    return s
  },
}

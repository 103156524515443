import { apiCore } from '@/app/deliv'
import Connect from '@/entities/connect'
import { API_ZONE, WS } from '@/shared/config'
import { useStorage } from '@/shared/use/storage'
import { watchEffect } from 'vue'
import { uuid } from 'vue-uuid'
import { CREATE, DELETE, UPDATE } from './action'
import { exportDTO } from './dto/exportDTO'
import { importDTO } from './dto/importDTO'
import AddGeoZones from './ui/AddGeoZones.vue'
import ShowAllGeoZones from './ui/ShowAllGeoZones.vue'
import ZoneCard from './ui/ZoneCard.vue'

const zones = useStorage()

const clear = zones.clear

const get = async () => {
  const { data } = await apiCore.get(API_ZONE.LIST)
  const prepareZones = data.map((zone) => importDTO(zone))
  zones.set(prepareZones)
}
const create = async (zone) => {
  zone.id = null
  zone.uuid = uuid.v4()
  zone.action = CREATE
  await apiCore.post(API_ZONE.NEW, exportDTO(zone))
}

const update = async (zone) => {
  zone.action = UPDATE
  await apiCore.post(API_ZONE.EDIT, exportDTO(zone))
}

const del = async (id) => {
  await apiCore.get(API_ZONE.REMOVE + id.toString())
}

const subscribe = () => {
  Connect.wamp.value.Subscribe(WS.SP + Connect.spID.value, (data) => {
    const prepareZones = data.map((zone) => importDTO(zone))
    zones.set(prepareZones)
    data.forEach((zone) => {
      if (zone.action === DELETE) zones.del(zone.zoneID)
    })
  })
}

const unsubscribe = () => {
  Connect.wamp.value.Unsubscribe(WS.SP + Connect.spID.value)
}

const api = { get, create, update, del, subscribe, unsubscribe }

const showZones = (zone) => {
  zone.id > 0
    ? (zones.get(zone.id).ismap = zone.ismap)
    : Object.keys(zones.storage).forEach((id) => {
        zones.get(id).ismap = zone.ismap
      })
}

watchEffect(() => {})

export { AddGeoZones, api, clear, ShowAllGeoZones, showZones, ZoneCard, zones }

<script setup>
import { ConnectCoordinate, ConnectType, PowerSlider } from '@/shared/ui'
import { ref } from 'vue'

defineProps({ lastPos: { type: Object, required: true } })

const open = ref(true)
</script>

<template>
  <div class="basic_content" :style="`height: ${open ? 270 : 30}px`">
    <div class="basic_content_head" @click="open = !open">
      Последний выход на связь
    </div>
    <div class="in_basic_content basic_top_border">
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">Дата и время:</div>
        <div style="margin-right: 15px; font-weight: 500">
          {{ lastPos.datetime }}
        </div>
      </div>
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">Адрес:</div>
        <div
          style="
            margin-right: 15px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ lastPos.address }}
        </div>
      </div>
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">Координаты:</div>
        <ConnectCoordinate style="margin-right: 15px; font-weight: 500">
          {{ lastPos.rLon }} / {{ lastPos.rLat }}
        </ConnectCoordinate>
      </div>
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">
          Способ определения местоположения:
        </div>
        <div style="margin-right: 15px">
          <ConnectType :connectType="lastPos.connecttype" />
        </div>
      </div>
      <!-- <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">Температура:</div>
        <div style="margin-right: 15px">{{ lastPos.board }} &#176;C</div>
      </div> -->
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">Скорость:</div>
        <div style="margin-right: 15px; font-weight: 500">
          {{ lastPos.speed }} км/ч
        </div>
      </div>
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">
          Количество спутников:
        </div>
        <div style="margin-right: 15px; font-weight: 500">
          {{ lastPos.gps }}
        </div>
      </div>
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">Уровень GSM связи:</div>
        <div style="margin-right: 15px; font-weight: 500">
          {{ lastPos.gsm }}
        </div>
      </div>
      <div class="basic_content_row">
        <div style="flex: 1 1 auto; margin-left: 15px">Заряд:</div>
        <div style="margin-right: 15px">
          <PowerSlider :power="lastPos.powervolume" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

export default [
  {
    id: 1001,
    name: "Режим работы",
    desc: "Режим работы маяка",
    values: ["Раз в 10 минут", "Раз в 1 час", "Раз в 4 часа", "Раз в 24 часа"],
  },
  {
    id: 1002,
    name: "Пропуск GPS сеансов",
    desc: "Пропуск GPS сеансов",
    values: [
      "Не пропускать",
      "Пропустить 1 раз",
      "Пропустить 2 раза",
      "Пропустить 3 раза",
      "Пропустить 4 раза",
      "Пропустить 5 раз",
      "Пропустить 6 раз",
      "Пропустить 7 раз",
    ],
  },
  {
    id: 1003,
    name: "Таймеры LORA",
    desc: "Таймеры LORA",
    values: [
      "Раз в 10 минут",
      "Раз в 20 минут",
      "Раз в 30 минут",
      "Раз в 60 минут",
    ],
  },
];

<template>
  <simple-row
    @save="save()"
    @cancel="setIsAddAction(false)"
    :level="level"
    :select="{ id: item.autoID, parent: item.spID, type: 'automatic' }"
    :name="'Новое действие'"
    :isOpen="false"
    :folderPic="'action-folder'"
    :save="true"
  />
  <div ref="ae" style="width: 100%; border-top: 1px solid #5ce672">
    <div class="ae_box">
      <div class="ae_row">
        <div class="ae_row_left">Тип действия</div>
        <div class="ae_row_right">
          <select class="select" v-model="action.actionType">
            <option v-for="t in actionTypes" :value="t.id" :key="t.id">
              {{ t.label }}
            </option>
          </select>
        </div>
      </div>
      <height-wrapp :h="action.actionType ? height - 62 : 0">
        <height-wrapp :h="action.actionType === 1 ? height - 62 : 0">
          <div class="ae_row">
            <div class="ae_row_left">Период</div>
            <div class="ae_row_right">
              <input type="number" class="input" v-model="action.linkTtl" />
            </div>
          </div>
          <div
            @click="
              T1GoupOpen === 'Add' ? (T1GoupOpen = 'no') : (T1GoupOpen = 'Add')
            "
          >
            <tmp-selector-add :isOpen="T1GoupOpen === 'Add'" />
          </div>
          <div
            @click="
              T1GoupOpen === 'Del' ? (T1GoupOpen = 'no') : (T1GoupOpen = 'Del')
            "
          >
            <tmp-selector-del :isOpen="T1GoupOpen === 'Del'" />
          </div>
        </height-wrapp>
        <!--        <height-wrapp :h="action.actionType === 5 ? height - 62 : 0">-->
        <!--          <div class="ae_row">-->
        <!--            <div class="ae_row_left">Чат ID</div>-->
        <!--            <div class="ae_row_right">-->
        <!--              <input type="text" class="input" v-model="action.param" />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </height-wrapp>-->
        <height-wrapp :h="action.actionType === 8 ? height - 62 : 0">
          <div class="ae_row">
            <div class="ae_row_left">Сервис Провайдер</div>
            <div class="ae_row_right">
              <select class="select" v-model="action.alarmSpID">
                <option
                  v-for="s in allGroups.filter(
                    (g) => !item.actions.find((a) => a.alarmSpID === g.spID)
                  )"
                  :value="s.spID"
                  :key="s.spID"
                >
                  {{ s.name }} {{ s.spID }}
                </option>
              </select>
            </div>
          </div>
        </height-wrapp>
      </height-wrapp>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/groups'
import actionModel from '@/models/action'
import { HeightWrapp } from '@/shared/ui'
import { mapActions, mapGetters } from 'vuex'
import { api } from '../api'
import SimpleRow from './SimpleRow.vue'
import tmpSelectorAdd from './tmpSelectorAdd.vue'
import tmpSelectorDel from './tmpSelectorDel.vue'

export default {
  name: 'ActionEditor',
  components: { SimpleRow, HeightWrapp, tmpSelectorAdd, tmpSelectorDel },
  mixins: [mixin],
  props: {
    item: Object,
    level: Number,
  },
  emits: ['update'],
  data() {
    return {
      action: null,

      rowCountDefault: 2, // Количество строк по умолчанию
      rowHeight: 30, // Высота строк
      rowHeightPlus: 4, // добавка на бордеры
      rowCount: 2, // Всего строк сейчас

      rowT1Goup: 3, // Добавится строк типе действия - изменение состава группы
      rowT5Telegram: 1, // Добавится строк типе действия - телеграм
      rowT8Alarm: 1, // Добавится строк типе действия - создать тревогу
      T1GoupOpen: 'no',

      rowDeltaHeight: 0, // Добавки высоты от встроенных компонентов (+-из группы)

      actionTypes: [
        { id: 1, label: 'Изменить состав группы' },
        // { id: 5, label: "Телеграм уведомление" },
        { id: 8, label: 'Создать тревогу' },
      ],
    }
  },
  created() {
    if (this.item.actions.find((a) => a.actionType === 1))
      this.actionTypes = this.actionTypes.filter((a) => a.id !== 1)
    this.action = JSON.parse(JSON.stringify(actionModel))
    this.rowCount = this.rowCountDefault
    this.setWrappHeight(this.height)
  },
  watch: {
    T1GoupOpen(v) {
      this.resetGroupSelect()
      if (v === 'Add')
        this.action.spsInclude.forEach((a) => this.addGroupSelect(a))
      if (v === 'Del')
        this.action.spsExclude.forEach((a) => this.addGroupSelect(a))
    },
    groupsSelect: {
      handler(v) {
        this.rowDeltaHeight = v.rowCount * 22
        this.setWrappHeight(this.height)
        if (this.T1GoupOpen === 'Add') this.action.spsInclude = [...v.select]
        if (this.T1GoupOpen === 'Del') this.action.spsExclude = [...v.select]
      },
      deep: true,
    },
    action: {
      handler() {
        switch (this.action.actionType) {
          case 1:
            this.rowCount = this.rowCountDefault + this.rowT1Goup
            break
          case 5:
            this.rowCount = this.rowCountDefault + this.rowT5Telegram
            break
          case 8:
            this.rowCount = this.rowCountDefault + this.rowT8Alarm
            break
          default:
            this.rowCount = this.rowCountDefault
            break
        }
        this.setWrappHeight(this.height)
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      allGroups: 'g_mod/GET_GROUPS',
      groupsSelect: 'g_mod/GET_GROUPS_SELECT',
    }),
    height() {
      return (
        this.rowCount * this.rowHeight +
        this.rowHeightPlus +
        this.rowDeltaHeight
      )
    },
    validate() {
      let val = false
      switch (this.action.actionType) {
        case 1:
          // Проверка, что в автоматизации еще нет действия - изменение состава группы
          if (!this.item.actions.find((a) => a.actionType === 1)) val = true
          break
        case 5:
          // Проверка, что заполнен чат ИД и нет действий - телеграм уведомлений с таким же чат ИД
          if (
            this.action.param !== '' &&
            !this.item.actions.find(
              (a) => a.actionType === 5 && a.param === this.action.param
            )
          )
            val = true
          break
        case 8:
          val = true
          break
      }
      return val
    },
  },
  methods: {
    ...mapActions({
      resetGroupSelect: 'g_mod/RESET_GROUPS_SELECTOR',
      addGroupSelect: 'g_mod/GROUPS_SELECTOR',
    }),
    save() {
      if (this.validate) {
        let item = JSON.parse(JSON.stringify(this.item))
        item.actions.push(JSON.parse(JSON.stringify(this.action)))
        api.cudAutomatics(item)
        this.setIsAddAction(false)
      }
    },
  },
}
</script>

<style scoped>
.ae_box {
  /*flex: 1 1 auto;*/
  margin-left: 10px;
  background: #ffffff;
  border-left: 1px solid #5ce672;
  border-right: 1px solid #5ce672;
  border-bottom: 1px solid #5ce672;
  border-radius: 0 0 4px 4px;
  width: 360px;
  overflow: hidden;
  /*display: flex;*/
}
.ae_row {
  padding: 0 6px 0 6px;
  flex: 0 0 auto;
  height: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.ae_row_left {
  padding: 0 6px 0 6px;
}
.ae_row_right {
  padding: 0 6px 0 6px;
}
.select {
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  padding: 2px 12px 2px 12px;
  width: 220px;
  /*font-weight: 400;*/
  /*font-size: 12px;*/
}
.input {
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  padding: 2px 12px 2px 12px;
  width: 220px;
}
</style>

import { apiTelegram } from '@/app/deliv'
import { router } from '@/app/providers'
import { api as apiCars } from '@/entities/cars'
import { FLEET_DEBUG } from '@/shared/config'
import {
  addConfig2Cars,
  carDataPrepare,
  lastPosPrepare2,
} from '@/shared/lib/helpers'

const nammer = (c, names) => {
  let name = 'sn'
  for (let i = 0; i < names.length; i++) {
    if (names[i] in c && c[names[i]] !== '') {
      name = names[i]
      break
    }
  }
  return c[name].toString()
}

export const carsModule = {
  state: () => ({
    cars: [],
    names: ['gos', 'sn'],
  }),
  getters: {
    GET_CAR: (state) => (id) => {
      return [...state.cars].find((c) => c.id === id)
    },
    ALL_CARS(state) {
      return state.cars
    },
    GET_NAMES(state) {
      return state.names
    },
  },
  mutations: {
    SET_CARS(state, cars) {
      state.cars = cars
    },
    SET_NAMES(state, names) {
      state.names = names
    },
  },
  actions: {
    SET_NAMES: ({ commit, state }, carNames) => {
      commit('SET_NAMES', carNames)

      const cars = [...state.cars]
      cars.forEach((c) => {
        c.name = nammer(c, carNames)
      })
      commit('SET_CARS', cars)
    },
    SET_CARS: ({ commit, state }, payload) => {
      if (FLEET_DEBUG) console.log('SET_CARS', payload)
      if (FLEET_DEBUG) console.time('no pini SET_CARS', payload)
      let cars = []
      payload.forEach((i) => {
        let car = Object.assign(carDataPrepare(i), lastPosPrepare2(i.lastPos), {
          address: '',
          ismap: true,
          config: addConfig2Cars(i.objID),
        })
        car.name = nammer(car, state.names)
        cars.push(car)
      })
      commit('SET_CARS', cars)
      if (FLEET_DEBUG) console.timeEnd('no pini SET_CARS', payload)
    },
    CLEAR: ({ commit }) => {
      commit('SET_CARS', [])
    },
    UPDATE_CARS: ({ state, commit }, payload) => {
      if (FLEET_DEBUG) console.log('UPDATE_CARS', payload)
      if (FLEET_DEBUG) console.time('UPDATE_CARS', payload)
      let cars = JSON.parse(JSON.stringify(state.cars))
      payload.forEach((car) => {
        let isContains = false
        if (car.actions === 0) {
          cars = cars.filter((c) => c.id !== car.objID)
        } else {
          cars.forEach((i) => {
            if (i.id === car.objID) {
              car.lastPos.unixGmt > i.datetime
                ? Object.assign(
                    i,
                    carDataPrepare(car),
                    lastPosPrepare2(car.lastPos)
                  )
                : Object.assign(i, carDataPrepare(car))
              car.name = car.name = nammer(car, state.names)
              isContains = true
            }
          })
          if (!isContains) {
            const newCar = Object.assign(
              carDataPrepare(car),
              lastPosPrepare2(car.lastPos),
              {
                address: '',
                ismap: true,
                config: {},
              }
            )
            newCar.name = nammer(newCar, state.names)
            cars.push(newCar)
          }
        }
      })

      commit('SET_CARS', cars)
      if (FLEET_DEBUG) console.timeEnd('UPDATE_CARS', payload)
    },
    UPDATE_CARS_POSITION: ({ state, commit, dispatch }, payload) => {
      // Получили обновленные данные по позициям объектов. Обновляем позиции
      if (FLEET_DEBUG) console.log('UPDATE_CARS_POSITION', payload)
      if (FLEET_DEBUG) console.time('UPDATE_CARS_POSITION', payload)
      let cars = [...state.cars]

      payload.forEach((i) => {
        let old_car = cars.find((j) => j.id === i.objID)
        if (old_car) {
          Object.assign(old_car, lastPosPrepare2(i))
        } else {
          // Пришла позиция по объекту которого у нас нет
        }
      })

      commit('SET_CARS', cars)
      dispatch('sfs_mod/CALC_INTERSECTS', null, { root: true })
      if (FLEET_DEBUG) console.timeEnd('UPDATE_CARS_POSITION', payload)
    },

    // --------------------------
    EDIT_CAR: ({ state, commit }, car) => {
      let cars = JSON.parse(JSON.stringify(state.cars))
      cars.map((i) => {
        if (i.id === car.id) {
          i.model = car.model
          i.name = car.name
          i.sn = car.sn
          i.color = car.color
          i.year = car.year
          i.gos = car.gos
          i.vin = car.vin
        }
      })
      commit('SET_CARS', cars)
      // window.localStorage.setItem("cars", JSON.stringify(cars));
    },
    // FLEET_CONFIG: async ({ state }, payload) => {
    //   const token = localStorage.getItem("token");
    //   let sn = JSON.parse(JSON.stringify(state.cars)).find(
    //     (i) => i.id === payload.id
    //   ).sn;
    //   axios.defaults.headers.common["X-Token"] = token;
    //   axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    //   axios.defaults.headers.common["Content-Type"] = "application/json";
    //   let msgText =
    //     "\nРежим выхода на связь: " +
    //     payload.config[1001] +
    //     "\nПропуск GPS сеансов: " +
    //     payload.config[1002] +
    //     "\nТаймеры LORA: " +
    //     payload.config[1003];
    //   let msg =
    //     "Пользователь: " +
    //     jwt_decode(token).name +
    //     "\nНастроить маяк SN: " +
    //     sn +
    //     msgText +
    //     "\nВремя конфигурации: " +
    //     new Date();
    //   // console.log("fuu", msg);
    //   let res = await axios.post("http://fleet.myavo.ru/fleet/conf", {
    //     msg: msg,
    //   });
    //   let res2 = "push to config: " + msg;
    //   console.log(res2, res);
    // },
    GET_HISTORY: async ({ state, dispatch }, fromTill) => {
      let sn = router.currentRoute.value.params.id //rootGetters["CAR_BOX"].car_id;
      let cars = []
      let car = JSON.parse(JSON.stringify(state.cars)).find((i) => i.sn === sn)
      let data = await apiCars.getHistory(car, fromTill)
      let data2 = data.data
      data2.sort((b, a) => (a.unixGmt > b.unixGmt ? 1 : -1))
      data2.forEach((i) => {
        car.isMap = true
        car.lastPos = i
        lastPosPrepare2(i)
        cars.push(JSON.parse(JSON.stringify(car)))
      })
      dispatch('carsHistory_mod/DEL_CARS_HISTORY', null, {
        root: true,
      })
      dispatch('carsHistory_mod/SET_CARS_HISTORY', cars.reverse(), {
        root: true,
      })
      // console.log("cars payload", cars);
    },
    SHOW_CAR: ({ state, commit }, payload) => {
      let cars = [...state.cars]
      if (payload.id.length > 0) {
        cars.map((i) => {
          if (payload.id.includes(i.id)) {
            if (payload.ismap === 'invert') {
              i.ismap = !i.ismap
            } else {
              i.ismap = payload.ismap
            }
          }
        })
      } else {
        cars.map((i) => (i.ismap = payload.ismap))
      }

      commit('SET_CARS', cars)
      // window.localStorage.setItem("cars", JSON.stringify(cars));
    },
    SET_CUSTOM: ({ state, commit }, payload) => {
      let cars = [...state.cars]
      cars.map((i) => {
        if (i.id === payload.id) {
          i.custom[payload.key] = payload.val
        }
      })
      commit('SET_CARS', cars)
      // window.localStorage.setItem("cars", JSON.stringify(cars));
    },
    SET_CONFIG: ({ state, commit }, payload) => {
      let cars = [...state.cars]
      let carsSetting = []
      cars.forEach((i) => {
        if (i.id === payload.id) {
          i.config = payload.config
        }
        carsSetting.push({ id: i.id, config: i.config })
      })
      commit('SET_CARS', cars)
      window.localStorage.setItem('cars_setting', JSON.stringify(carsSetting))
      // dispatch("FLEET_CONFIG", payload);
      apiTelegram(payload)
    },
    EDIT_CAR_FIELD: ({ state, commit }, payload) => {
      let cars = [...state.cars]
      cars.map((i) => {
        if (i.id === payload.id) {
          i[payload.key] = payload.val
        }
      })
      commit('SET_CARS', cars)
      // window.localStorage.setItem("cars", JSON.stringify(cars));
    },
  },
  namespaced: true,
}

<template>
  <div class="main_chk">
    <div
      :class="[
        'box',
        { on: mode === 'on' },
        { off: mode === 'off' },
        { all: mode === 'all' },
        { disable: mode === 'disable' },
      ]"
      @click="mode !== 'disable' ? $emit('change') : ''"
    >
      <div v-if="mode === 'on' || mode === 'all'" class="selected"></div>
    </div>
    <div
      class="label"
      :style="{
        fontSize: fontSize + 'px',
        fontWeight: fontWeight,
        color: fontColor,
      }"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    mode: String,
    label: {
      type: String,
      default: '',
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    fontWeight: {
      type: Number,
      default: 400,
    },
    fontColor: {
      type: String,
      default: '#374C70',
    },
  },
  emits: ['change'],
}
</script>

<style scoped>
.main_chk {
  position: relative;
}
.box {
  position: absolute;
  width: 14px;
  height: 14px;
  border: 1px solid #9dadc8;

  box-sizing: border-box;
  border-radius: 2px;
}
.on {
  background: #1e5486;
}
.off {
}
.all {
  background: #c4c4c4;
}
.disable {
  background: #c4c4c4;
}
.label {
  padding-left: 20px;
  line-height: 15px;
  /*color: #374C70;*/
}
.selected {
  position: absolute;
  top: 1px;
  left: 4px;
  width: 4px;
  height: 9px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.box:hover {
  border: 1px solid #1d1d1d;
  /*box-shadow: 0 0 1px 1px #596c8f;*/
}
</style>

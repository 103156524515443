<template>
  <div class="coordinates">
    <div>
      <img :src="require('@/assets/images/icons/carcard/marker.png')" />
    </div>
    <div class="coordinates_text">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.coordinates {
  flex: 0 0 auto;
  font-weight: 400;
  /* font-size: 10px; */
  line-height: 11px;
  color: #000000;
  text-align: end;
  display: flex;
}
.coordinates_text {
  padding-left: 3px;
  padding-top: 2px;
}
</style>

<template>
  <div
    ref="menuWrapp"
    class="menu_wrapp"
    :style="{
      height: s(heightMenuWrapp),
      top: s(menuWrapper.y),
      left: s(menuWrapper.x),
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "menuWrapper",
  props: {
    h: Number,
    menuWrapper: Object,
  },
  emits: ["close"],
  data() {
    return {
      startCheck: false,
    };
  },
  created() {
    setTimeout(() => {
      this.startCheck = true;
    }, 0);
    window.addEventListener("click", (e) => {
      this.clickListner(e);
    });
  },
  beforeUnmount() {
    window.removeEventListener("click", (e) => {
      this.clickListner(e);
    });
  },
  computed: {
    heightMenuWrapp() {
      if (this.menuWrapper.enable && this.startCheck) {
        return this.h;
      }
      return 0;
    },
  },
  methods: {
    s(p) {
      return p.toString() + "px";
    },
    clickListner(e) {
      if (this.$refs.menuWrapp !== undefined && this.$refs.menuWrapp !== null) {
        if (!this.$refs.menuWrapp.contains(e.target)) {
          this.$emit("close");
        }
      }
    },
  },
};
</script>

<style scoped>
.menu_wrapp {
  z-index: 3005;
  /*width: 100px;*/
  overflow-y: hidden;
  position: absolute;
  transition: height 100ms;
  background: #ffffff;
  box-shadow: 0 4px 6px 1px rgba(48, 66, 98, 0.24);
  border-radius: 4px;
}
</style>

import { local } from '@/app/deliv'
import Connect from '@/entities/connect'
import { wsConfig as config, DEBUG } from '@/shared/config'
import { useRulesStore } from '@/stores/rules'
import autobahn from 'autobahn'
import { setter } from '../app'

class Wamp {
  install(app) {
    app.provide('wamp', this)
  }
  constructor() {
    this.subscribtions = {}
  }
  onchallenge(session, method, extra) {
    if (method === 'wampcra') {
      let keyToUse = this.password
      if (typeof extra.salt !== 'undefined') {
        keyToUse = autobahn.auth_cra.derive_key(this.password, extra.salt)
      }
      return autobahn.auth_cra.sign(keyToUse, extra.challenge)
    } else if (method === 'token') {
      return this.password
    } else {
      throw "don't know how to authenticate using '" + method + "'"
    }
  }
  onopen(session, details) {
    Connect.connected(session, details)

    /* eslint-disable */
    for (let i in this.subscribtions)
      if (this.subscribtions.hasOwnProperty(i)) {
        let subs = this.subscribtions[i]
        this.connection.session.subscribe(i, subs.callback).then(
          function (subscription) {
            if (DEBUG)
              console.log(
                '[CONN] Удачная подписка на события ' +
                  i +
                  ' с ID ' +
                  subscription.id
              )
            subs.subscription = subscription
          },
          function (error) {
            console.error(
              '[CONN] Ошибка подписки на  ' + i + ' с ошибкой ',
              error
            )
          }
        )
      }
    Connect.setWamp(this)
    useRulesStore().setBitMap(details)
    if (this.remember) {
      local.set('fleet_login', this.login)
      local.set('fleet_pass', this.password)
    }
    setter.preSet()
  }
  onclose(reason, details) {
    if (
      details.reason === 'wamp.close.normal' ||
      details.reason === 'wamp.error.second_login' ||
      details.reason === 'wamp.error.authentication_failed' ||
      details.reason === 'wamp.error.authorization_failed'
    ) {
    } else {
      Connect.disconnected(reason, details)
      if (!details.will_retry) {
        this.connection.open()
      }
    }
  }
  close() {
    if (this.connection) {
      this.connection.close()
    }
  }
  async open(login, password, authmethod, appversion, remember) {
    if (this.connection && this.connection.isConnected) {
      return { opened: true, error: 'Соединение уже установлено' }
    }
    this.password = password
    this.login = login
    this.connection = new autobahn.Connection({
      debug: true,
      url: config.url,
      realm: config.realm,
      authmethods: [authmethod], //"wampcra"
      authid: login,
      onchallenge: this.onchallenge.bind(this),
      max_retries: 1000,
      max_retry_delay: 300,
      retry_delay_growth: 1.5,
      protocols: ['wamp.2.json'],
      authextra: {
        UserAgent: 'Fleet ' + appversion,
      },
    })
    this.connection.onopen = this.onopen.bind(this)
    this.connection.onclose = this.onclose.bind(this)
    this.remember = remember
    this.connection.open()
    return { opened: true, error: '' }
  }

  Unsubscribe(uri) {
    if (DEBUG) console.log('[wamp] -> unsubscribe', uri)

    return new Promise((resolve, reject) => {
      if (this.subscribtions.hasOwnProperty(uri)) {
        let sub = this.subscribtions[uri]
        if (DEBUG) console.log('[CONN] TRY UNSUB ', sub)

        this.connection.session.unsubscribe(sub.subscription).then(
          function (subscribtion) {
            if (DEBUG) console.log('[CONN] Удачная отписка на события ' + uri)
            resolve()
          },
          function (error) {
            if (DEBUG)
              console.error(
                '[CONN] Ошибка отписки на  ' + uri + ' с ошибкой ',
                error
              )
            reject(error)
          }
        )
        delete this.subscribtions[uri]
      } else {
        reject()
      }
    })
  }
  Subscribe(uri, callback) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-prototype-builtins
      if (this.subscribtions.hasOwnProperty(uri)) {
        resolve(this.subscribtions[uri])
      } else {
        let subs = {
          subscription: null,
          callback: callback,
        }
        this.subscribtions[uri] = subs
        if (Connect.isLogin.value) {
          // if (this.connectionStore.IsLoggedIn) {
          this.connection.session.subscribe(uri, callback).then(
            function (subscription) {
              if (DEBUG)
                console.log(
                  '[CONN] Удачная подписка на события ' +
                    uri +
                    ' с ID ' +
                    subscription.id
                )
              subs.subscription = subscription
              resolve(subs)
            },
            function (error) {
              console.error(
                '[CONN] Ошибка подписки на  ' + uri + ' с ошибкой ',
                error
              )
              reject()
            }
          )
        }
      }
    })
    // }
  }
  doCall(uri, data) {
    return new Promise((resolve, reject) => {
      if (!Connect.isLogin.value) {
        if (DEBUG) console.log('Нет подключения, невозможно выполнить запрос')
      } else
        this.connection.session.call(uri, data, {}, {}).then(
          function (data) {
            var count = 0
            if (data instanceof Array) {
              count = data.length
            }
            if (DEBUG)
              console.log(
                '[CONN] Обработан запрос: ' +
                  uri +
                  ' Запрос вернул: ' +
                  count +
                  ' записей'
              )
            if (data === false) {
              reject(false)
            } else resolve(data)
          },
          function (error) {
            if (DEBUG)
              console.log(
                '[CONN] Ошибка выполнения запроса: ' + uri + ' : ',
                error
              )
            reject(error)
          }
        )
      if (DEBUG) console.log('[CONN] Выполнение запроса ' + uri)
    })
  }
}

export const wamp = new Wamp()

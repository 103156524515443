import { getMulti } from '@/shared/api/geoCoder'

export const getCarsAddress = async (cars) =>
  await getMulti(
    cars.reduce((res, i) => {
      res[i.objID] = [i.lastPos.rLat, i.lastPos.rLon]
      return res
    }, {})
  )

export const getLastPositionAddress = async (lastPos) =>
  await getMulti(
    lastPos.reduce((res, i) => {
      res[i.objID] = [i.rLat, i.rLon]
      return res
    }, {})
  )

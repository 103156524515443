import { BASE_API_URL } from '@/shared/config'
import Connect from '@/entities/connect'
import { useErrorStore } from '@/stores/errorStore'
import axios from 'axios'

const api = axios.create({
  headers: { 'Access-Control-Allow-Origin': '*' },
})

api.interceptors.request.use((requestConfig) => {
  requestConfig.headers.common['X-Token'] = Connect.token.value
  return requestConfig
})

api.interceptors.response.use(
  function (response) {
    return response
  },
  async (error) => {
    // console.log("error.config", error.config);

    let msg = 'что-то пошло не так'
    if (
      error.response.data &&
      error.response.data.res === 'ERR' &&
      error.response.data.msg !== ''
    ) {
      msg = error.response.data.msg
    } else if (error.response.data && typeof error.response.data === 'string') {
      msg = error.response.data
    }
    useErrorStore().setError(msg)

    // return api.request(error.config);
  }
)

export const apiCore = {
  get(path, params = {}) {
    return api.get(BASE_API_URL + path, params)
  },

  post(path, params = {}) {
    return api.post(BASE_API_URL + path, params)
  },
}

import Feature from 'ol/Feature'
import Circle from 'ol/geom/Circle'
import Polygon from 'ol/geom/Polygon'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { style } from './style'

const fGeometry = (z, zoneStyle) => {
  let geo = null
  switch (z.type) {
    case 'Polygon':
      geo = new Polygon([z.coordinates])
      break
    case 'Circle':
      geo = new Circle(z.center, z.radius)
      break
  }
  const feature = new Feature(geo)
  feature.setStyle(zoneStyle)
  feature.setId(z.id)
  return feature
}

export const layerSimple = {
  //  Инит
  init: () => {
    let layer = {}
    layer.source = new VectorSource()
    layer.vector = new VectorLayer({ source: layer.source })
    return layer
  },

  buildLayer: (layer, zones, editKey = false) => {
    layer.source.clear()
    let features = []
    zones.forEach((z) => {
      if (editKey || z.ismap)
        features.push(fGeometry(z, style.zones(z, editKey)))
    })
    layer.source.addFeatures(features)
  },
}

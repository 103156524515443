import { Tile as TileLayer } from 'ol/layer'
import Map from 'ol/Map'
import { transform } from 'ol/proj'
import { OSM } from 'ol/source'
import View from 'ol/View'

const IN_PRO = 'EPSG:4326'
const OUT_PRO = 'EPSG:3857'

export const layerMap = {
  //  Инит
  init: (center) => {
    const raster = new TileLayer({
      source: new OSM(),
    })
    const viewer = new View({
      center: transform(center, IN_PRO, OUT_PRO),
      zoom: 10,
      projection: OUT_PRO,
    })
    return new Map({
      layers: [raster],
      target: 'map',
      view: viewer,
    })
  },

  //  Фитнес для слоя
  fit: (map, layer, id = null) => {
    if (id) {
      if (layer.source.getFeatureById(id)) {
        let ext = layer.source.getFeatureById(id).getGeometry().getExtent()
        map.getView().fit(ext, {
          size: map.getSize(),
          padding: [50, 50, 50, 50],
          duration: 1000,
          maxZoom: 18,
        })
      }
    } else if (layer.vector.getSource().getExtent()[0] !== Infinity) {
      map.getView().fit(layer.vector.getSource().getExtent(), {
        size: map.getSize(),
        padding: [50, 50, 50, 50],
        duration: 1000,
        maxZoom: 18,
      })
    }
  },
}

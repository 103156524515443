export const reportsRoute = {
  path: 'reports',
  name: 'reports',
  redirect: { name: 'all_report' },
  components: {
    reports: () => import('./ReportsPage.vue'),
  },
  children: [
    {
      path: 'all_report',
      name: 'all_report',
      components: {
        allReports: () => import('./ReportsAll.vue'),
      },
    },
    {
      path: 'report_builder',
      name: 'report_builder',
      components: {
        reportBuilder: () => import('./ReportsBuilder.vue'),
      },
    },
  ],
}

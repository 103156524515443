<template>
  <!--  <div class="collaps_add_content no_selected_btn">-->
  <div class="plus no_selected_btn">
    <div :class="[{ x: true }, { rotated: open }, { norotated: !open }]"></div>
    <div :class="[{ y: true }, { rotated: open }, { norotated: !open }]"></div>
  </div>
  <!--  </div>-->
</template>

<script>
export default {
  name: "PlusRotate",
  props: {
    open: Boolean,
  },
};
</script>

<style scoped>
.collaps_add_content {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #0288d1;
}
.plus {
  position: relative;
  width: 18px;
  height: 18px;
  background: #1e81ce;
  border-radius: 2px;
  border: 1px solid #1e81ce;
}
.plus:hover {
  border: 1px solid #0b5e9f;
}
.plus:active {
  background: #0b5e9f;
  border: 1px solid #0b5e9f;
}
.x {
  position: absolute;
  width: 10px;
  height: 2px;
  left: 3px;
  top: 7px;
  background: #ffffff;
  border-radius: 2px;
}
.y {
  position: absolute;
  width: 2px;
  height: 10px;
  left: 7px;
  top: 3px;
  background: #ffffff;
  border-radius: 2px;
}
.rotated {
  transform: rotate(45deg);
  transition: transform 0.5s;
}
.norotated {
  transform: rotate(0deg);
  transition: transform 0.5s;
}
</style>

<template>
  <router-view :class="{ wait: c.cursorWait }" />
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, provide, ref } from "vue";
import { useCarsStore } from "@/stores/carsStore";

const c = useCarsStore();

//  Храним в переменной clientHeight актуальное значение высоты окна браузера
const clientHeight = ref(document.documentElement.clientHeight);

const clickInstances = new Map();

const regClicker = (el, clickHandler) => {
  clickInstances.set(el, clickHandler);
};

const unRegClicker = (el) => {
  clickInstances.delete(el);
};

const clicker = (target) => {
  for (let k of clickInstances.keys()) {
    if (k?.value && !k.value.contains(target)) clickInstances.get(k)();
  }
};

onMounted(() => {
  window.addEventListener("click", (e) => {
    clicker(e.target);
  });
  window.addEventListener("resize", () => {
    clientHeight.value = document.documentElement.clientHeight;
  });
});
onBeforeUnmount(() => {
  window.removeEventListener("click", () => {
    clicker();
  });
  window.removeEventListener("resize", () => {
    clientHeight.value = document.documentElement.clientHeight;
  });
});

//  Для полученного элемена возвращаем его максимальную высоту (высота окна - топ элемента - 27 )))
const maxHeight = computed(() => {
  return (el, open = true) => {
    if (el && el[0] && open)
      return (
        (
          clientHeight.value -
          el[0].getBoundingClientRect().top -
          27
        ).toString() + "px"
      );
    return "0px";
  };
});

provide("clientWindow", { maxHeight });
provide("regClicker", { regClicker, unRegClicker });
</script>

<style lang="scss">
@import "@/assets/fonts/Gordita/stylesheet.css";
@import "src/assets/scss/style.scss";
/*Обнуление*/
* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  /*font-family: "Times New Roman", Times, serif;*/
  font-family: "Gordita";
  /*, "-apple-system", "Helvetica Neue", Helvetica, Arial, sans-serif;*/
}
input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

/*--------------------*/

.wait {
  cursor: wait;
}
</style>

<template>
  <div
    @click.stop="hidder()"
    :class="[{ err: true }, { err_on: isShow }, { err_off: !isShow }]"
  >
    {{ props.err }}
  </div>
</template>

<script setup>
import { ref } from "vue";
import { watchEffect, defineProps, defineEmits } from "vue";

const props = defineProps({
  err: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["setErr"]);

const isShow = ref(false);
const hidder = () => {
  isShow.value = false;
  setTimeout(() => {
    emit("setErr", "");
  }, 200);
};
const changer = (e) => {
  if (e !== "") {
    isShow.value = true;
    setTimeout(() => {
      hidder();
    }, 3500);
  }
};

watchEffect(() => changer(props.err));
</script>

<style scoped>
.err {
  position: absolute;
  height: 30px;
  right: 0;
  z-index: 1000;
  background: #ff8080;
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition-property: all;
  transition-duration: 0.2s;
  border-radius: 10px 0 0 0;
}
.err_on {
  bottom: 0;
}
.err_off {
  bottom: -30px;
}
</style>

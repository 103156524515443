import carSearchOptions from '@/models/carSearchOptions'
import carSortOptions from '@/models/carSortOptions'

const defaultState = () => {
  return {
    searchQuery: '',
    searchOptions: [...carSearchOptions],

    filterGeoZones: {
      name: 'В геозоне',
      zones: [],
      enable: false,
    },
    filterDayOffline: {
      name: 'Не на связи',
      dayCount: 1,
      enable: false,
    },
    filterWorkMode: {
      name: 'Режим работы',
      modes: [
        { id: 1, name: 'Раз в 10 минут', enable: false },
        { id: 2, name: 'Раз в час', enable: false },
        { id: 3, name: 'Раз в 4 чвас', enable: false },
        { id: 4, name: 'Раз в 24 часа', enable: false },
      ],
      enable: false,
    },
    filterPowerVolume: {
      name: 'Уровень заряда',
      volumes: [
        { id: 1, name: 'низкий', enable: false },
        { id: 2, name: 'средний', enable: false },
        { id: 3, name: 'высокий', enable: false },
      ],
      enable: false,
    },

    selectedSort: 'datetime',
    sortUp: true,
    sortOptions: [...carSortOptions],

    intersectsByZone: [],
    intersectsByCar: [],
  }
}

export const sfsModule = {
  state: () => ({
    searchQuery: '',
    searchOptions: [...carSearchOptions],

    filterGeoZones: {
      name: 'В геозоне',
      zones: [],
      enable: false,
    },
    filterDayOffline: {
      name: 'Не на связи',
      dayCount: 1,
      enable: false,
    },
    filterWorkMode: {
      name: 'Режим работы',
      modes: [
        { id: 1, name: 'Раз в 10 минут', enable: false },
        { id: 2, name: 'Раз в час', enable: false },
        { id: 3, name: 'Раз в 4 чвас', enable: false },
        { id: 4, name: 'Раз в 24 часа', enable: false },
      ],
      enable: false,
    },
    filterPowerVolume: {
      name: 'Уровень заряда',
      volumes: [
        { id: 1, name: 'низкий < 40%', enable: false },
        { id: 2, name: 'средний 40% ... 70%', enable: false },
        { id: 3, name: 'высокий > 70%', enable: false },
      ],
      enable: false,
    },

    selectedSort: 'datetime',
    sortUp: true,
    sortOptions: [...carSortOptions],

    intersectsByZone: [],
    intersectsByCar: [],
  }),
  getters: {
    FILTER_GEOZONES(state) {
      return state.filterGeoZones
    },
    FILTER_POWER_VOLUME(state) {
      return state.filterPowerVolume
    },
    FILTER_DAY_OFFLINE(state) {
      return state.filterDayOffline
    },
    FILTER_WORK_MODE(state) {
      return state.filterWorkMode
    },

    SEARCHED_CARS(state, getters, rootState, rootGetters) {
      let searchedCar = []
      let allCars = rootGetters['cars_mod/ALL_CARS']
      if (state.searchOptions.filter((i) => i.enable).length === 0) {
        return allCars
      }
      state.searchOptions.forEach((v) => {
        if (v.enable === true) {
          let sCar = allCars.filter((car) => {
            return car[v.id]
              .toLowerCase()
              .includes(state.searchQuery.toLowerCase())
          })
          searchedCar = [...searchedCar, ...sCar]
        }
      })
      searchedCar = [...new Set(searchedCar)]
      return searchedCar
    },
    SEARCHED_AND_FILTER_L1_CARS(state, getters) {
      //  Фильтр по Оффлайн дням
      let searchedCarFilterL1 = [...getters.SEARCHED_CARS]
      if (state.filterDayOffline.enable) {
        let now = Date.now()
        const oneDay = 1000 * 60 * 60 * 24
        searchedCarFilterL1 = searchedCarFilterL1.filter((c) => {
          return (
            now - parseInt(c.datetime, 10) * 1000 >
            oneDay * state.filterDayOffline.dayCount
          )
        })
      }
      return searchedCarFilterL1
    },
    SEARCHED_AND_FILTER_L2_CARS(state, getters) {
      // SEARCHED_AND_FILTER_L2_CARS(state, getters, rootState, rootGetters) {
      //  Фильтр по Режиму работы
      // let custom = rootGetters["cf_mod/ALL_CUSTOM_FIELDS"].find(
      //   (j) => j.id === 1001
      // ).values;
      // let searchedCarFilterL2 = [];
      // let filterId = [];
      // let objIds = [];
      // getters.FILTER_WORK_MODE.modes
      //   .filter((i) => i.enable)
      //   .forEach((i) => {
      //     filterId.push(i.id);
      //   });
      // if (filterId.length > 0) {
      //   custom = custom.filter((i) => filterId.includes(i.value));
      //   custom.forEach((i) => {
      //     objIds.push(i.objId);
      //   });
      //   objIds = [...new Set(objIds)];
      //   searchedCarFilterL2 = [...getters.SEARCHED_AND_FILTER_L1_CARS].filter(
      //     (i) => objIds.includes(i.id)
      //   );
      // } else {
      //   searchedCarFilterL2 = [...getters.SEARCHED_AND_FILTER_L1_CARS];
      // }
      // return searchedCarFilterL2;
      return [...getters.SEARCHED_AND_FILTER_L1_CARS]
    },
    SEARCHED_AND_FILTER_L3_CARS(state, getters) {
      //  Фильтр по Уровню заряда
      let searchedCarFilterL3 = []
      if (
        getters.FILTER_POWER_VOLUME.volumes.filter((i) => i.enable).length > 0
      ) {
        // TODO: что за дичь? убрать ифы лишние
        getters.FILTER_POWER_VOLUME.volumes.forEach((i) => {
          if (i.enable) {
            switch (i.id) {
              case 1:
                ;[...getters.SEARCHED_AND_FILTER_L2_CARS]
                  .filter((j) => j.powervolume < 40)
                  .forEach((j) => searchedCarFilterL3.push(j))
                break
              case 2:
                ;[...getters.SEARCHED_AND_FILTER_L2_CARS]
                  .filter((j) => j.powervolume >= 40 && j.powervolume <= 70)
                  .forEach((j) => searchedCarFilterL3.push(j))
                break
              case 3:
                ;[...getters.SEARCHED_AND_FILTER_L2_CARS]
                  .filter((j) => j.powervolume > 70)
                  .forEach((j) => searchedCarFilterL3.push(j))
                break
            }
          }
        })
      } else {
        searchedCarFilterL3 = [...getters.SEARCHED_AND_FILTER_L2_CARS]
      }
      return searchedCarFilterL3
    },
    SEARCHED_AND_FILTER_L4_CARS(state, getters) {
      //  Фильтр по Геозонам
      let searchedCarFilterL4 = [...getters.SEARCHED_AND_FILTER_L3_CARS]
      let GeozoneCarId = []
      let isEnable = false
      getters.FILTER_GEOZONES.zones.forEach((z) => {
        if (z.enable) {
          isEnable = true
          if (state.intersectsByZone.find((iz) => iz.zoneId === z.id)) {
            GeozoneCarId = [
              ...GeozoneCarId,
              ...state.intersectsByZone.find((iz) => iz.zoneId === z.id)
                .carsInZone,
            ]
          }
        }
      })
      if (isEnable) {
        GeozoneCarId = [...new Set(GeozoneCarId)]
        searchedCarFilterL4 = searchedCarFilterL4.filter((i) => {
          return GeozoneCarId.includes(i.id)
        })
      }
      return searchedCarFilterL4
    },
    SORTED_AND_SEARCHED_AND_FILTERED_CARS(state, getters) {
      if (state.sortUp) {
        return getters.SEARCHED_AND_FILTER_L4_CARS.sort((b, a) =>
          a[state.selectedSort] > b[state.selectedSort] ? 1 : -1
        )
      }
      return getters.SEARCHED_AND_FILTER_L4_CARS.sort((a, b) =>
        a[state.selectedSort] > b[state.selectedSort] ? 1 : -1
      )
    },
  },
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_INTERSECTS_BY_ZONE(state, intersects) {
      state.intersectsByZone = intersects
    },
    SET_INTERSECTS_BY_CAR(state, intersects) {
      state.intersectsByCar = intersects
    },

    SET_SELECTED_SORT(state, selectedSort) {
      state.selectedSort = selectedSort
    },
    SET_SORT_UP(state, sortUp) {
      state.sortUp = sortUp
    },

    SET_SEARCH_OPTIONS(state, searchOptions) {
      state.searchOptions = searchOptions
    },
    SET_SEARCH_QUERY(state, searchQuery) {
      state.searchQuery = searchQuery
    },

    SET_FILTER_OPTIONS(state, filterOptions) {
      state.filterOptions = filterOptions
    },
    SET_FILTER_GEOZONES(state, zones) {
      state.filterGeoZones.zones = zones
    },
    SET_FILTER_WORK_MODE(state, modes) {
      state.filterWorkMode.modes = modes
    },
    SET_FILTER_POWER_VOLUME(state, volumes) {
      state.filterPowerVolume.volumes = volumes
    },
    SET_FILTER_DAY_OFFLINE(state, day) {
      state.filterDayOffline.dayCount = day.dayCount
      state.filterDayOffline.enable = day.enable
    },
  },
  actions: {
    CLEAR: ({ commit }) => {
      commit('RESET_STATE')
    },
    CALC_INTERSECTS: ({ commit, rootGetters }) => {
      // !!!!!!
      // const cars = rootGetters["cars_mod/ALL_CARS"];
      // // const zones = rootGetters["zones_mod/ZONES"];
      // let intersectsByZones = [];
      // // let intersectsByCars = [];
      // zones.value.forEach((zone) => {
      //   let geo = null;
      //   switch (zone.type) {
      //     case "Circle":
      //       geo = new Circle(zone.center, zone.radius);
      //       break;
      //     case "Polygon":
      //       geo = new Polygon([zone.coordinates]);
      //       break;
      //   }
      //   let carsInZone = [];
      //   cars.forEach((car) => {
      //     if (
      //       geo.intersectsCoordinate(
      //         transform([car.lon, car.lat], "EPSG:4326", "EPSG:3857")
      //       )
      //     ) {
      //       carsInZone.push(car.id);
      //     }
      //   });
      //   intersectsByZones.push({ zoneId: zone.id, carsInZone: carsInZone });
      // });
      // commit("SET_INTERSECTS_BY_ZONE", intersectsByZones);
    },

    SET_SEARCH_OPTIONS: ({ state, commit }, optId) => {
      let options = [...state.searchOptions]
      if (optId === 0) {
        if (options.filter((i) => i.enable).length === options.length) {
          options.map((i) => {
            i.enable = false
          })
        } else {
          options.map((i) => {
            i.enable = true
          })
        }
      } else {
        options.map((i) => {
          if (i.id === optId) {
            i.enable = !i.enable
          }
        })
      }
      commit('SET_SEARCH_OPTIONS', options)
    },
    FETCH_FILTER_GEOZONES: ({ state, commit, dispatch }, newZones) => {
      let newZoesId = []
      newZones.forEach((i) => {
        newZoesId.push(i.id)
      })

      let oldZonesId = []
      ;[...state.filterGeoZones.zones].forEach((i) => {
        oldZonesId.push(i.id)
      })

      let zones = [...state.filterGeoZones.zones]
      // //  Удаляем из фильтра зоны, которые не пришли
      // oldZonesId.forEach((i) => {
      //   if (!newZoesId.includes(i)) {
      //     zones = zones.filter((j) => {
      //       j.id !== i;
      //     });
      //   }
      // });
      // Добавляем в фильтр новые зоны
      newZoesId.forEach((i) => {
        if (!oldZonesId.includes(i)) {
          zones = [
            ...zones,
            {
              id: i,
              name: newZones.find((j) => j.id === i).name,
              enable: false,
            },
          ]
        }
      })
      commit('SET_FILTER_GEOZONES', zones)
      dispatch('CALC_INTERSECTS')
    },
    ENABLE_FILTER_GEOZONE: ({ state, commit }, id) => {
      let zones = []
      const all = state.filterGeoZones.zones.length
      const select = state.filterGeoZones.zones.filter((i) => i.enable).length
      state.filterGeoZones.zones.forEach((i) => {
        if (id === 0 && select < all) {
          zones.push({ id: i.id, name: i.name, enable: true })
        } else if (id === 0 && select === all) {
          zones.push({ id: i.id, name: i.name, enable: false })
        } else {
          if (i.id === id) {
            zones.push({ id: id, name: i.name, enable: !i.enable })
          } else {
            zones.push(i)
          }
        }
      })
      zones = JSON.parse(JSON.stringify(zones))
      commit('SET_FILTER_GEOZONES', zones)
    },
    ENABLE_FILTER_WORK_MODE: ({ state, commit }, id) => {
      let modes = []
      const all = state.filterWorkMode.modes.length
      const select = state.filterWorkMode.modes.filter((i) => i.enable).length
      state.filterWorkMode.modes.forEach((i) => {
        if (id === 0 && select < all) {
          modes.push({ id: i.id, name: i.name, enable: true })
        } else if (id === 0 && select === all) {
          modes.push({ id: i.id, name: i.name, enable: false })
        } else {
          if (i.id === id) {
            modes.push({ id: id, name: i.name, enable: !i.enable })
          } else {
            modes.push(i)
          }
        }
      })
      commit('SET_FILTER_WORK_MODE', modes)
    },
    ENABLE_FILTER_POWER_VOLUME: ({ state, commit }, id) => {
      let volumes = []
      const all = state.filterPowerVolume.volumes.length
      const select = state.filterPowerVolume.volumes.filter(
        (i) => i.enable
      ).length
      state.filterPowerVolume.volumes.forEach((i) => {
        if (id === 0 && select < all) {
          volumes.push({ id: i.id, name: i.name, enable: true })
        } else if (id === 0 && select === all) {
          volumes.push({ id: i.id, name: i.name, enable: false })
        } else {
          if (i.id === id) {
            volumes.push({ id: id, name: i.name, enable: !i.enable })
          } else {
            volumes.push(i)
          }
        }
      })
      commit('SET_FILTER_POWER_VOLUME', volumes)
    },
    ENABLE_FILTER_DAY_OFFLINE: ({ state, commit }, day) => {
      const oldDay = state.filterDayOffline.dayCount
      if (day.toString() !== '') {
        if (day <= 0) {
          if (state.filterDayOffline.enable) {
            commit('SET_FILTER_DAY_OFFLINE', {
              dayCount: oldDay,
              enable: false,
            })
          } else {
            commit('SET_FILTER_DAY_OFFLINE', {
              dayCount: oldDay,
              enable: true,
            })
          }
        } else {
          commit('SET_FILTER_DAY_OFFLINE', { dayCount: day, enable: true })
        }
      }
    },
  },
  namespaced: true,
}

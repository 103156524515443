<template>
  <div class="datatimeconnecting">
    <img :src="require('@/assets/images/icons/carcard/clock.png')" />
    <div class="datatimeconnecting_text">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.datatimeconnecting {
  flex: 0 0 auto;
  display: flex;
  height: 24px;
  background: #f8f4fd;
  border: 1px solid #a790d9;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
}
.datatimeconnecting_text {
  padding-left: 8px;
  padding-top: 4px;
}
</style>

import store from "@/store";
import { nextTick } from "vue";

const setter = (l) => {
  const layers = ["cars", "one", "history", "alarms"];
  layers.forEach((layer) => {
    if (layer === l) {
      store.dispatch("OPN_mod/ADD_SHOW_LAYERS", layer);
    } else {
      store.dispatch("OPN_mod/DEL_SHOW_LAYERS", layer);
    }
  });
  nextTick(() => {
    store.dispatch("OPN_mod/SET_FIT_LAYER", l);
  });
};

export const enableLayer = (to) => {
  switch (to) {
    case "car_property":
      setter("one");
      break;
    case "car_history":
      setter("history");
      break;
    case "car_setting":
      setter("one");
      break;
    case "all_cars":
      setter("cars");
      break;
    case "work_list":
      setter("cars");
      break;
    case "groups_list":
      setter("cars");
      break;
    case "all_alarms":
      setter("alarms");
      break;
  }
};

export const settingsRoute = {
  path: 'settings',
  name: 'settings',
  redirect: { name: 'all_settings' },
  components: {
    settings: () => import('./SettingsPage.vue'),
  },
  children: [
    {
      path: 'main_settings',
      name: 'main_settings',
      components: {
        mainSetting: () => import('./SettingsMain.vue'),
      },
    },
    {
      path: 'cf_settings',
      name: 'cf_settings',
      components: {
        customFieldsSetting: () => import('./SettingsCustomFields.vue'),
      },
    },
  ],
}

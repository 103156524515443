<template>
  <div class="wrapper_simple_row">
    <img
      :src="require(`@/assets/icons/groups/` + openPic + `.png`)"
      alt="group"
      style="margin-right: 8px; margin-left: 8px"
    />
    <img
      :src="require(`@/assets/icons/groups/` + folderPic + `.png`)"
      alt="auto"
      style="margin-right: 8px"
    />
    <div style="flex: 1 1 auto">Удалить из групп</div>
  </div>
  <group-selector
    v-if="isOpen"
    :item="tree"
    :levelTree="0"
    :levelOff="0"
    :level="1"
    :search="search"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GroupSelector from './GroupSelector.vue'

export default {
  name: 'tmpSelectorAdd',
  components: { GroupSelector },
  props: { isOpen: Boolean },
  data() {
    return {
      folderPic: 'action-del',
      search: '',
      // isOpen: false,
    }
  },
  beforeUnmount() {
    this.resetGroupSelect()
  },
  methods: {
    ...mapActions({
      resetGroupSelect: 'g_mod/RESET_GROUPS_SELECTOR',
    }),
  },
  computed: {
    // Если папка открыта, то в строке картинка со стрелкой вниз, иначе стрелка вправо
    openPic() {
      if (this.isOpen) return 'to-down'
      return 'to-right'
    },
    ...mapGetters({
      groupsSelect: 'g_mod/GET_GROUPS_SELECT',
      tree: 'g_mod/GET_TREE',
    }),
  },
}
</script>

<style scoped>
.wrapper_simple_row {
  height: 30px;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
</style>

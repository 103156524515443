<template>
  <div class="main_finder_string">
    <div class="icon">
      <img :src="require('@/assets/icons/filter_finder_sorter/search.png')" />
    </div>
    <div class="input">
      <input
        class="search"
        :placeholder="placeholder"
        v-model="search"
        @input="$emit('search', search)"
        type="text"
      />
    </div>
    <div
      v-if="setting"
      @click.stop="showMenuWrapper($event)"
      class="icon"
      style="position: relative"
    >
      <img :src="require('@/assets/icons/filter_finder_sorter/setting.png')" />
    </div>

    <menu-wrapper
      @close="showMenuWrapper()"
      v-if="menuWrapper.show"
      :menuWrapper="menuWrapper"
      :h="
        menuWrapper.hMax < rowCount * 30 + Object.keys(setting).length - 1
          ? menuWrapper.hMax
          : rowCount * 30 + Object.keys(setting).length - 1
      "
      style="overflow-y: auto"
    >
      <div v-for="(i, index) in Object.keys(setting)" :key="i">
        <div
          @click.stop="
            $emit('settingSelect', {
              key: i,
              val: setting[i].options[j - 1].name,
            })
          "
          class="menu_row"
          v-for="j in setting[i].options.length"
          :key="j"
        >
          <check-box
            :label="setting[i].options[j - 1].label"
            :mode="
              setting[i].selected.includes(setting[i].options[j - 1].name)
                ? 'on'
                : 'off'
            "
          />
        </div>
        <div
          v-if="index < Object.keys(setting).length - 1"
          style="border-bottom: 1px solid gray"
        ></div>
      </div>
    </menu-wrapper>
  </div>
</template>

<script>
import ComboMenuWrapper from '@/mixins/comboUI/MenuWrapper'
import { CheckBox } from '@/shared/ui'

export default {
  name: 'finderString',
  components: { CheckBox },
  mixins: [ComboMenuWrapper],
  props: {
    placeholder: String,
    setting: Object,
    startString: {
      type: String,
      default: '',
    },
  },
  emits: ['search', 'settingSelect'],
  data() {
    return {
      search: '',
    }
  },
  mounted() {
    this.search = this.startString
  },
  computed: {
    rowCount() {
      let rowCount = 0
      for (let key in this.setting) {
        rowCount += this.setting[key].options.length
      }
      return rowCount
    },
  },
}
</script>

<style scoped>
.main_finder_string {
  flex: 1 1 auto;
  height: 30px;
  background: #f0f3f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.icon {
  width: 30px;
  display: flex;
  justify-content: center;
}
.input {
  flex: 1 1 auto;
  padding-top: 2px;
}
.search {
  width: 100%;
  background: #f0f3f8;
}
</style>

export const selectMode = (objs) => {
  if (objs) {
    const allObjsCount = objs.length
    const showObjsCount = objs.filter((i) => i.isMap).length
    let mode = 'all'
    if (showObjsCount === 0) mode = 'off'
    if (allObjsCount === showObjsCount) mode = 'on'
    return mode
  }
  return 'off'
}

export const changeAll = (storeObjs, key, fitness = false) => {
  if (selectMode(storeObjs[key]) === 'on') {
    storeObjs.showSetter({ objID: [], isMap: false })
  } else {
    storeObjs.showSetter({ objID: [], isMap: true })
  }
}

export const changeSelect = (storeObjs, key, fitness = false) => {
  let selectId = []
  storeObjs.sortAndFilter.forEach((i) => selectId.push(i.objID))
  if (selectMode(storeObjs.sortAndFilter) === 'on') {
    storeObjs.showSetter({ objID: selectId, isMap: false })
  } else {
    storeObjs.showSetter({ objID: selectId, isMap: true })
  }
}

import { defineStore } from "pinia";
import { ref } from "vue";

export const useErrorStore = defineStore("errorStore", () => {
  const error = ref("");
  const setError = (err) => {
    error.value = err;
  };
  return { error, setError };
});

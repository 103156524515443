import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { Vector as VectorLayer } from 'ol/layer'
import { Cluster, Vector as VectorSource } from 'ol/source'
import { style } from './style'

//  Берем объект, отдаем фьютер-точку
const fPoint = (c, id) => {
  let feature = new Feature(new Point([c.mLon, c.mLat]))
  feature.setId(id)
  return feature
}

export const layerClust = {
  //  Инит
  init: () => {
    let layer = {}
    layer.source = new VectorSource()
    layer.vector = new VectorLayer({
      source: new Cluster({
        distance: 60,
        source: layer.source,
      }),
    })
    return layer
  },

  //  Формируем кластерный слой из объектов
  buildLayer: (layer, cars, carColor, key = 'objID') => {
    //  ключ у истории объектов - имя (номер в истории), тк в истории одного объекта ИД один и тотже
    layer.source.clear()
    let points = []
    cars.forEach((c) => {
      if (c.isMap) points.push(fPoint(c.lastPos, c[key]))
    })
    layer.source.addFeatures(points)
    style.clusterStyle(layer.vector, cars, key, style.cars(carColor))
  },
}

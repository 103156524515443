<template>
  <div style="display: flex">
    <div
      style="z-index: 1; min-width: 25px; height: 15px; margin-top: 15px"
    ></div>
    <div
      class="basic_content"
      style="width: 305px"
      :style="{ maxHeight: heightList.toString() + 'px' }"
    >
      <div class="basic_content_head" style="width: 305px; padding-right: 15px">
        Все объекты: {{ list.length }}
      </div>
      <div
        class="in_basic_content basic_scroll basic_top_border"
        style="align-items: start; padding-bottom: 1px"
      >
        <transition-group name="list" tag="p">
          <div
            class="basic_content_row"
            style="padding: 0 15px 0px 15px"
            v-for="item in list.slice(0, this.showCardsCount)"
            :key="item"
            @click="$emit('change', item.objID)"
          >
            <check-box
              :label="item.name"
              :fontSize="12"
              :mode="mode(item.objID)"
            />
          </div>
        </transition-group>
        <div ref="observer" class="observer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckBox } from '@/shared/ui'
const REDUCTION_CONTENT_HEIGHT = 0.5

export default {
  name: 'adderRightSide',
  components: { CheckBox },
  props: { list: Array, selectList: Array },
  emits: ['change'],
  data() {
    return {
      maxHeight: window.innerHeight * REDUCTION_CONTENT_HEIGHT,
      showCardsCount: 30,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    const options = {
      root: document.querySelector('#scrollArea'),
      rootMargin: '0px',
      threshold: 1.0,
    }
    // eslint-disable-next-line no-unused-vars
    const callback = (entries, observer) => {
      if (entries[0].isIntersecting && this.showCardsCount < this.list.length) {
        this.addshowCardsCount()
      }
    }
    const observer = new IntersectionObserver(callback, options)
    observer.observe(this.$refs.observer)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    heightList() {
      return this.maxHeight
    },
  },
  methods: {
    addshowCardsCount() {
      this.showCardsCount += 10
    },
    onResize() {
      this.maxHeight = window.innerHeight * REDUCTION_CONTENT_HEIGHT
    },
    mode(x) {
      if (this.selectList.includes(x)) return 'on'
      return 'off'
    },
  },
}
</script>

<style scoped>
.observer {
  height: 0px;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>

<template>
  <div style="position: relative">
    <!--    Строка в обычном состоянии-->
    <simple-row
      @click="!isHidden ? setSelected(item.autoID, item.spID, 'automatic') : ''"
      @dblclick="!isHidden ? (isOpen = !isOpen) : ''"
      @save="saveAutomaticEditor = true"
      @cancel="setIsEdit(false)"
      :selected="!showActionAdder"
      :save="showAutomaticEditor"
      :upper="showAutomaticEditor || showActionAdder"
      :level="level"
      :select="{ id: item.autoID, parent: item.spID, type: 'automatic' }"
      :name="item.name"
      :isOpen="isOpen"
      :folderPic="'auto-folder'"
    />

    <!--    Действия пользователя-->
    <!--    Изменение автоматизации-->
    <height-wrapp v-if="showAutomaticEditor" :h="wrappHeight">
      <automatic-editor
        :type="'edit'"
        :save="saveAutomaticEditor"
        :item="item"
        :level="level"
      />
    </height-wrapp>

    <!--    Добавление экшена-->
    <height-wrapp v-if="showActionAdder" :h="wrappHeight">
      <action-editor :item="item" :level="level + 1" />
    </height-wrapp>

    <!--    Потомки - экшены-->
    <div v-if="isOpen && isShowActions">
      <!--    Добавление / исключение объектов из групп-->
      <action-change-group
        @save="saveChangeGroup()"
        v-if="changeGroup"
        :item="changeGroup"
        :level="level + 1"
        :parent="item.autoID"
      />
      <!--    Тревожные тревоги-->
      <action-alarm
        @save="saveChangeGroup()"
        v-if="alarms.length > 0"
        :items="alarms"
        :level="level + 1"
        :parent="item.autoID"
      />
      <!--    Телеграм уведомления-->
      <!--      <action-telegram-->
      <!--        @save="saveChangeTelegram($event)"-->
      <!--        v-if="telegram"-->
      <!--        :item="telegram"-->
      <!--        :level="level + 1"-->
      <!--        :parent="item.autoID"-->
      <!--      />-->
    </div>
  </div>
</template>

<script>
import SimpleRow from './SimpleRow.vue'
// import ActionTelegram from "@/components/monitoringdesk/groups/ActionTelegram";
import mixin from '@/mixins/groups'
import { HeightWrapp } from '@/shared/ui'
import { mapGetters } from 'vuex'
import { api } from '../api'
import ActionAlarm from './ActionAlarm.vue'
import ActionChangeGroup from './ActionChangeGroup.vue'
import ActionEditor from './ActionEditor.vue'
import AutomaticEditor from './AutomaticEditor.vue'

export default {
  name: 'AutomaticsItem',
  components: {
    SimpleRow,
    // ActionTelegram,
    ActionChangeGroup,
    ActionAlarm,
    AutomaticEditor,
    ActionEditor,
    HeightWrapp,
  },
  mixins: [mixin],
  props: {
    item: Object,
    level: Number,
  },
  data() {
    return {
      showActionAdder: false, // Тромоз (300 мс) выключения компонента создания экшена
      showAutomaticEditor: false, // Тромоз (300 мс) выключения компонента редакритования автоматизации
      saveAutomaticEditor: false, // Передача в компонент сигнала на сейв (кнопки находятся тут, а сейв происходит в компоненте)
      isOpen: false,
    }
  },
  watch: {
    enableActionAdder: {
      handler(v) {
        if (v) {
          this.showActionAdder = v
        } else {
          this.setWrappHeight(0)
          setTimeout(() => {
            this.showActionAdder = v
          }, 300)
        }
      },
    }, // Включаем тормоз
    enableAutomaticEditor: {
      handler(v) {
        if (v) {
          this.showAutomaticEditor = v
        } else {
          this.setWrappHeight(0)
          setTimeout(() => {
            this.showAutomaticEditor = v
          }, 300)
        }
      },
    }, // Включаем тормоз
    saveAutomaticEditor: {
      handler(v) {
        if (v)
          setTimeout(() => {
            this.saveAutomaticEditor = false
          }, 300)
      },
    }, // Переводим индикатор в положение по умолчанию
  },
  computed: {
    ...mapGetters({
      groupsSelector: 'g_mod/GET_GROUPS_SELECT',
    }),
    enableActionAdder() {
      return (
        this.isSelected(this.item.autoID, this.item.spID, 'automatic') &&
        this.isAddAction
      )
    },
    enableAutomaticEditor() {
      return (
        this.isSelected(this.item.autoID, this.item.spID, 'automatic') &&
        this.isEdit
      )
    },
    telegram() {
      return this.item.actions.find((i) => i.actionType === 5)
    }, // Индикатор наличия действия - телеграм
    changeGroup() {
      return this.item.actions.find((i) => i.actionType === 1)
    }, // Индикатор наличия действия - изменить состав групп
    alarms() {
      return this.item.actions.filter((i) => i.actionType === 8)
    }, // Индикатор наличия действия - тревоги
  },
  methods: {
    saveChangeGroup() {
      const item = JSON.parse(JSON.stringify(this.item))
      const select = JSON.parse(JSON.stringify(this.groupsSelector.select))
      item.actions.forEach((a) => {
        if (a.actionType === 1) {
          if (this.selectType === 'include') a.spsInclude = select
          if (this.selectType === 'exclude') a.spsExclude = select
        }
      })
      api.cudAutomatics(item)
      this.setIsEdit(false)
    },
    saveChangeTelegram(chat) {
      const item = JSON.parse(JSON.stringify(this.item))
      item.actions.forEach((a) => {
        if (a.actionType === 5) {
          a.param = chat
        }
      })
      api.cudAutomatics(item)
      this.setIsEdit(false)
    },
  },
}
</script>

<style scoped></style>

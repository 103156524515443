export const BASE_API_URL = 'https://fleet.myavo.ru'

export const DEBUG = false
export const FLEET_DEBUG = false

export const EPSG_DB = 'EPSG:4326'
export const EPSG_MAP = 'EPSG:3857'

export const ZONE_CIRCLE = 'Circle'
export const ZONE_POLYGON = 'Polygon'

export const IMPORT_ZONE_CIRCLE = 2
export const IMPORT_ZONE_POLYGON = 1

export const DEFAULT_FILL_COLOR = 'rgba(255,0,255,0.5)'
export const DEFAULT_STROKE_COLOR = '#000000'
export const DEFAULT_STROKE_WIDTH = 1

export const TYPE_RATIO = {
  [IMPORT_ZONE_POLYGON]: ZONE_POLYGON,
  [IMPORT_ZONE_CIRCLE]: ZONE_CIRCLE,
}

export const API_OBJ = {
  LIST: '/fleet/objects/list/1',
  SINGLE: '/fleet/objects/find_by_sn/',
  HISTORY: '/fleet/positions/:carID/history/:from/:till',
  SET_INFO: '/fleet/object/set_info/:objID',
}
export const API_ZONE = {
  LIST: '/fleet/zone/list',
  NEW: '/fleet/zone/new',
  EDIT: '/fleet/zone/edit',

  REMOVE: '/fleet/zone/remove/',
}
export const API_GROUPS = {
  LIST: '/fleet/sps/list',
  EDIT: '/fleet/sps/edit',
  ADD_OBJ: '/fleet/sps/add_objects',
  REM_OBJ: '/fleet/sps/rem_objects',
  AUTOMATIONS_LIST: '/fleet/automations/list',
  AUTOMATIONS_EDIT: '/fleet/automations/edit',
}
export const API_ALARMS = {
  LIST: '/fleet/alarms/list',
  TAKE: '/fleet/alarms/take/',
  UPDATE: '/fleet/alarms/update',
  FIN: '/fleet/alarms/finish',
}
export const API_CUSTOM_FIELDS = {
  LIST: '/fleet/custom_fields/list',
  SET: '/fleet/object/set_custom_field/:objId/:fieldUID',
  CREATE: '/fleet/custom_fields/create',
}
export const API_CONF = {
  TELEGRAM: '/fleet/conf',
}

export const WS = {
  OBJECT: 'fleet.objects.',
  POSITION: 'web.pos.mux.',
  SP: 'web.sp.updates.',
  CUSTOM_FIELDS: 'fleet.customfields.',
  GROUPS: 'fleet.groups.',
  ALARMS: 'fleet.alarms.',
  AUTOMATICS: 'fleet.automations.',
  SINGLE: 'fleet.object.:userID.:objID',
  SINGLE_POSITION: 'fleet.pos.:objID',
}

export const TYPE_OF_GROUP = {
  GROUP: 1,
  AUTO: 2,
  OBJECT_MANUAL: 3,
  OBJECT_AUTO: 4,
  ACTION_GROUP_ADD: 5,
  ACTION_GROUP_DEL: 6,
}

export const RGEOCODE = {
  SINGLE: (lat, lon) => `/rgeocode/address/${lat}/${lon}`,
  MULTI: `/rgeocode/multiaddress`,
}

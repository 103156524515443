<template>
  <div class="basic_main no_selected basic_font" style="padding: 0">
    <div
      style="
        overflow: visible;
        flex: 1 1 auto;
        background: #fdfdfd;
        box-shadow: 0 4px 6px 2px #dddddd;
        border-radius: 6px;
        width: 382px;
        margin: 8px 0 8px 12px;
        display: flex;
        flex-direction: column;
      "
    >
      <slot name="top"></slot>
    </div>
    <div
      class="basic_content"
      style="flex: 1 1 auto; margin-left: 12px; position: relative"
    >
      <slot name="shadow"></slot>
      <div class="basic_content_head">
        <slot name="header"></slot>
      </div>

      <div
        class="in_basic_content basic_scroll basic_top_border"
        style="align-items: start; padding: 8px 0 0 8px"
      >
        <transition-group name="list" tag="p">
          <slot name="list"></slot>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>

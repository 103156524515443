<template>
  <div>
    <div
      class="folder"
      @dblclick.stop="isOpen = !isOpen"
      :style="{
        paddingLeft: paddingLeft,
        display: rowDisplay,
      }"
    >
      <div style="display: flex; align-items: center">
        <div @click.stop="isOpen = !isOpen">
          <img
            :src="require(`@/assets/icons/groups/` + openPic + `.png`)"
            alt="group"
            style="margin-right: 8px; margin-left: 8px"
          />
        </div>
        <check-box
          @dblclick.stop
          @click.stop="groupsSelector(item.spID)"
          :mode="
            level < levelOff
              ? 'disable'
              : groupsSelect.select.includes(item.spID)
              ? 'on'
              : 'off'
          "
          :label="item.name"
          :fontSize="12"
        />
      </div>
    </div>
    <div v-if="isOpen">
      <div v-for="chil in item.children" :key="chil.spID">
        <group-selector
          :levelOff="levelOff"
          :item="chil"
          :levelTree="levelTree + 1"
          :level="level + 1"
          :search="search"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/groups'
import { CheckBox } from '@/shared/ui'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GroupSelector',
  mixins: [mixin],
  components: { CheckBox },
  props: {
    item: Object,
    level: Number,
    levelOff: Number,
    levelTree: Number,
    search: String,
  },
  data() {
    return {
      isOpen: true,
    }
  },
  mounted() {
    this.selectCounter(true)
  },
  beforeUnmount() {
    this.selectCounter(false)
  },
  watch: {
    rowDisplay: {
      handler(v) {
        this.selectCounter(v === '')
      },
    },
  },
  computed: {
    ...mapGetters({
      groupsSelect: 'g_mod/GET_GROUPS_SELECT',
    }),
    rowDisplay() {
      if (this.item.name.toLowerCase().includes(this.search.toLowerCase()))
        return ''
      return 'none'
    },
  },
  methods: {
    ...mapActions({
      groupsSelector: 'g_mod/GROUPS_SELECTOR',
      selectCounter: 'g_mod/COUNTER_GROUPS_SELECT',
    }),
  },
}
</script>

<style scoped>
.folder {
  height: 22px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0);
  position: relative;
}
.folder:hover {
  border: 1px solid #a6ddff;
  border-radius: 2px;
}
.folder:active {
  border: 1px solid #0288d1;
  border-radius: 2px;
}
</style>

import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      // Что показываем (группы показываем всегда)
      isShowObj: "gc_mod/GET_IS_SHOW_OBJ",
      isShowAutomatics: "gc_mod/GET_IS_SHOW_AUTOMATICS",
      isShowActions: "gc_mod/GET_IS_SHOW_ACTIONS",

      // В какую кнопку ткнули
      isAddGroup: "gc_mod/GET_IS_ADD_GROUP",
      isAddAutomatic: "gc_mod/GET_IS_ADD_AUTOMATIC",
      isAddAction: "gc_mod/GET_IS_ADD_ACTION",
      isEdit: "gc_mod/GET_IS_EDIT",
      selectItem: "gc_mod/GET_SELECT",
      selectId: "gc_mod/GET_SELECT_ID",
      selectParent: "gc_mod/GET_SELECT_PARENT",
      selectType: "gc_mod/GET_SELECT_TYPE",

      // Высота обертки плавного появления
      wrappHeight: "gc_mod/GET_WRAPP_HEIHGT",
    }),

    // Включается модалка, если что-то редактируется или добавляется
    isHidden() {
      return (
        this.isAddGroup ||
        this.isAddAutomatic ||
        this.isAddAction ||
        this.isEdit
      );
    },
    // Если папка открыта, то в строке картинка со стрелкой вниз, иначе стрелка вправо
    openPic() {
      if (this.isOpen) return "to-down";
      return "to-right";
    },
    // Отступ строки в дереве
    paddingLeft() {
      return (this.level * 10).toString() + "px";
    },
  },
  methods: {
    ...mapActions({
      // Что показываем (группы показываем всегда)
      setIsShowObj: "gc_mod/SET_IS_SHOW_OBJ",
      setIsShowAutomatics: "gc_mod/SET_IS_SHOW_AUTOMATICS",
      setIsShowActions: "gc_mod/SET_IS_SHOW_ACTIONS",

      // В какую кнопку ткнули
      setIsAddGroup: "gc_mod/SET_IS_ADD_GROUP",
      setIsAddAutomatic: "gc_mod/SET_IS_ADD_AUTOMATIC",
      setIsAddAction: "gc_mod/SET_IS_ADD_ACTION",
      setIsEdit: "gc_mod/SET_IS_EDIT",
      setSelectItem: "gc_mod/SET_SELECT",
      setSelectId: "gc_mod/SET_SELECT_ID",
      setSelectParent: "gc_mod/SET_SELECT_PARENT",
      setSelectType: "gc_mod/SET_SELECT_TYPE",

      // Высота обертки плавного появления
      setWrappHeight: "gc_mod/SET_WRAPP_HEIHGT",
    }),

    // Запомним какую строку выбрали
    setSelected(id, parent, type) {
      this.setSelectItem({
        id: id,
        parent: parent,
        type: type,
      });
    },
    // Проверим выбрана переданная строка или нет
    isSelected(id, parent, type) {
      return (
        this.selectId === id &&
        this.selectParent === parent &&
        this.selectType === type
      );
    },
  },
};

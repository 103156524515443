<template>
  <div class="slider_wrap">
    <div class="slider">
      <div
        :class="[{ sliderbgH: isH }, { sliderbgL: isL }, { sliderbgM: isM }]"
        :style="style"
      ></div>
    </div>
    <div class="power_volume">{{ numberPover }}%</div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({ power: Number })

const numberPover = computed(() => parseInt(props.power, 10))
const style = computed(() => `width: ${numberPover.value}px`)
const isH = computed(() => numberPover.value >= 75)
const isL = computed(() => numberPover.value <= 40)
const isM = computed(() => numberPover.value < 75 && numberPover.value > 40)
</script>

<style scoped>
.slider_wrap {
  display: flex;
  align-items: center;
}
.slider {
  width: 100px;
  height: 9px;
  padding: 1px 0px;
  box-shadow: inset 0 0 1px 1px #fdfdfd;
  border: 1px solid #24abff;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
.sliderbgH {
  background: repeating-linear-gradient(
    90deg,
    #24abff,
    #24abff 4px,
    white 4px,
    white 5px
  );
}
.sliderbgM {
  background: repeating-linear-gradient(
    90deg,
    #ffe600,
    #ffe600 4px,
    white 4px,
    white 5px
  );
}
.sliderbgL {
  background: repeating-linear-gradient(
    90deg,
    #bc1a1a,
    #bc1a1a 4px,
    white 4px,
    white 5px
  );
}
.power_volume {
  padding-left: 10px;
  padding-top: 2px;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #1e81ce;
}
</style>

//              --- Обертка плавного развороачивания/сворачивания окна меню (menuWrapper) ---
//
//  Ширина подстраивается под содержимое окна,
//  Высота задается пропсом
//  Привязана абсолютом к таргету
//
//  В шаблоне разместить компонент
//            <menu-wrapper
//               @close="showMenuWrapper()"
//               v-if="menuWrapper.show"
//               :menuWrapper="menuWrapper"
//               :h="3 * 30"
//             >
//                  Контент
//            </menu-wrapper>
//
//

import MenuWrapper  from '@/shared/ui/menuWrapper/MenuWrapper.vue'

export default {
  components: { MenuWrapper },
  data() {
    return {
      marginBottom: 40,
      menuWrapper: {
        x: 0,
        y: 0,
        enable: false,
        show: false,
        hMax: 0,
      },
    }
  },
  methods: {
    showMenuWrapper(e = null) {
      if (this.menuWrapper.enable) {
        this.menuWrapper.enable = false
        setTimeout(() => {
          this.menuWrapper.show = false
        }, 100)
      } else {
        this.menuWrapper.show = true
        this.menuWrapper.enable = true
        this.menuWrapper.x = e.clientX - e.offsetX
        this.menuWrapper.y = e.clientY - e.offsetY + 20
        this.menuWrapper.hMax =
          window.innerHeight - this.menuWrapper.y - this.marginBottom
      }
    },
  },
}

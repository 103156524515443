<template>
  <div
    v-if="enable"
    style="
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      z-index: 2;
    "
  ></div>
</template>

<script>
// Тень от модалки
export default {
  name: "ModalShadow",
  props: { enable: Boolean },
};
</script>

<style scoped></style>

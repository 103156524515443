<template>
  <div style="position: relative" v-for="item in items" :key="item">
    <!--    Строка в обычном состоянии и при редактировании-->
    <simple-row
      @click="setSelected(item.alarmSpID, parent, 'alarm')"
      @save="$emit('save', $event)"
      @cancel="setIsEdit(false)"
      :level="level"
      :select="{ id: item.alarmSpID, parent: parent, type: 'alarm' }"
      :name="allGroups.find((g) => g.spID === item.alarmSpID).name"
      :folderPic="'alarm'"
    />
  </div>
</template>

<script>
import mixin from '@/mixins/groups'
import { mapGetters } from 'vuex'
import SimpleRow from './SimpleRow.vue'

export default {
  name: 'ActionAlarm',
  components: { SimpleRow },
  mixins: [mixin],
  props: {
    level: Number,
    items: Object,
    parent: String,
  },
  emits: ['save'],
  computed: {
    ...mapGetters({
      allGroups: 'g_mod/GET_GROUPS',
    }),

    selectID() {
      return this.parent
    },
  },
}
</script>

<style scoped></style>

import box from "@/models/groupbox";

const state = {
  groupbox: JSON.parse(JSON.stringify(box)),
};
const getters = {
  GROUP_BOX: (state) => {
    return state.groupbox;
  },
};
const mutations = {
  SET_GROUP_BOX: (state, payload) => {
    state.groupbox = payload;
  },
  INIT_GROUP_BOX: (state) => {
    state.groupbox = JSON.parse(JSON.stringify(box));
  },
};
const actions = {
  SET_GROUP_BOX: (context, payload) => {
    context.commit("SET_GROUP_BOX", payload);
  },
  INIT_GROUP_BOX: (context) => {
    context.commit("INIT_GROUP_BOX");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div
    style="cursor: pointer; display: flex; align-items: center"
    @mouseenter="
      isOver = true;
      disable ? (prefix = '-disable') : (prefix = '-hover');
    "
    @mouseout="
      (isOver = false), disable ? (prefix = '-disable') : (prefix = '-normal')
    "
    @mouseup="
      disable
        ? (prefix = '-disable')
        : isOver
        ? (prefix = '-hover')
        : (prefix = '-normal')
    "
    @mousedown="disable ? (prefix = '-disable') : (prefix = '-active')"
  >
    <img
      draggable="false"
      :src="require(`@/assets/icons/` + type + `/` + name + prefix + `.png`)"
      :alt="alt"
    />
    <span style="margin-left: 6px" v-if="label !== ''">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "ImageBtn",
  props: {
    type: String,
    name: String,
    label: { type: String, default: "" },
    alt: { type: String, default: "" },
    disable: { type: Boolean, default: false },
  },
  data() {
    return {
      isOver: false,
      prefix: "-normal",
    };
  },
  created() {
    if (this.disable) this.prefix = "-disable";
  },
  watch: {
    disable: {
      handler(v) {
        v ? (this.prefix = "-disable") : (this.prefix = "-normal");
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="main_confirm">
    <div class="box">
      <div class="top">
        {{ labelConf }}
      </div>
      <div class="bottom">
        <div class="btn" @click="$emit('delete')">Да</div>
        <div class="btn" @click="$emit('cancel')">Нет</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmDeletion",
  props: {
    labelConf: String,
  },
  emits: ["delete", "cancel"],
  data() {
    return {
      confirmShow: false,
    };
  },
};
</script>

<style scoped>
.main_confirm {
  position: relative;
}
.box {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 138px;
  /*height: 54px;*/
  background: #f5f5f5;
  box-shadow: 0px 4px 6px 1px rgba(48, 66, 98, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.top {
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #404040;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 0 10px;
}
.bottom {
  display: flex;
  justify-content: space-evenly;
}
.btn {
  /*flex: 0 0 auto;*/
  margin: 5px;
  width: 51px;
  height: 19px;
  cursor: pointer;

  border: 1px solid #304262;
  box-sizing: border-box;
  border-radius: 4px;

  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #404040;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:hover {
  background: #365366;
  color: #ffffff;
}
</style>

import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { style } from './style'

//  Берем объект, отдаем фьютер-точку
const fPoint = (c) => {
  let feature = new Feature(new Point([c.mLon, c.mLat]))
  feature.setId(c.id)
  return feature
}

export const layerOne = {
  //  Инит
  init: () => {
    let layer = {}
    layer.source = new VectorSource()
    layer.vector = new VectorLayer({
      source: layer.source,
    })
    return layer
  },

  //  Формируем слой из объекта
  buildLayer: (layer, car) => {
    layer.source.clear()
    let points = [fPoint(car.lastPos)]
    layer.source.addFeatures(points)
    layer.vector.setStyle(style.cars('green', car.lastPos.course))
  },
}

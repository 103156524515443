<template>
  <div>
    <div
      :class="{
        wrapper_simple_select:
          isSelected(select.id, select.parent, select.type) && selected,
        wrapper_simple_upper: upper,
        basic_content_row: true,
      }"
      :style="{ paddingLeft: paddingLeft, height: isSmall ? '24px' : '30px' }"
    >
      <div class="wrapper_simple_row">
        <!--  Стрелочка у папки - показывает открыта папка или нет (берем из миксина по ключу isOpen)-->
        <img
          v-if="!isSmall"
          :src="require(`@/assets/icons/groups/` + openPic + `.png`)"
          alt="group"
          style="margin-right: 8px; margin-left: 8px"
        />
        <!--  Картинка папки (группа/автоматизация/...)-->
        <img
          :src="require(`@/assets/icons/groups/` + folderPic + `.png`)"
          alt="auto"
          style="margin-right: 8px"
        />
        <!--  Основной текст или ввод-->
        <div style="flex: 1 1 auto">
          <!--          Если есть ключ Инпут - показываем инпут-->
          <input
            v-if="isInput"
            class="wrapper_simple_input"
            v-model="nameInput"
            v-focus
            @click.stop
            @keydown.enter="$emit('save', nameInput)"
            @keydown.esc="
              $emit('cancel');
              nameInput = name;
            "
            type="text"
          />
          <!--          Если нет ключа Инпут - показываем текст-->
          <div v-else>{{ name }}</div>
        </div>
        <!--  Если передан ключ Сейв или Инпут, то показать Иконки эмитов сейва/отмены-->
        <div v-if="save || isInput" style="display: flex; margin-right: 5px">
          <div
            @click.stop="$emit('save', nameInput)"
            style="padding: 5px; cursor: pointer"
          >
            <img
              :src="require(`@/assets/icons/groups/save.png`)"
              alt="Сохранить"
            />
          </div>
          <div
            @click.stop="
              $emit('cancel');
              nameInput = name;
            "
            style="padding: 5px; cursor: pointer"
          >
            <img
              :src="require(`@/assets/icons/groups/cancel.png`)"
              alt="Отменить"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="upper && !isSmall" style="height: 30px"></div>
    <div v-if="upper && isSmall" style="height: 24px"></div>
  </div>
</template>

<script>
import mixin from "@/mixins/groups";

const focus = {
  mounted: (el) => {
    el.focus();
    el.select();
  },
};

export default {
  name: "SimpleRow",
  directives: {
    focus,
  },
  mixins: [mixin],
  props: {
    save: {
      type: Boolean,
      default: false,
    }, // Включатель кнопок Сохранить/Отмена
    name: String,
    isOpen: Boolean,
    folderPic: String,
    select: Object,
    level: Number,
    upper: {
      type: Boolean,
      default: false,
    }, // Строка переводитсяв абсолют и поднимается к модалке, на ширину строки создается фейковый див
    selected: {
      type: Boolean,
      default: true,
    }, // Отключаем класс селект строке
    isInput: {
      type: Boolean,
      default: false,
    }, // Редактирование в инпуте строки
    isSmall: {
      type: Boolean,
      default: false,
    }, // Маленькие строки у объектов
  },
  emits: ["save", "cancel"],
  data() {
    return {
      nameInput: "",
    };
  },
  created() {
    this.nameInput = this.name;
  },
};
</script>

<style scoped>
.wrapper_simple_row {
  /*height: 30px;*/
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
.wrapper_simple_select {
  background: #d8f1ff;
}
.wrapper_simple_upper {
  position: absolute;
  z-index: 3;
}
.wrapper_simple_input {
  flex: 1 1 auto;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
}
.wrapper_simple_input:focus {
  outline: none;
}
</style>

<template>
  <div>
    <!--    Строка в обычном состоянии-->
    <simple-row
      @click.stop="setSelected(parent, parent, 'objectFolder')"
      @dblclick="isOpen = !isOpen"
      :level="level"
      :select="{ id: parent, parent: parent, type: 'objectFolder' }"
      :id="parent"
      :upper="isEdit && isSelected(parent, parent, 'objectFolder')"
      :name="'Объекты '"
      :isOpen="isOpen"
      :folderPic="'folder'"
    />
    <!--    Потомки - объекты-->
    <div v-if="isOpen">
      <simple-row
        v-for="n in groupObjs"
        :key="n"
        @click="isEdit ? '' : setSelected(n.id, parent, 'object')"
        :level="level + 3"
        :select="{ id: n, parent: parent, type: 'object' }"
        :upper="isEdit && isSelected(parent, parent, 'objectFolder')"
        :name="n.sn"
        :isOpen="false"
        :folderPic="'car'"
        :isSmall="true"
      />
      <div class="observer" ref="objFolderObserver"></div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/groups'
import { mapGetters } from 'vuex'
import SimpleRow from './SimpleRow.vue'

export default {
  name: 'ObjFolder',
  components: { SimpleRow },
  mixins: [mixin],
  props: {
    level: Number,
    parent: Number,
  },
  data() {
    return {
      isOpen: false,
      names: [],
    }
  },
  // watch: {
  //   groupObjs: {
  //     handler() {
  //       console.log("watcher this.groupObjs");
  //       if (this.isOpen) this.namePusher();
  //     },
  //   },
  // },
  mounted() {
    // Подстановка в в-фор компутеда с финдом на 1000 оюъектов очень сильно тормозит при смене селекта
    // TODO: добавить вотчера на аллКарс для переформирования нэймесов
    // if (this.isOpen) this.namePusher();
  },
  computed: {
    ...mapGetters({
      list: 'g_mod/GET_GROUP_OBJECTS',
      allCars: 'cars_mod/ALL_CARS',
    }),
    groupObjs() {
      const ids = this.list(this.parent)
      return this.allCars.filter((c) => ids.includes(c.id))
    },
  },
  methods: {
    // namePusher() {
    //   console.time("one " + this.parent);
    //   console.log("this.groupObjs", this.groupObjs.length);
    //   this.names = [];
    //   if (this.groupObjs) {
    //     this.groupObjs.forEach((i) => {
    //       this.names.push({
    //         id: i,
    //         name: this.allCars.find((c) => c.id === i).name,
    //       });
    //     });
    //   }
    //   // if (this.lister) {
    //   //   this.lister.every((i) => {
    //   //     this.names.push({
    //   //       id: i,
    //   //       name: this.allCars.find((c) => c.id === i).name,
    //   //     });
    //   //     // if (this.names.length === 50) return false;
    //   //     return true;
    //   //   });
    //   // }
    //
    //   console.timeEnd("one " + this.parent);
    // },
  },
}
</script>

<style scoped></style>

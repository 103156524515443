export const carBoxRoute = {
  path: 'car/:id',
  redirect: { name: 'car_property' },
  name: 'car',
  components: {
    carBox: () => import('./CarBoxPage.vue'),
  },
  children: [
    {
      path: 'car_property',
      name: 'car_property',
      components: {
        CarProperty: () => import('./CarBoxProperty.vue'),
      },
    },
    {
      path: 'car_history',
      name: 'car_history',
      components: {
        carHistory: () => import('./CarBoxHistory.vue'),
      },
    },
    {
      path: 'car_setting',
      name: 'car_setting',
      components: {
        carSetting: () => import('./CarBoxSetting.vue'),
      },
    },
  ],
}

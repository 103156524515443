import { reactive } from 'vue'

export const useStorage = () => {
  const storage = reactive({})

  const setOne = (item) => {
    storage[item.id] = item
  }

  const set = (items) => {
    items.forEach(setOne)
  }

  const get = (id) => storage[id]

  const del = (id) => {
    delete storage[id]
  }

  const clear = () => {
    Object.keys(storage).forEach(del)
  }

  return { storage, setOne, set, get, del, clear }
}
